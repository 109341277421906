<template>
  <div>
    <component-title>
      <template #actions>
        <div class="tw-flex tw-gap-x-3">
          <v-btn
            v-if="userHasAcessToPatientAudit"
            class="btn btn-primary tw-w-48"
            @click="showPatientAuditDialog = true"
          >
            Auditoria
            <span>
              <v-badge
                v-if="patientRegistrationLength > 0"
                inline
                color="danger"
                :content="patientRegistrationLength"
              />
            </span>
          </v-btn>

          <v-btn
            v-if="userHasAccessToVirtualReception"
            class="btn btn-primary tw-w-64"
            :class="{
              'tw-w-80': isUserInAttendance,
            }"
            @click="toggleVirtualReceptionDialog"
          >
            {{ virtualReceptionButtonTitle }}
            <span>
              <v-badge
                v-if="openAttendances > 0"
                inline
                color="danger"
                :content="openAttendances"
              />
            </span>
          </v-btn>

          <v-btn
            v-if="hasAccessToCreatePatient"
            color="primary"
            class="btn tw-w-60"
            @click="showCreatePatientDialog = true"
          >
            Novo Paciente
          </v-btn>
        </div>
      </template>
    </component-title>

    <div class="filters-container sticky tw-py-10 tw-drop-shadow-md">
      <div class="tw-grid tw-grid-flow-col tw-auto-cols-auto tw-items-center tw-px-11 tw-gap-6">
        <v-autocomplete
          v-model="filters.clinic"
          class="tw--mb-7"
          :items="clinics"
          append-icon=""
          label="Unidade"
          item-text="name"
          item-value="id"
          hint="Obrigatório"
          persistent-hint
          :loading="isLoadingClinic"
          return-object
          filled
          data-testid="agenda__filters-clinic--autocomplete"
          @blur="$v.filters.clinic.$touch()"
          @change="handleSearchAgenda('clinic')"
        />

        <v-autocomplete
          v-model="filters.speciality"
          class="tw--mb-7"
          :items="specialities"
          append-icon=""
          item-text="name"
          item-value="id"
          label="Especialidade"
          hint="Obrigatório"
          persistent-hint
          :loading="isLoadingSpeciality"
          return-object
          filled
          data-testid="agenda__filters-speciality--autocomplete"
          @blur="$v.filters.speciality.$touch()"
          @change="handleSearchAgenda('speciality')"
        />

        <search-professional
          :key="`${specialityId + clinicId + professionalId}`"
          :speciality-id="specialityId"
          :clinic-id="clinicId"
          :professional-id="professionalId"
          :has-agenda="true"
          hide-details
          @input="updateProfessional($event)"
        />

        <vc-date-picker
          v-model="filters.date"
          :close-on-content-click="true"
          :model-config="{ type: 'string', mask: 'YYYY-MM-DD' }"
          color="green"
          locale="pt-BR"
          :attributes="datePickerAttributes"
          @input="handleSearchAgenda"
        >
          <template v-slot="{ inputEvents, inputValue }">
            <v-text-field
              :value="formatFilterDateToDDMMYYYY"
              readonly
              label="Melhor data"
              append-icon="mdi-calendar"
              clearable
              filled
              hide-details
              v-on="inputEvents"
              @click:clear="filters.date = null; handleSearchAgenda()"
            />
          </template>
        </vc-date-picker>

        <v-btn
          block
          large
          :disabled="!filters.date"
          class="btn btn-secondary tw-h-14"
          @click="setFilterDate('yesterday')"
        >
          <v-icon>
            mdi-less-than
          </v-icon>
        </v-btn>

        <v-btn
          block
          large
          :disabled="!filters.date"
          class="btn btn-secondary tw-h-14"
          @click="setFilterDate('tomorrow')"
        >
          <v-icon>
            mdi-greater-than
          </v-icon>
        </v-btn>

        <v-btn
          block
          large
          class="btn btn-primary btn-primary--darken tw-h-14"
          @click="setFilterDate('today')"
        >
          Ir para hoje
        </v-btn>
      </div>
    </div>

    <div>
      <v-progress-linear
        v-if="isLoadingSlot"
        color="primary"
        height="3"
        indeterminate
      />
    </div>

    <div>
      <h2
        v-if="shouldShowNoResultMessage"
        class="components__default-message tw-ml-12 tw-mt-4"
      >
        Não há horários disponíveis.
      </h2>

      <transition
        v-else
        name="slide"
      >
        <component
          :is="componentToLoad"
          v-if="!shouldShowNoResultMessage"
          :agenda-filters="filters"
          :query="query"
          @goToAgenda="fillFilters"
        />
      </transition>
    </div>

    <v-dialog
      v-model="showPatientAuditDialog"
      fullscreen
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <v-toolbar-title class="tw-pl-8">
            Auditoria
          </v-toolbar-title>

          <v-btn
            class="tw-ml-auto"
            icon
            dark
            @click="closePatientAuditDialog()"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <audit-container
          @audited="getPatientRegistrationLength()"
        />
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="isOpenVirtualReceptionDialog"
      fullscreen
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <v-toolbar-title class="tw-pl-8">
            Recepção virtual
          </v-toolbar-title>

          <v-btn
            class="tw-ml-auto"
            icon
            dark
            @click="toggleVirtualReceptionDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <virtual-reception-container />
      </v-card>
    </v-dialog>

    <v-dialog
      v-if="hasAccessToCreatePatient"
      v-model="showCreatePatientDialog"
      fullscreen
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <v-toolbar-title class="tw-pl-4">
            Cadastrar Paciente
          </v-toolbar-title>

          <v-btn
            class="tw-ml-auto"
            icon
            dark
            @click="showCreatePatientDialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <create-patient />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import CreatePatient from '@/components/CreatePatient'
import ComponentTitle from '@/components/ComponentTitle'
import moment from 'moment'
import debounce from 'lodash/debounce'
import { mapActions, mapGetters } from 'vuex'
import removeNilOrEmpty from '@/utils/removeNilOrEmpty'
import {
  always,
  isNil,
  equals,
  and,
  path,
  includes,
  not,
  gte,
  length,
  keys,
  ifElse,
  isEmpty,
  head,
  find,
  map,
} from 'ramda'
import SearchProfessional from '@/components/Common/SearchProfessional'
import AmparoCalendar from '@/components/Common/AmparoCalendar'
import AuditContainer from '@/components/Audit/AuditContainer'
import LoadingAgenda from '@/components/loaders/LoadingAgenda'
import VirtualReceptionContainer from '@/components/VirtualReception'
import AgendaMessage from './AgendaMessage'
import AgendaFreeAppointmentList from './AgendaFreeAppointmentList'
import AgendaContainer from './AgendaContainer'

const MEDICAL_PROFESSIONS_IDS = [
  'profession_cjz3an5zj00020nxbgcea9qh5',
  'profession_cjz3an5zk00030nxbejbsg6wj',
  'profession_cjz3an5zk00040nxbf1ple8ju',
  'profession_cjzbz1g7t000gb0vo907k3kv3',
  'profession_cjzbz9pgk000hb0vogejnajuo',
]

export default {
  components: {
    'agenda-free-appointments-list': AgendaFreeAppointmentList,
    'agenda-message': AgendaMessage,
    agenda: AgendaContainer,
    CreatePatient,
    ComponentTitle,
    SearchProfessional,
    LoadingAgenda,
    AmparoCalendar,
    AuditContainer,
    VirtualReceptionContainer,
  },
  data() {
    return {
      componentToLoad: 'agenda-message',
      menuDate: false,
      filters: {
        speciality: null,
        clinic: null,
        professional: null,
        date: null,
      },
      professionalAgendaDays: [],
      showPatientAuditDialog: false,
      showCreatePatientDialog: false,
    }
  },
  computed: {
    ...mapGetters({
      user: 'authentication/user',
      userHasAccessToFunctionality: 'authentication/userHasAccessToFunctionality',
      clinics: 'clinic/clinics',
      specialities: 'speciality/specialities',
      availableAgendas: 'agenda/availableAgendas',
      slots: 'agenda/slots',
      isLoadingSlot: 'agenda/loadingSlot',
      isLoadingClinic: 'clinic/loadingClinic',
      isLoadingSpeciality: 'speciality/loading',
      isLoadingProfessional: 'professional/loformatFilterDateToDDMMYYYYading',
      isUserInAttendance: 'virtualReception/isUserInAttendance',
      openAttendances: 'virtualReception/openAttendances',
      patientRegistrationLength: 'patientRegistration/patientRegistrationLength',
      showVirtualReceptionDialog: 'virtualReception/showVirtualReceptionDialog',
    }),
    isOpenVirtualReceptionDialog: {
      get() { return this.showVirtualReceptionDialog },
      set() { this.toggleVirtualReceptionDialog() },
    },
    datePickerAttributes() {
      return [{
        dot: true,
        dates: {
          start: moment().toDate(),
          end: moment().add(1, 'months').toDate(),
          weekdays: map(
            day => day + 1,
            this.professionalAgendaDays,
          ),
        },
      }]
    },
    shouldShowNoResultMessage() {
      return and(not(this.isLoadingSlot), isEmpty(this.slots))
    },
    formatFilterDateToDDMMYYYY() {
      return this.filters.date ? moment(this.filters.date).format('DD/MM/YYYY') : ''
    },
    hasAccessToCreatePatient() {
      return this.userHasAccessToFunctionality.createPatient
    },
    query() {
      return this.$route.query
    },
    specialityId() {
      return path(['speciality', 'id'], this.filters)
    },
    clinicId() {
      return path(['clinic', 'id'], this.filters)
    },
    professionalId() {
      return path(['professional', 'id'], this.filters)
    },
    userHasAcessToPatientAudit() {
      return this.userHasAccessToFunctionality.patientAudit
    },
    userHasAccessToVirtualReception() {
      return this.userHasAccessToFunctionality.virtualReception
    },
    virtualReceptionButtonTitle() {
      return this.isUserInAttendance
        ? 'Voltar ao atendimento'
        : 'Recepção virtual'
    },
  },
  watch: {
    query: {
      deep: true,
      immediate: true,
      handler(query) {
        this.filters = this.hasAllFilters(query)
          ? this.formatQuery(query)
          : this.filters
        this.navigateToQuery()
      },
    },
  },
  created() {
    this.getPatientRegistrationLength()
  },
  async mounted() {
    if (!this.hasAllFilters(this.query) && this.query?.appointmentId) {
      const [appointment] = await this.listAppointment({
        id: this.query.appointmentId,
        omitValuesOnStore: true,
      })

      this.$router.push({
        path: '/agenda',
        query: {
          clinicId: appointment.clinicId,
          specialityId: appointment.specialityId,
          professionalId: appointment.professionalId,
          date: moment(appointment.date).format('YYYY-MM-DD'),
          appointmentId: appointment.id,
          orderId: this.query.orderId,
        },
      })
    }
    this.listClinic()
  },
  activated() {
    this.checkDoctorAgenda()
  },
  methods: {
    ...mapActions({
      listClinic: 'clinic/listClinic',
      listSpeciality: 'speciality/listSpeciality',
      listAgenda: 'agenda/listAgenda',
      listSlot: 'agenda/listSlot',
      listAppointment: 'agenda/listAppointment',
      listProfessionalAgendaDays: 'professional/listProfessionalAgendaDays',
      listPatientRegistrationLength: 'patientRegistration/listPatientRegistrationLength',
      toggleVirtualReceptionDialog: 'virtualReception/toggleVirtualReceptionDialog',
    }),
    updateProfessional(event) {
      this.filters.professional = event
      this.handleSearchAgenda()
    },
    hasAllFilters(filters) {
      return gte(length(keys(removeNilOrEmpty(filters))), 4)
    },
    formatQuery(query) {
      return {
        clinic: { id: query.clinicId },
        speciality: { id: query.specialityId },
        professional: { id: query.professionalId },
        date: query.date,
      }
    },
    navigateToQuery() {
      if (not(isEmpty(this.query))) this.handleSearchAgenda()
    },
    setFilterDate(when) {
      if (equals(when, 'tomorrow')) {
        this.filters.date = moment(this.filters.date).add(1, 'days').format('YYYY-MM-DD')
      }
      if (equals(when, 'yesterday')) {
        this.filters.date = moment(this.filters.date).subtract(1, 'days').format('YYYY-MM-DD')
      }
      if (equals(when, 'today')) {
        this.filters.date = moment().format('YYYY-MM-DD')
      }
      this.handleSearchAgenda()
    },
    fillFilters(slot) {
      this.filters.speciality = slot.speciality
      this.filters.clinic = slot.clinic
      this.filters.professional = slot.professional
      this.filters.date = moment(slot.date).format('YYYY-MM-DD')
      this.handleSearchAgenda()
    },
    handleFilters(change) {
      this.listSpeciality({ clinicId: this.filters.clinic.id })
      if (includes(change, ['clinic', 'speciality'])) {
        this.filters.professional = null
      }
      if (this.filters.professional) {
        this.listProfessionalAgendaDays({
          professionalId: this.filters.professional.id,
          clinicId: this.filters.clinic.id,
          specialityId: this.filters.speciality.id,
        }).then((res) => {
          this.professionalAgendaDays = res
        })
        return
      }
      this.professionalAgendaDays = []
    },
    handleSearchAgenda: debounce(async function handleSearchAgenda(filtersToClear) {
      if (and(
        not(isNil(path(['clinic', 'id'], this.filters))),
        isNil(path(['speciality', 'id'], this.filters)),
      )) {
        this.listSpeciality({ clinicId: this.filters.clinic.id })
      }
      this.$v.$touch()
      if (this.$v.$invalid) return
      this.handleFilters(filtersToClear)
      if (this.hasAllFilters(this.filters)) {
        this.componentToLoad = 'agenda'
        this.listSlot(this.filters)
        this.listAppointment(this.filters)
        return
      }
      this.componentToLoad = 'agenda-free-appointments-list'
      await this.listSlot(this.filters)
    }, 500),
    selectAgenda(agendas) {
      const now = moment().format('HH:mm:ss')

      const agendaMatchesTime = find(
        agenda => and(now >= agenda.startTime, now <= agenda.endTime),
        agendas,
      )
      if (agendaMatchesTime) return agendaMatchesTime

      return ifElse(
        isEmpty,
        always(null),
        head,
      )(agendas)
    },
    async checkDoctorAgenda() {
      if (not(isEmpty(this.$route.query))) return
      const { professionId, professionalId } = this.user

      if (includes(professionId, MEDICAL_PROFESSIONS_IDS)) {
        await this.listAgenda({ professionalId, dayOfTheWeek: moment().day() })
        const selectedAgenda = this.selectAgenda(this.availableAgendas)
        if (isNil(selectedAgenda)) return
        this.fillFilters(selectedAgenda)
      }
    },
    closePatientAuditDialog() {
      this.showPatientAuditDialog = false
      this.getPatientRegistrationLength()
    },
    async getPatientRegistrationLength() {
      this.listPatientRegistrationLength({
        status: 'todo',
      })
    },
  },
  validations: {
    filters: {
      clinic: { required },
      speciality: { required },
    },
  },
}
</script>

<style lang="stylus" scoped>
.v-btn.v-btn--large
  margin-top 0px
  height 56px
  border-radius 8px
</style>
