<template>
  <div>
    <v-menu
      v-model="isNewAppointmentOpen"
      transition="scale-transition"
      :position-x="mousePositionX"
      :position-y="mousePositionY"
      :close-on-content-click="false"
      offset-y
    >
      <v-card
        class="px-5 py-6"
        max-width="750"
        min-width="370"
        z-index="98"
        data-testid="agenda-container__new-appointment--card"
      >
        <v-card-title class="tw-text-xl tw-p-0 tw-mb-4 tw-font-medium">
          Novo atendimento

          <v-btn
            class="tw-ml-auto"
            icon
            @click="closeNewAppointmentMenu"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="tw-text-base tw-text-black tw-p-0">
          <p
            v-if="selectedSlot"
            class="tw-font-bold"
          >
            {{ selectedSlot.date | formatDatePtBrLL }} |
            {{ selectedSlot.startTime | removeSecondsFromTime }}
            | {{ selectedSlotInterval }}min
            | Unidade {{ clinicName }}
          </p>

          <div
            v-if="selectedPatient && !selectedPatient.needUpdate"
            class="tw-flex tw-flex-wrap tw-gap-x-4 tw-gap-y-2 tw-py-2 tw-mb-4"
          >
            <span>
              <strong>Nome:</strong> {{ selectedPatient | formatDisplayName }}
            </span>
            <span>
              <strong>{{ selectedPatient | getIdentifierDocumentLabel }}:</strong>
              {{ selectedPatient | getIdentifierDocument }}
            </span>
            <span>
              <strong>Nascimento:</strong> {{ selectedPatient.birthDate | formatDate }}
              - Idade {{ patientAge }}
            </span>
            <span>
              <strong>Contato:</strong> {{ selectedPatient.telphone | formatPhone }}
            </span>
            <span>
              <strong>E-mail:</strong>
              {{ selectedPatient.email ? selectedPatient.email : 'Não informado' }}
            </span>
            <span v-if="selectedPatient.healthMaintenanceOrganization">
              <strong>Operadora:</strong> {{ selectedPatient.healthMaintenanceOrganization.name }}
            </span>
            <span v-if="selectedPatient.healthInsurancePlanNumber">
              <strong>Carteirinha:</strong> {{ selectedPatient.healthInsurancePlanNumber }}
            </span>
          </div>

          <search-patient
            ref="searchInput"
            v-default-patient="selectedPatient"
            class="tw-mb-6"
            label="Buscar paciente"
            hide-details
            return-object
            filled
            clearable
            data-testid="agenda-container__search-patient--input"
            @input="selectedPatient = $event"
          />

          <div v-if="selectedPatient && shouldShowAppointmentTypeSelect">
            <v-select
              v-model="newAppointmentType"
              class="tw-mb-6"
              :items="appointmentTypeList"
              hide-details
              label="Tipo de atendimento"
              filled
              data-testid="agenda-container__appointment-type--select"
            />

            <v-checkbox
              v-if="shouldShowSpontaneousDemand"
              v-model="appointmentIsSpontaneousDemand"
              hide-details
              class="tw-m-0 tw-p-0 tw-mb-6 tw-font-light"
              color="green"
              label="Demanda Espontânea"
              data-testid="create-appointment__appointment-is-spontaneous-demand--checkbox"
            />
          </div>

          <v-alert
            v-if="appointmentIsExtendedByItems"
            class="tw-bg-gray-light"
            icon="mdi-alert"
            data-testid="agenda-container__appointment-extended-by-item--alert"
          >
            Devido aos itens selecionados, o agendamento exigirá a alocação de 2 slots.
            Garanta que esta é a configuração correta.
          </v-alert>

          <div
            v-if="patientNotEligible"
            class="tw-font-normal tw-text-gray tw-text-2xl tw-mb-2"
          >
            <v-divider class="tw-mb-2" />
            Esse usuário <b>não está elegível</b> pela operadora. <br>
            Deseja prosseguir com o agendamento?
          </div>
        </v-card-text>

        <v-card-actions class="tw-p-0 tw-grid tw-grid-cols-1 tw-gap-3">
          <div v-if="selectedPatient?.needUpdate">
            Paciente com cadastro incompleto.
            <v-btn
              class="btn btn-info tw-font-medium"
              block
              large
              @click="editDialog = true"
            >
              Editar paciente
            </v-btn>
          </div>

          <v-btn
            class="btn btn-info tw-font-medium"
            block
            large
            @click="redirectToSmartAgenda"
          >
            Ir para Agenda Inteligente
          </v-btn>

          <div class="tw-grid tw-grid-flow-col tw-auto-cols-fr tw-gap-x-3">
            <v-btn
              v-if="patientNotEligible"
              class="btn btn-secondary"
              block
              large
              @click="closeNewAppointmentMenu()"
            >
              Cancelar
            </v-btn>

            <v-btn
              class="btn btn-primary"
              :disabled="disabledScheduleButton"
              :loading="isLoadingCreateAppointment"
              block
              large
              data-testid="agenda-container__schedule-appointment--button"
              @click="handleOpenConfirmModal"
            >
              {{ isWorkSpaceAmparo ? 'Prosseguir Agendamento' : 'Agendar' }}
            </v-btn>
          </div>

          <div
            v-if="patientIsStored"
            class="tw-text-danger"
          >
            Paciente arquivado
          </div>
        </v-card-actions>
      </v-card>
    </v-menu>

    <v-list
      data-testid="agenda-container__slots--container"
      class="tw-p-0"
    >
      <div
        v-for="(slot, index) in slots"
        :key="slot.id"
        :class="[
          'tw-flex',
          'tw-items-center',
          'tw-pl-12',
          'agenda-slot',
          {
            'agenda-slot--block': slot.block,
            'agenda-slot--extended': isExtendedSlot(slot.id),
          }
        ]"
        :data-testid="`agenda-container__slots-${index}`"
        @click="showNewAppointmentMenu($event, slot)"
      >
        <div class="agenda-slot__time tw-font-bold">
          {{ slot.startTime | removeSecondsFromTime }}H
        </div>

        <div
          v-if="shouldShowTimeline(slot)"
          class="agenda__timeline timeline--is-able"
        >
          <div class="agenda__timeline-line" />
        </div>

        <transition-group
          name="reverse-slide"
          class="tw-flex tw-flex-wrap tw-gap-4 tw-m-4"
        >
          <div
            v-for="(appointment, appointmentIndex) in appointmentsBySlot(slot.id)"
            :key="appointment.id"
            @click.stop
          >
            <appointment-card
              :ref="appointment.id"
              :data-testid="`agenda-container__slots-card-${appointmentIndex}`"
              :is-auto-open-activated="isAutoOpenActivated"
              :show-edit-appointment="showEditAppointment"
              :appointment-data="{ appointment, slot }"
              :appointment-index="appointmentIndex"
              @selectedAppointment="showEditAppointmentMenu"
            />
          </div>
        </transition-group>

        <div
          v-if="hasSpotlightOrBlock(slot)"
          class="
            tw-flex
            tw-absolute
            tw-w-screen
            tw-justify-center
            tw-text-xs
            tw-uppercase
            tw-leading-normal
            tw-gap-x-4
            tw--ml-12
          "
          @click.prevent.self="showNewAppointmentMenu($event, slot)"
        >
          <span
            v-if="slot.spotlight"
            class="tw-flex tw-items-center tw-text-[#5c00cc] tw-font-bold"
          >
            <v-icon
              class="tw-text-current"
              size="18"
              left
            >
              mdi-star
            </v-icon>
            {{ slot.spotlight.reason }}
          </span>

          <span
            v-if="slot.block"
            class="tw-flex tw-items-center tw-text-black tw-opacity-50 tw-font-bold"
          >
            <v-icon
              class="tw-text-current"
              size="18"
              left
            >
              mdi-cancel
            </v-icon>
            {{ slot.block | blockReason }}
          </span>
        </div>

        <div
          v-if="slot.restriction && !slot.block"
          class="tw-max-w-60 tw-ml-auto tw-mr-8"
          @click.stop
        >
          <v-select
            class="
              agenda-slot__restriction-warnings
              v-text-field__cursor--pointer
              tw-p-0
            "
            color="tech-yellow"
            hide-details
            :items="getRestrictionItems(slot.restriction)"
            :menu-props="{
              offsetY: true,
              maxWidth: 240,
              closeOnClick: true,
              closeOnContentClick: true
            }"
          >
            <template v-slot:prepend-inner>
              <v-icon
                left
                color="tech-yellow"
              >
                mdi-alert
              </v-icon>
            </template>
            <template
              v-slot:label
              class="tw-p-0"
            >
              <span class="tw-uppercase">
                Ver restrições
              </span>
            </template>
            <template v-slot:item="{ item }">
              <v-list-item class="hover:tw-bg-black hover:tw-bg-opacity-5">
                <v-tooltip
                  left
                  max-width="330"
                >
                  <template v-slot:activator="{ on }">
                    <v-list-item-content
                      class="tw-font-medium"
                      v-on="on"
                    >
                      {{ item.text }}:
                      <span
                        :class="{
                          'tw-pt-2 tw-truncate': true,
                          'tw-font-semibold': item.textBold
                        }"
                      >
                        {{ item.textValue }}
                      </span>
                    </v-list-item-content>
                  </template>
                  <span>
                    {{ item.textValue }}
                  </span>
                </v-tooltip>
              </v-list-item>
            </template>
          </v-select>
        </div>

        <div
          v-if="slot.reserves"
          class="tw-absolute tw-right-14"
        >
          <v-chip disabled>
            {{ slot.reserves[0] }}
          </v-chip>
        </div>
      </div>
    </v-list>

    <create-appointment
      v-if="showConfirmOrderItemsModal"
      origin="agenda"
      title="Novo Atendimento"
      :patient="selectedPatient"
      :clinic-id="selectedSlot.clinic?.id"
      :selected-slot="selectedSlot"
      :items="newAppointmentItems"
      :contract="newAppointmentContract"
      :slots="slots"
      :show-dialog="showConfirmOrderItemsModal"
      @setAppointmentData="setAppointmentData"
      @close="closeConfirmOrderItemsModal"
    />

    <v-dialog
      v-model="editDialog"
      fullscreen
      transition="dialog-bottom-transition"
    >
      <v-card>
        <CreatePatient
          :patient-id="patientId"
          @closeEditDialog="closeEditDialog"
          @patientUpdated="refreshSelectedPatient"
        />
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="appointmentWithReserveDialog"
      max-width="420"
    >
      <v-card class="tw-p-4">
        <v-card-title class="tw-text-xl tw-p-0 tw-mb-2">
          O atendimento não pode ser agendado devido ao(s) item(s) abaixo:
        </v-card-title>
        <v-card-text class="tw-text-base tw-text-black tw-break-words tw-p-0 tw-mb-2">
          <ul v-if="alertModalMessageText.length">
            <li
              v-for="message in alertModalMessageText"
              :key="message"
            >
              {{ message }}
            </li>
          </ul>
        </v-card-text>
        <v-card-actions class="tw-justify-center tw-p-0">
          <v-btn
            large
            text
            class="btn btn-secondary"
            @click="appointmentWithReserveDialog = false"
          >
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <edit-appointment-modal
      v-if="showEditAppointment"
      origin="agenda"
      :mouse-position-x="mousePositionX"
      :mouse-position-y="mousePositionY"
      :appointment-data="appointmentModalData"
      @toggleShowEditAppointment="toggleShowEditAppointment"
      @setEligibilityOnPatient="setEligibilityOnPatient"
    />
  </div>
</template>

<script>
import moment from 'moment'
import SearchPatient from '@/components/Common/SearchPatient'
import {
  T,
  always,
  and,
  clone,
  cond,
  equals,
  findIndex,
  gt,
  head,
  includes,
  isEmpty,
  isNil,
  length,
  lte,
  map,
  not,
  or,
  path,
  propEq,
} from 'ramda'
import { mapActions, mapGetters } from 'vuex'
import appointmentEnum from '@/enums/appointment'
import {
  APPOINTMENT,
  ELIGIBILITY,
  HEALTH_PRODUCT,
  HMO,
} from 'amparo-enums'
import professionEnum from '@/enums/profession'
import patientEnum from '@/enums/patient'
import specialityEnum from '@/enums/speciality'
import getAppointmentErrorMessage from '@/utils/getAppointmentErrorMessage'
import { smartAgendaStore } from '@/store'

const { FAMILY_DOCTOR } = specialityEnum

const { PREGNANT_WARNING_CONDITIONS } = appointmentEnum

const {
  NUTRITIONIST,
  PSYCHOLOGIST,
  DOCTOR,
} = professionEnum

const { RESERVATION_PATIENTS } = patientEnum

export default {
  components: {
    CreatePatient: () => import('@/components/CreatePatient'),
    EditAppointmentModal: () => import('@/components/EditAppointmentModal'),
    AppointmentCard: () => import('@/components/Common/AppointmentCard'),
    CreateAppointment: () => import('@/components/CreateAppointment/CreateAppointment'),
    SearchPatient,
  },
  props: {
    agendaFilters: {
      type: Object,
      required: true,
    },
    query: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      savedEvent: '',
      savedSlot: '',
      isLoadingActivationJourney: false,
      isLoadingCreateAppointment: false,
      mousePositionX: 0,
      mousePositionY: 0,
      extendedAppointmentWarningMessage: '',
      needsExtendedAppointment: false,
      editDialog: false,
      appointmentWithReserveDialog: false,
      shouldForcePresentialAppointment: false,
      canExtendAppointment: false,
      isNewAppointmentOpen: false,
      selectedSlot: null,
      showEditAppointment: false,
      selectedPatient: null,
      selectedAppointment: {},
      isPatientOnActivationJourney: false,
      refreshAppointmentInterval: null,
      newAppointmentNotes: null,
      firstAccessScroll: true,
      firstAppointmentScroll: true,
      newAppointmentType: null,
      newAppointmentContract: null,
      newAppointmentItems: null,
      newAppointmentOrderProfessionalId: null,
      newAppointmentIsRelatedToActivationJourney: false,
      appointmentItemsList: [],
      appointmentContractsList: [],
      extendedReason: null,
      appointmentIsExtended: false,
      appointmentIsExtendedByItems: false,
      appointmentIsSpontaneousDemand: false,
      pregnantConditionsWarning: PREGNANT_WARNING_CONDITIONS,
      eligibility: {},
      alertModalMessageText: [],
      hmoNotAllowedToCreateContractItems: [
        HMO.carePlus,
      ],
      journey: {},
      showConfirmOrderItemsModal: false,
      hasExtendedFirstAppointmentByJourney: false,
    }
  },
  computed: {
    ...mapGetters({
      slots: 'agenda/slots',
      appointments: 'agenda/appointments',
      attendanceId: 'virtualReception/attendanceId',
      isWorkSpaceAmparo: 'authentication/isWorkSpaceAmparo',
      attendancePatient: 'virtualReception/attendancePatient',
      patientSpecialConditions: 'patient/patientSpecialConditions',
      patientConditions: 'patient/patientConditions',
      isLoadingPatientSpecialConditions: 'patient/isLoadingPatientSpecialConditions',
      shouldScheduleFirstAppointment: 'patient/shouldScheduleFirstAppointment',
      hasActivationJourney: 'patient/hasActivationJourney',
    }),
    matchesFirstAppointmentAttributes() {
      return this.isMfcAppointment
        && (!this.newAppointmentType || includes(
          this.newAppointmentType,
          [APPOINTMENT.types.presential, APPOINTMENT.types.remote],
        ))
    },
    appointmentModalData() {
      return {
        ...this.selectedSlot,
        ...this.selectedAppointment,
        eligibilityStatus: this.selectedAppointment.eligibility?.status,
        date: this.agendaFilters.date,
      }
    },
    disabledScheduleButton() {
      const needsExtendedAppointment = this.needsExtendedAppointment
        || this.appointmentIsExtendedByItems
        || this.hasExtendedFirstAppointmentByJourney

      return not(this.selectedPatient)
        || this.selectedPatient.needUpdate
        || this.patientIsStored
        || this.isLoadingActivationJourney
        || this.isLoadingPatientSpecialConditions
        || (!this.canExtendAppointment && needsExtendedAppointment)
        || (!this.isWorkSpaceAmparo && !this.newAppointmentType)
    },
    patientIsStored() {
      return this.selectedPatient
        ? not(isNil(this.selectedPatient.storedAt))
        : false
    },
    isAgendaRedirect() {
      return not(isEmpty(this.$route.query))
    },
    filterDateFormated() {
      if (!this.agendaFilters.date) return ''
      return moment(this.agendaFilters.date)
        .locale('pt-br')
        .format('LL')
    },
    clinicName() {
      return this.selectedSlot ? this.selectedSlot.clinic.name : ''
    },
    patientId() {
      return this.selectedPatient ? this.selectedPatient.id : false
    },
    selectedSlotInterval() {
      if (isNil(this.selectedSlot)) return null
      return this.appointmentIsExtended
        ? this.selectedSlot.interval * 2
        : this.selectedSlot.interval
    },
    patientNotEligible() {
      const error = path(['eligibility', 'errorCode'], this)
      return (
        equals(error, ELIGIBILITY.status.cmResponseError)
        || equals(error, ELIGIBILITY.status.notEligible)
      )
    },
    isMfcAppointment() {
      const specialityId = path(['speciality', 'id'], this.agendaFilters)
      return equals(FAMILY_DOCTOR.id, specialityId)
    },
    patientAge() {
      return moment().diff(this.selectedPatient?.birthDate, 'years')
    },
    shouldShowScheduleAppointmentTooltip() {
      return this.canExtendAppointment
        && this.hasExtendedFirstAppointmentByJourney
    },
    appointmentTypeList() {
      if (this.shouldForcePresentialAppointment) return [APPOINTMENT.types.presential]
      if (this.selectedSlot?.restriction?.appointmentType) {
        return this.selectedSlot.restriction.appointmentType
      }

      return [
        APPOINTMENT.types.presential,
        APPOINTMENT.types.remote,
        APPOINTMENT.types.spontaneous,
        APPOINTMENT.types.procedure,
        APPOINTMENT.types.telemonitoring,
        APPOINTMENT.types.administrativeDemand,
      ]
    },
    shouldShowAppointmentTypeSelect() {
      return !this.isWorkSpaceAmparo
    },
    shouldShowSpontaneousDemand() {
      return moment().add(1, 'day').isAfter(moment(this.selectedSlot.date))
    },
    appointmentHealthProductId() {
      return this.newAppointmentContract?.healthProductId
        || this.selectedPatient?.healthProductId
    },
  },
  watch: {
    query: {
      deep: true,
      immediate: true,
      handler() {
        this.isAutoOpenActivated = true
      },
    },
    async selectedPatient(newPatient) {
      if (!isNil(newPatient)) {
        this.isLoadingActivationJourney = true
        try {
          await this.setAppointmentContractsList()
          await this.updatePatientSpecialConditions(this.selectedPatient)

          this.shouldForcePresentialAppointment = await this.isPostalFirstAppointment()
          this.isLoadingActivationJourney = false
        } catch (err) {
          this.isLoadingActivationJourney = false
        }
      }
      this.eligibility = {}
    },
  },
  activated() {
    this.scrollToTimeline()
    this.refreshAgenda()
  },
  created() {
    this.refreshAgenda()
  },
  mounted() {
    if (this.firstAccessScroll) this.scrollToTimeline()
  },
  updated() {
    if (this.firstAccessScroll) this.scrollToTimeline()
  },
  deactivated() {
    this.clearRefreshAppointment()
  },
  beforeDestroy() {
    this.clearRefreshAppointment()
  },
  methods: {
    ...mapActions({
      createAppointment: 'agenda/createAppointment',
      createVideoRoom: 'agenda/createVideoRoom',
      listAppointment: 'agenda/listAppointment',
      listCareLinePatient: 'careLine/listCareLinePatient',
      listSlot: 'agenda/listSlot',
      removeVideoRoom: 'agenda/deleteVideoRoom',
      setSnackbar: 'snackbar/setSnackbar',
      updateAppointment: 'agenda/updateAppointment',
      createOrder: 'billing/createOrder',
      updatePatientSpecialConditions: 'patient/updatePatientSpecialConditions',
      shouldScheduleEngagementAppointment: 'patient/shouldScheduleEngagementAppointment',
      searchContractItem: 'billing/searchContractItem',
      getActualContract: 'billing/getActualContract',
      getHmoById: 'healthMaintenanceOrganization/getById',
      hasValidActivationJourneyAttributes: 'patient/hasValidActivationJourneyAttributes',
    }),
    appointmentsBySlot(slotId) {
      return this.appointments.filter(
        appointment => this.returnFirstAppointmentSlot(appointment.slots) === slotId,
      )
    },
    getRestrictionText(restrictionItems, wordGenre = 'f') {
      const itemNames = restrictionItems
        ? restrictionItems.map(item => item.name || item)
        : null

      if (!itemNames) {
        return {
          res: wordGenre === 'f' ? 'Todas' : 'Todos',
          isTextBold: true,
        }
      }

      if (isEmpty(itemNames)) {
        return {
          res: wordGenre === 'f' ? 'Nenhuma' : 'Nenhum',
          isTextBold: true,
        }
      }

      return { res: itemNames.join(', ') }
    },
    getRestrictionItems(slotRestrictions) {
      const {
        appointmentType,
        hmos,
        userProfessions,
        scheduleByPatient,
        scheduleByOwner,
      } = slotRestrictions

      const { res: hmoText, isTextBold: hmoTextBold } = this.getRestrictionText(hmos, 'm')

      const {
        res: appointmentTypeText,
        isTextBold: appointmentTypeTextBold,
      } = this.getRestrictionText(appointmentType)

      const {
        res: userProfessionsText,
        isTextBold: userProfessionsTextBold,
      } = this.getRestrictionText(userProfessions)

      const formattedRestrictions = [
        {
          text: 'Modalidades de consulta permitidas',
          textValue: appointmentTypeText,
          textBold: appointmentTypeTextBold,
        },
        {
          text: 'Planos de saúde permitidos',
          textValue: hmoText,
          textBold: hmoTextBold,
        },
        {
          text: 'Profissões permitidas',
          textValue: userProfessionsText,
          textBold: userProfessionsTextBold,
        },
        {
          text: 'Permitir agendamentos pelo Meu Amparo',
          textValue: scheduleByPatient ? 'Sim' : 'Não',
          textBold: true,
        },
        {
          text: 'Permitir agendamentos pelo profissional da agenda',
          textValue: scheduleByOwner ? 'Sim' : 'Não',
          textBold: true,
        },
      ]

      return formattedRestrictions
    },
    async isRelatedToActivationJourney() {
      return this.hasValidActivationJourneyAttributes({
        specialityId: this.agendaFilters.speciality.id,
        appointmentType: this.newAppointmentType,
        reschedule: {},
      })
    },
    async getContract(healthProductId) {
      const contract = await this.getActualContract(healthProductId)
      this.contract = contract
      return contract
    },
    async setAppointmentContractsList() {
      this.appointmentContractsList = []

      if (this.isWorkSpaceAmparo) {
        const patientContract = await this.getContract(this.selectedPatient.healthProductId)

        const defaultContractItems = patientContract?.id
          ? [
            {
              id: patientContract.id,
              name: this.selectedPatient.healthProduct.name,
              healthMaintenanceOrganizationId: patientContract.hmoId,
              healthProductId: patientContract.healthProductId,
              journeys: patientContract.journeys,
            },
          ]
          : []

        if (!equals(this.selectedPatient.healthProductId, HEALTH_PRODUCT.private)) {
          const privateContract = await this.getContract(HEALTH_PRODUCT.private)
          this.appointmentContractsList = [
            ...defaultContractItems,
            {
              id: privateContract?.id,
              name: 'PARTICULAR',
              healthMaintenanceOrganizationId: HMO.private,
              healthProductId: HEALTH_PRODUCT.private,
              journeys: privateContract.journeys,
            },
          ]
        } else {
          this.appointmentContractsList = defaultContractItems
        }

        this.newAppointmentContract = head(this.appointmentContractsList)
      }
    },
    getAppointmentItemName(item) {
      const clinicLabel = item.clinicId
        ? `- ${item.clinic.name}`
        : ''

      if (item.itemMnemonic) {
        const { itemMnemonic } = item
        return `${item.item?.name} ${clinicLabel} - ${itemMnemonic.mnemonic} - ${itemMnemonic.location}`
      }

      return `${item.item?.name} ${clinicLabel}`
    },
    toggleShowEditAppointment(event) {
      this.showEditAppointment = event
    },
    redirectToSmartAgenda() {
      const newPath = `patient/${this.selectedPatient.id}`
      this.$router.push({ path: newPath, query: { openSmartAgenda: null } })
    },
    getAppointmentHmoId(patient) {
      return this.newAppointmentContract?.hmoId
        || patient?.healthMaintenanceOrganizationId
    },
    async isPostalFirstAppointment() {
      const amparoHmoId = this.selectedPatient.healthMaintenanceOrganizationId
      if (not(equals(amparoHmoId, HMO.postal))) return false

      const { hasPostalOrder } = await smartAgendaStore.verifyPostalActivation(
        this.selectedPatient.id,
      )
      return not(hasPostalOrder)
    },
    deactivateAutoOpen() {
      this.isAutoOpenActivated = false
    },
    updateAgenda() {
      this.listSlot(this.agendaFilters)
      this.listAppointment(this.agendaFilters)
    },
    clearRefreshAppointment() {
      clearTimeout(this.refreshAppointmentInterval)
      this.refreshAppointmentInterval = null
    },
    setFirstAppointmentScroll(value) {
      this.firstAppointmentScroll = value
    },
    copyPatientLink() {
      document.querySelector('#patientLink').select()
      document.execCommand('copy')
    },
    getPatientId(patient) {
      if (patient) return patient.id
      return null
    },
    getAppointmentCreatedBy({ activityLog }) {
      return activityLog[activityLog.length - 1].userName
        ? activityLog[activityLog.length - 1].userName : 'Amparo Tech'
    },
    getWhatsAppApi(telphone) {
      return `https://api.whatsapp.com/send?phone=55${telphone}`
    },
    refreshSelectedPatient(newerPatient) {
      this.selectedPatient = newerPatient
    },
    shouldShowWaitingTime(appointment) {
      return appointment.status === 'received'
    },
    getAppointmentWaitingTime({ activityLog }) {
      let waitingTime = 0
      for (const { status, changedAt } of activityLog) {
        if (equals(status, 'received')) {
          waitingTime = moment().diff(changedAt, 'minutes')
        }
      }
      return cond([
        [lte(60), always(`${Math.floor(waitingTime / 60)}h`)],
        [T, always(`${waitingTime}m`)],
      ])(waitingTime)
    },
    shouldShowNameTooltip() {
      const selectedPatientName = this.$filters.formatDisplayName(this.selectedAppointment.patient)
      return this.selectedAppointment.patient
        && selectedPatientName.length >= 22
    },
    shouldShowTimeline(slot) {
      return (slot.startTime === this.currentTime(slot.interval))
        && (moment(slot.date).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD'))
    },
    refreshAgenda() {
      const REFRESH_TIME = 60000
      if (this.refreshAppointmentInterval) this.clearRefreshAppointment()
      this.refreshAppointmentInterval = setTimeout(async function interval() {
        try {
          await this.updateAgenda(this.agendaFilters)
          this.refreshAppointmentInterval = setTimeout(interval.bind(this), REFRESH_TIME)
        } catch (error) {
          this.clearRefreshAppointment()
        }
      }.bind(this), REFRESH_TIME)
    },
    shouldSkipCoreAccreditation(profession) {
      const requiredProfessionsToValide = [
        NUTRITIONIST.id,
        PSYCHOLOGIST.id,
        DOCTOR.id,
      ]
      return not(includes(profession, requiredProfessionsToValide))
    },
    checkClinicCoreAccreditation(hmoData, slotDatas) {
      const hmoClinicsArray = hmoData?.coreAccreditation?.clinics
      const choosedClinic = path(['clinic', 'id'], slotDatas)

      const hasPermittion = isNil(hmoClinicsArray) || includes(choosedClinic, hmoClinicsArray)
      if (!hasPermittion) {
        this.alertModalMessageText
          .push(`A clínica ${path(['clinic', 'name'], slotDatas)} não está credenciada no plano ${path(['healthMaintenanceOrganization', 'name'], this.selectedPatient)} `)
      }

      return hasPermittion
    },
    checkProfessionalCoreAccreditation(hmoData, slotDatas) {
      const profession = path(['professional', 'profession', 'id'], slotDatas)
      if (this.shouldSkipCoreAccreditation(profession)) return true

      const hmoProfessionalsArray = hmoData?.coreAccreditation?.professionals
      const choosedProfessional = path(['professional', 'id'], slotDatas)
      const hasPermittion = isNil(hmoProfessionalsArray)
        || includes(choosedProfessional, hmoProfessionalsArray)

      if (!hasPermittion) {
        this.alertModalMessageText
          .push(`O profissional ${path(['professional', 'name'], slotDatas)} não está credenciado no plano ${path(['healthMaintenanceOrganization', 'name'], this.selectedPatient)}`)
      }

      return hasPermittion
    },
    checkIfPatientHasAllowedHMOInSlot(hmoData, slotDatas) {
      const hasPermittion = not(slotDatas.reserves)
        || includes(hmoData?.nickName, slotDatas.reserves)

      if (!hasPermittion) {
        this.alertModalMessageText.push('Essa reserva já está sendo utilizada por outro paciente')
      }

      return hasPermittion
    },
    async handleCreateAppointment(patientData, slotDatas) {
      this.alertModalMessageText = []

      const hmoData = this.newAppointmentContract
        ? await this.getHmoById(this.getAppointmentHmoId(patientData))
        : patientData.healthMaintenanceOrganization

      const hasClinicPermition = this.checkClinicCoreAccreditation(hmoData, slotDatas)
      const hasProfessionalPermition = this
        .checkProfessionalCoreAccreditation(hmoData, slotDatas)
      const hasSlotPermition = this.checkIfPatientHasAllowedHMOInSlot(hmoData, slotDatas)

      if (not(hasClinicPermition) || not(hasSlotPermition) || not(hasProfessionalPermition)) {
        this.isLoadingCreateAppointment = false
        return this.toggleAppointmentWithReserveDialog()
      }

      return this.registerNewAppointment(patientData, slotDatas)
    },
    toggleAppointmentWithReserveDialog() {
      this.appointmentWithReserveDialog = !this.appointmentWithReserveDialog
    },
    toggleAppointmentIsExtended() {
      this.appointmentIsExtended = !this.appointmentIsExtended
    },
    currentTime(slotTime) {
      const roundMinute = Math.floor(moment().minute() / slotTime) * slotTime
      return moment().minute(roundMinute).seconds('00').format('HH:mm:ss')
    },
    setAppointmentClasses(appointment, slot) {
      const appointmentClass = this.isPatientLate(appointment, slot)
        ? 'late'
        : appointment.status
      const isExtended = gt(length(appointment.slots), 1) && '--extended'

      if (appointment.slots.length > 1) {
        return [
          `appointment-icon--${appointmentClass}`,
          `appointment-icon${isExtended}`,
          `agenda-slot__appointment${isExtended} font-weight-bold`,
        ]
      }
      return [
        `appointment-icon--${appointmentClass}`,
        'appointment-icon',
        'agenda-slot__appointment font-weight-bold',
      ]
    },
    isPatientLate(appointment, slot) {
      const arrivedStatus = [
        APPOINTMENT.status.received.value,
        APPOINTMENT.status.attended.value,
        APPOINTMENT.status.missed.value,
        APPOINTMENT.status.unsuccessfulContact.value,
      ]
      const hasNotArrivedAtClinic = not(includes(appointment.status, arrivedStatus))

      if (moment(appointment.date).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD')) {
        return hasNotArrivedAtClinic
      }
      if (moment(appointment.date).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')) {
        return hasNotArrivedAtClinic && appointment.startTime < this.currentTime(slot.interval)
      }
      return false
    },
    scrollToTimeline() {
      const timeline = document.querySelector('.timeline--is-able')
      this.scrollTo(timeline)
      this.firstAccessScroll = false
    },
    scrollTo(element) {
      const windowHeight = window.innerHeight
      if (!element || element.offsetTop < windowHeight) return
      const offsetDiff = element.offsetTop - (windowHeight / 2)
      window.scrollTo({
        top: offsetDiff,
        behavior: 'smooth',
      })
    },
    hasSpotlightOrBlock(slot) {
      return slot.spotlight || slot.block
    },
    closeNewAppointmentMenu() {
      this.isNewAppointmentOpen = false
    },
    shouldCreateVideoCredentials(
      {
        type,
        videoRoomId,
      },
    ) {
      const shouldCreateVideo = and(
        includes(type, [APPOINTMENT.types.remote, APPOINTMENT.types.remoteSession]),
        isNil(videoRoomId),
      )
      return shouldCreateVideo
    },
    insertVideoRoomInAppointment(videoRoomId, appointmentId) {
      return this.updateAppointment({
        id: appointmentId,
        attributes: { videoRoomId },
      })
    },
    async deleteVideoRoom(appointmentId) {
      try {
        const { videoRoomId } = this.selectedAppointment
        await this.removeVideoRoom(videoRoomId)
        return this.updateAppointment({
          id: appointmentId,
          attributes: { videoRoomId: null },
        })
      } catch (error) {
        return error
      }
    },
    allowExtendAppointment(slot) {
      const indexSlot = findIndex(propEq(slot.id, 'id'), this.slots)
      if (slot.agenda?.maxSlots < 2) {
        return false
      }
      if (this.slots.length - 1 === indexSlot) {
        return false
      }
      if (isNil(this.slots[indexSlot + 1])) {
        return false
      }
      if (this.slots[indexSlot + 1].slotAppointments.length > 0) {
        return false
      }
      return true
    },
    buildSnackbar(status, message) {
      this.setSnackbar({ status, message })
    },
    async showEditAppointmentMenu({ e, slot, appointmentData }) {
      this.mousePositionX = e.clientX
      this.mousePositionY = e.clientY
      this.deactivateAutoOpen()
      this.showEditAppointment = false
      this.selectedAppointment = appointmentData
      this.selectedSlot = slot
      this.appointmentIsExtended = false
      this.appointmentIsExtendedByItems = false
      this.$nextTick(() => {
        this.showEditAppointment = true
      })
    },
    setFocusOnSearchPatientAutocomplete() {
      setTimeout(() => {
        this.$refs.searchInput.$children[0].focus()
      }, 300)
    },
    showNewAppointmentMenu(event, slot) {
      if (slot.block || this.isNewAppointmentOpen) return
      this.newAppointmentType = null
      this.selectedSlot = slot
      this.canExtendAppointment = this.allowExtendAppointment(slot)
      this.mousePositionX = event.clientX
      this.mousePositionY = event.clientY
      this.selectedPatient = null
      this.newAppointmentContract = null
      this.newAppointmentItems = []
      this.newAppointmentOrderProfessionalId = null
      this.appointmentContractsList = []
      this.appointmentItemsList = []
      this.$nextTick(() => {
        this.appointmentIsExtended = false
        this.appointmentIsSpontaneousDemand = false
        this.appointmentIsExtendedByItems = false
        this.isNewAppointmentOpen = true
        if (not(isNil(this.attendancePatient))) {
          this.setAttendanceSelectedPatient()
        }
        this.setFocusOnSearchPatientAutocomplete()
      })
    },
    setAttendanceSelectedPatient() {
      this.selectedPatient = clone(this.attendancePatient)
    },
    handleNewAppointmentParams(data) {
      const notFollowsExtendedRequirements = and(
        this.needsExtendedAppointment,
        or(not(this.appointmentIsExtended), not(this.canExtendAppointment)),
      )

      const appointmentDataLogs = {
        isFirstAppointmentAfterActivationJourney: this.shouldScheduleFirstAppointment
          && this.isMfcAppointment,
        isPatientOnActivationJourney: this.hasActivationJourney,
        needsExtendedAppointment: this.needsExtendedAppointment,
        message: this.extendedAppointmentWarningMessage,
      }

      const agendaOptionsLog = notFollowsExtendedRequirements
        ? appointmentDataLogs
        : null

      return {
        agendaOptionsLog,
        ...data,
      }
    },
    async parseDataToNewAppointment(patient, slotDatas) {
      const slotsId = [slotDatas.id]
      if (this.appointmentIsExtended && this.canExtendAppointment) {
        slotsId.push(this.createExtendedAppointment(slotDatas))
      }

      const isRelatedToActivationJourney = this.newAppointmentIsRelatedToActivationJourney
      const healthProductId = isRelatedToActivationJourney
        ? this.appointmentHealthProductId : null
      const data = {
        patientId: patient.id,
        slotsId,
        type: this.newAppointmentType,
        origin: 'agenda',
        extendedReason: this.extendedReason,
        isRelatedToActivationJourney,
        isSpontaneousDemand: this.appointmentIsSpontaneousDemand,
        notes: this.newAppointmentNotes,
        healthMaintenanceOrganizationId: this.getAppointmentHmoId(patient),
        healthProductId,
      }
      if (this.attendanceId
        && equals(path(['id'], this.attendancePatient), patient.id)
      ) {
        data.virtualReceptionId = this.attendanceId
      }

      return this.handleNewAppointmentParams(data)
    },
    createExtendedAppointment(slotDatas) {
      const indexSlot = findIndex(propEq(slotDatas.id, 'id'), this.slots)
      return this.slots[indexSlot + 1].id
    },
    isEmptyOrIsNil(value) {
      return isEmpty(value) || isNil(value)
    },
    getNewAppointmentItemsFormatted(items) {
      if (this.isEmptyOrIsNil(items)) return undefined

      return map(
        item => ({
          id: item.id,
          itemId: item.itemId,
          itemMnemonicId: item.itemMnemonic?.id,
          quantity: item.quantity,
        }),
        items,
      )
    },
    async initializeOrder(appointment, patient) {
      if (includes(patient.id, RESERVATION_PATIENTS)) return

      await this.createOrder({
        appointmentId: appointment.id,
        appointmentType: appointment.type,
        date: appointment.date,
        patientId: patient.id,
        specialityId: this.agendaFilters.speciality.id,
        clinicId: this.agendaFilters.clinic.id,
        professionalId: this.newAppointmentOrderProfessionalId
          || this.agendaFilters.professional.id,
        healthMaintenanceOrganizationId: this.getAppointmentHmoId(patient),
        healthProductId: this.appointmentHealthProductId,
        items: this.getNewAppointmentItemsFormatted(this.newAppointmentItems),
      })
    },
    async registerNewAppointment(patientData, slotDatas) {
      if (this.appointmentWithReserveDialog) this.toggleAppointmentWithReserveDialog()
      try {
        const { data: appointment } = await this.createAppointment(
          await this.parseDataToNewAppointment(patientData, slotDatas),
        )
        if (this.shouldCreateVideoCredentials(appointment)) {
          const { data: { id: videoRoomId } } = await this.createVideoRoom({
            appointmentId: appointment.id,
            patientId: appointment.patientId,
            professionalId: appointment.professionalId,
          })
          await this.insertVideoRoomInAppointment(videoRoomId, appointment.id)
        }
        if (this.isWorkSpaceAmparo) {
          await this.initializeOrder(appointment, patientData)
        }
        await this.updateAgenda(this.agendaFilters)
        this.closeNewAppointmentMenu()
      } catch (err) {
        const errorCode = err.response?.data?.errorCode
        const errorMessage = getAppointmentErrorMessage(errorCode)
        this.buildSnackbar('error', errorMessage)
      } finally {
        this.isLoadingCreateAppointment = false
      }
    },
    returnFirstAppointmentSlot(slots) {
      if (slots.length < 2) {
        return slots[0]
      }
      const indexSlot1 = findIndex(propEq(slots[0], 'id'), this.slots)
      const indexSlot2 = findIndex(propEq(slots[1], 'id'), this.slots)
      if (or(indexSlot1 === -1, indexSlot2 === -1)) return null
      const firstSlotTime = moment(this.slots[indexSlot1].startTime, 'hh:mm:ss')
      const secondSlotTime = moment(this.slots[indexSlot2].startTime, 'hh:mm:ss')
      if (secondSlotTime.isBefore(firstSlotTime)) {
        return slots[1]
      }
      return slots[0]
    },
    goToVideoEncounter({ videoEncounterUrl }) {
      window.open(videoEncounterUrl)
    },
    async getPatient({ id }) {
      this.$http.get(`/patient?id=${id}`)
        .then((res) => {
          if (res.data.count > 0) [this.selectedPatient] = res.data.patients
        })
        .catch(() => (this.selectedPatient = null))
    },
    async closeEditDialog() {
      await this.getPatient(this.selectedPatient)
      this.editDialog = false
    },
    setEligibilityOnPatient(eligibility) {
      this.selectedAppointment = {
        ...this.selectedAppointment,
        patient: {
          ...this.selectedAppointment.patient,
          lastCheckEligibility: eligibility,
        },
      }
    },
    async handleOpenConfirmModal() {
      if (!this.isWorkSpaceAmparo) {
        this.isLoadingCreateAppointment = true
        await this.handleCreateAppointment(
          this.selectedPatient,
          this.selectedSlot,
        )
        return
      }

      this.openConfirmOrderItems()
    },
    openConfirmOrderItems() {
      this.showConfirmOrderItemsModal = true
    },
    closeConfirmOrderItemsModal() {
      this.showConfirmOrderItemsModal = false
    },
    setAppointmentData(appointmentData) {
      this.extendedReason = appointmentData.extendedReason
      this.appointmentIsExtended = appointmentData.appointmentIsExtended
      this.appointmentIsSpontaneousDemand = appointmentData.isSpontaneousDemand
      this.newAppointmentType = appointmentData.newAppointmentType
      this.newAppointmentItems = appointmentData.orderItems
      this.newAppointmentOrderProfessionalId = appointmentData.orderProfessionalId
      this.newAppointmentContract = appointmentData.actualContract
      this.newAppointmentNotes = appointmentData.newAppointmentNotes
      this.newAppointmentIsRelatedToActivationJourney = appointmentData.isRelatedToActivationJourney
      this.showConfirmOrderItemsModal = false
      this.handleCreateAppointment(this.selectedPatient, this.selectedSlot)
    },
    isExtendedSlot(slotId) {
      if (isEmpty(this.appointments)) return false

      const appointmentSlot = this.appointments.find(
        appointment => appointment.slots.find(slot => slot === slotId),
      )

      if (isNil(appointmentSlot)) return false

      return appointmentSlot.isExtended
    },
  },
}
</script>

<style lang="stylus" scoped>
@import '../../style/_core/colors.styl'

.timeline--is-able
  display visible

.agenda-slot
  width 100%
  border-bottom 1px dashed #ccc
  background-color #fff !important

.agenda-slot--extended
  height 75px

.agenda-slot--block
  background-color #d4d4d4 !important
  cursor not-allowed

.agenda-slot__hightlight-message
  align-self center
  justify-content center
  position absolute
  width 100vw
  font-size 12px
  letter-spacing 0.4px
  text-transform uppercase
  padding 0 !important
  color $confirmed-color

.v-chip--disabled
  opacity 1

.agenda-slot__time
  min-width 5rem
  color #656565
  font-size 16px
  line-height 5

.agenda-slot__appointment
  background-color #fff
  width auto
  height 40px
  border solid 1px rgba(#000, 0.33) !important
  border-radius 20px 5px 5px 20px
  font-size 13.7px
  letter-spacing 1.3px
  color #656565
  text-transform uppercase
  z-index 1

.agenda-slot__appointment--extended
  @extends .agenda-slot__appointment
  height 100px
  top 35px

.appointment-icon .appointment-icon__duration-container
  border-radius 20px 0 0 20px
  display flex
  align-content center
  margin 0 12px 0 -12px
  width auto
  height 40px
  padding 4px 8px 4px 12px

.appointment-icon--extended .appointment-icon__duration-container
  @extends .appointment-icon .appointment-icon__duration-container
  height 100px

.appointment-icon .appointment-icon__duration-time
  margin-top 5px
  text-transform none

.appointment-icon--extended .appointment-icon__duration-time
  margin-top 35px
  text-transform none

.appointment-icon--attended .appointment-icon__duration-container
  background-color rgba($japanese-laurel, 0.40)

.appointment-icon--attended:focus
  border 1px solid $dusty-gray !important

.appointment-icon--scheduled .appointment-icon__duration-container
  background-color rgba($rio-grande, 0.40)

.appointment-icon--scheduled:focus
  border 1px solid $rio-grande !important

.appointment-icon--confirmed .appointment-icon__duration-container
  background-color rgba($international-klein-blue, 0.40)

.appointment-icon--confirmed:focus
  border 1px solid $international-klein-blue !important

.appointment-icon--missed .appointment-icon__duration-container
  background-color rgba($grenadier, 0.40)

.appointment-icon--missed:focus
  border 1px solid $grenadier !important

.appointment-icon--unsuccessfulContact .appointment-icon__duration-container
  background-color rgba($grenadier, 0.40)

.appointment-icon--unsuccessfulContact:focus
  border 1px solid $grenadier !important

.appointment-icon--received .appointment-icon__duration-container
  background-color rgba($japanese-laurel, 0.40)

.appointment-icon--received:focus
  border 1px solid $japanese-laurel !important

.appointment-icon--late .appointment-icon__duration-container
  background-color rgba($gold-drop, 0.40)

.appointment-icon--late:focus
  border 1px solid $gold-drop !important

.appointment__first-time
  border-radius 5px
  background-color rgba(#807B7A, 0.2)
  padding 3px
  margin 0 0 0 6px

.appointment__waiting-time
  border-radius 5px
  background-color rgba(#ff0, 0.6)
  padding 3px
  margin 0 0 0 6px

.agenda__timeline
  position relative
  left 10px
  border 1px solid #f00
  height 7px
  width 7px
  border-radius 100%
  background-color #db4437

.agenda__timeline .agenda__timeline-line:before
  content ''
  position absolute
  top 49%
  left 0px
  height 2px
  background-color rgba(#db4437, 0.6)
  width 100vw

.agenda-slot--reserved
  background-color #eee

.agenda-slot__restriction-warnings
  ::v-deep
    .v-input__slot::before
      border none !important

    .v-input__append-inner i
      color $rio-grande

    label.v-label--active
      position unset
      transform none

  span
    color $rio-grande

>>> .v-alert__icon.v-icon
  color #f2780c
  padding-top 18px
  font-size 1.8rem

</style>
