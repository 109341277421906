<template>
  <div>
    <component-title />
    <div
      v-if="isLoading"
      class="tw-mt-5 tw-text-center"
    >
      <circular-loader />
    </div>
    <edit-contract-container
      v-else
    />
  </div>
</template>

<script>
import CircularLoader from '@/components/UI/CircularLoader'
import ComponentTitle from '@/components/ComponentTitle'
import { mapActions, mapGetters } from 'vuex'
import EditContractContainer from './EditContractContainer'

export default {
  name: 'EditContractPage',
  components: {
    CircularLoader,
    ComponentTitle,
    EditContractContainer,
  },
  computed: {
    ...mapGetters({
      isLoading: 'billing/isLoadingContract',
    }),
  },
  async mounted() {
    const { contractId } = this.$route.params
    await this.getContract(contractId)
  },
  methods: {
    ...mapActions({
      getContract: 'billing/getContract',
    }),
  },
}
</script>
