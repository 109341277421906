<template>
  <div>
    <component-title />
    <div
      v-if="isLoadingContract"
      class="tw-text-center tw-mt-5"
    >
      <circular-loader />
    </div>
    <contract-item-container
      v-else
    />
  </div>
</template>

<script>
import ComponentTitle from '@/components/ComponentTitle'
import { mapActions, mapGetters } from 'vuex'
import CircularLoader from '@/components/UI/CircularLoader'
import ContractItemContainer from './ContractItemContainer'

export default {
  name: 'ContractItemPage',
  components: {
    CircularLoader,
    ComponentTitle,
    ContractItemContainer,
  },
  computed: {
    ...mapGetters({
      isLoadingContract: 'billing/isLoadingContract',
    }),
  },
  async mounted() {
    const { contractId } = this.$route.params
    await this.getContract(contractId)
    await this.listContractItem({ contractId })
  },
  methods: {
    ...mapActions({
      getContract: 'billing/getContract',
      listContractItem: 'billing/listContractItem',
    }),
  },
}
</script>
