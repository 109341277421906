import { reactive } from 'vue'
import axios from '@/lib/axios'
import {
  find,
  isNil,
  omit,
  propEq,
} from 'ramda'
import removeNilOrEmpty from '@/utils/removeNilOrEmpty'
import isNilOrEmpty from '@/utils/dataValidators'

export default class SmartAgendaStore {
  #state = reactive({
    availableSlotsList: [],
    daysList: [],
    loadingDaysList: false,
    loadingDoctorSlots: false,
    selectedSlot: {},
    selectedSpeciliaty: null,
    slotsFilters: {},
    appointmentLogs: {},
    isLoadingDaysList: false,
    isLoadingDoctorSlots: false,
  })

  get state() {
    return this.#state
  }

  listDays(params) {
    this.#state.loadingDaysList = true
    const queryParams = omit(['isRelatedToActivationJourney'], params)
    return new Promise((resolve, reject) => {
      axios.get('/slot/days', { params: queryParams })
        .then(({ data }) => {
          this.#state.daysList = data.days
          resolve(data)
        })
        .catch(reject)
        .finally(() => {
          this.#state.loadingDaysList = false
        })
    })
  }

  listAvailableSlots(params) {
    this.#state.loadingDoctorSlots = true
    const { patientId } = params
    return new Promise((resolve, reject) => {
      const queryParams = removeNilOrEmpty(omit(['isRelatedToActivationJourney'], {
        ...params,
        patientId,
      }))

      axios.get('/slot/available', { params: queryParams })
        .then(({ data }) => {
          this.#state.slotsFilters = params
          this.#state.availableSlotsList = data
          resolve(data)
        })
        .catch(reject)
        .finally(() => {
          this.#state.loadingDoctorSlots = false
        })
    })
  }

  verifyPostalActivation(patientId) {
    return new Promise((resolve, reject) => {
      axios.get(`/patient/${patientId}/verify_postal_activation`)
        .then(({ data }) => {
          resolve(data)
        })
        .catch(reject)
    })
  }

  setSelectedSpeciality(name) {
    this.#state.selectedSpeciliaty = name
  }

  setAppointmentLogs(data) {
    this.#state.appointmentLogs = data
  }

  journeyIsExtended(clinicId, journeys) {
    if (isNilOrEmpty(journeys)) return true

    const journey = find(propEq(clinicId, 'clinicId'), journeys)

    const journeyOfClinic = isNil(journey)
      ? find(propEq(null, 'clinicId'), journeys)
      : journey

    return propEq(2, 'doctorSlots', journeyOfClinic)
  }

  setSelectedSlot(data) {
    const { appointmentLogs } = this.#state

    if (
      appointmentLogs.isPatientOnActivationJourney
      && appointmentLogs.isFirstAppointmentAfterActivationJourney
      && appointmentLogs.isRelatedToActivationJourney
    ) {
      const shouldExtendedAppointment = this.journeyIsExtended(
        data.slot?.clinicId,
        appointmentLogs?.contract?.journeys,
      )
      this.#state.appointmentLogs = {
        ...appointmentLogs,
        isExtendedAppointment: shouldExtendedAppointment,
        isExtendedAppointmentNotByItems: shouldExtendedAppointment,
      }
    }

    this.#state.selectedSlot = data
  }

  resetSearchValues() {
    this.#state.availableSlotsList = null
    this.#state.daysList = []
    this.#state.selectedSlot = {}
    this.#state.selectedSpeciliaty = null
    this.#state.slotsFilters = {}
  }
}
