<template>
  <div>
    <div class="tw-w-full tw-bg-green-frostee tw-px-12 tw-py-6">
      <div class="tw-grid tw-gap-4 md:tw-grid-cols-4">
        <v-text-field
          v-model="filters.name"
          filled
          label="Buscar por nome"
          clearable
          hide-details
          no-data-text="Digite para buscar"
        />
        <v-text-field
          v-model="filters.tussCode"
          label="Buscar por TUSS"
          clearable
          append-icon
          filled
          hide-no-data
          hide-details
        />
        <v-select
          v-model="filters.type"
          label="Tipo do Item"
          :items="itemTypeValues"
          item-text="label"
          item-value="value"
          clearable
          append-icon
          filled
          hide-no-data
          hide-details
        />
        <v-btn
          class="tw-h-full tw-font-semibold"
          color="dark-green"
          dark
          block
          large
          @click="getItems"
        >
          <v-icon left>
            mdi-magnify
          </v-icon>
          Buscar
        </v-btn>
      </div>
    </div>

    <div class="tw-px-12 tw-my-6">
      <div
        v-if="isItemsEmpty"
        class="tw-tracking-tighter tw-text-6xl tw-font-extralight tw-text-[#00000061]"
      >
        Nenhum resultado encontrado.
      </div>

      <div
        v-else-if="isLoading"
        class="tw-flex tw-justify-center"
      >
        <circular-loader />
      </div>

      <template v-else>
        <div
          v-for="(item, index) in items"
          :key="index"
          class="
            tw-grid
            tw-grid-cols-12
            tw-items-start
            tw-border
            tw-border-solid
            tw-border-gray-thin
            tw-rounded-lg
            tw-mb-4
            tw-p-5
          "
          :class="{
            'list-item--disabled': Boolean(item.storedAt),
          }"
        >
          <div class="tw-col-span-10">
            <div class="tw-text-black tw-text-2xl tw-break-all">
              {{ item.name }}
            </div>
            <div class="tw-text-gray-amparo tw-font-base tw-leading-loose tw-tracking-wider">
              Tipo: {{ item.type | formatItemType }}
            </div>
            <div class="tw-text-gray-amparo tw-font-base tw-leading-loose tw-tracking-wider">
              TUSS: {{ item.tussCode }}
            </div>
          </div>
          <div class="tw-col-span-2 tw-flex tw-justify-end tw-gap-x-2">
            <div v-if="item.storedAt">
              <v-tooltip top>
                <template #activator="{ on }">
                  <v-btn
                    class="tw-font-semibold"
                    text
                    icon
                    v-on="on"
                    @click="handleRestoreItem(item.id)"
                  >
                    <v-icon>mdi-archive-arrow-up</v-icon>
                  </v-btn>
                </template>
                <span>Restaurar</span>
              </v-tooltip>
            </div>
            <div
              v-else
              class="tw-flex tw-gap-x-2"
            >
              <v-tooltip top>
                <template #activator="{ on }">
                  <v-btn
                    class="tw-font-semibold"
                    text
                    icon
                    v-on="on"
                    @click="openListMnemonicsDialog(item)"
                  >
                    <v-icon>mdi-eye</v-icon>
                  </v-btn>
                </template>
                <span>Visualizar</span>
              </v-tooltip>
              <v-tooltip top>
                <template #activator="{ on }">
                  <v-btn
                    class="tw-font-semibold"
                    text
                    icon
                    v-on="on"
                    @click="openEditDialog(item)"
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Editar</span>
              </v-tooltip>
              <v-tooltip top>
                <template #activator="{ on }">
                  <v-btn
                    class="tw-font-semibold"
                    text
                    icon
                    v-on="on"
                    @click="openArchiveDialog(item)"
                  >
                    <v-icon>mdi-archive-arrow-down</v-icon>
                  </v-btn>
                </template>
                <span>Arquivar</span>
              </v-tooltip>
            </div>
          </div>
        </div>
      </template>

      <item-list-container-pagination
        v-if="!isLoading && !isItemsEmpty"
        @changePage="handleChangePage"
      />

      <create-item
        v-if="showEditItemDialog"
        :item-to-edit="editedItem"
        @closeDialog="closeEditDialog"
      />

      <item-mnemonic-list
        v-if="showItemMnemonicListDialog"
        :item="editedItem"
        @closeDialog="closeListMnemonicsDialog"
      />

      <archive-item-dialog
        v-if="showArchiveItemDialog"
        :item-id="editedItem.id"
        @closeDialog="closeArchiveDialog($event)"
      />
    </div>
  </div>
</template>

<script>
import {
  isEmpty,
  values,
} from 'ramda'
import { mapGetters, mapActions } from 'vuex'
import { debounce } from 'lodash'
import { ITEM } from 'amparo-enums'
import ItemListContainerPagination from './ItemListContainerPagination'

export default {
  name: 'ItemListContainer',
  components: {
    ItemListContainerPagination,
    CreateItem: () => import('./CreateItem'),
    ItemMnemonicList: () => import('./ItemMnemonicList'),
    ArchiveItemDialog: () => import('./ArchiveItemDialog'),
    CircularLoader: () => import('@/components/UI/CircularLoader'),
  },
  data() {
    return {
      editedItem: {},
      showEditItemDialog: false,
      showItemMnemonicListDialog: false,
      showArchiveItemDialog: false,
      filters: {},
      itemTypeValues: values(ITEM.types),
    }
  },
  computed: {
    ...mapGetters({
      items: 'billing/items',
      itemPage: 'billing/itemPage',
      isLoading: 'billing/isLoadingItem',
    }),
    isItemsEmpty() {
      return isEmpty(this.items)
    },
  },
  watch: {
    filters: {
      immediate: true,
      deep: true,
      handler: debounce(function searchItems() {
        this.getItems()
      }, 500),
    },
  },
  mounted() {
    this.getItems()
  },
  methods: {
    ...mapActions({
      listClinic: 'clinic/listClinic',
      listItem: 'billing/listItem',
      restoreItem: 'billing/restoreItem',
      setSnackbar: 'snackbar/setSnackbar',
    }),
    async getItems() {
      await this.listItem({
        ...this.filters,
        page: this.itemPage,
      })
    },
    openEditDialog(itemData) {
      this.editedItem = itemData
      this.showEditItemDialog = true
    },
    closeEditDialog() {
      this.editedItem = {}
      this.showEditItemDialog = false
      this.getItems()
    },
    openListMnemonicsDialog(itemData) {
      this.editedItem = itemData
      this.showItemMnemonicListDialog = true
    },
    closeListMnemonicsDialog() {
      this.editedItem = {}
      this.showItemMnemonicListDialog = false
    },
    openArchiveDialog(itemData) {
      this.editedItem = itemData
      this.showArchiveItemDialog = true
    },
    closeArchiveDialog(hasDeleteItem) {
      this.editedItem = {}
      this.showArchiveItemDialog = false
      if (hasDeleteItem) this.getItems()
    },
    handleChangePage() {
      this.getItems()
    },
    async handleRestoreItem(itemId) {
      try {
        await this.restoreItem(itemId)
        this.setSnackbar({
          status: 'success',
          message: 'Item restaurado com sucesso',
        })
        this.getItems()
      } catch (err) {
        this.setSnackbar({
          status: 'error',
          message: 'Erro ao restaurar item',
        })
      }
    },
  },
}
</script>

<style lang='stylus' scoped>
@import '../../style/_core/colors.styl'

.list-item--disabled > div > div
  color #32333826 !important

.v-btn.v-btn--text
  border solid 1px thin-gray
</style>
