<template>
  <div>
    <virtual-reception-filters @filterUpdate="updateParams" />
    <virtual-reception-list
      :attendance-list="attendancesList"
      @updateList="listAttendancesWithFilters"
    />
    <virtual-reception-pagination
      :page="filters.page"
      @changePage="setPage"
    />
  </div>
</template>

<script>
import {
  not,
  equals,
  isNil,
} from 'ramda'
import { mapGetters, mapActions } from 'vuex'
import VirtualReceptionFilters from './VirtualReceptionFilters'
import VirtualReceptionList from './VirtualReceptionList'

export default {
  components: {
    VirtualReceptionFilters,
    VirtualReceptionList,
    VirtualReceptionPagination: () => import('./VirtualReceptionPagination'),
  },
  data() {
    return {
      tab: null,
      filters: {
        page: 1,
        isClosed: false,
      },
      interval: null,
    }
  },
  computed: {
    ...mapGetters({
      attendancesList: 'virtualReception/attendancesList',
      showVirtualReceptionDialog: 'virtualReception/showVirtualReceptionDialog',
    }),
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        this.listAttendancesWithFilters()
      },
    },
  },
  created() {
    this.initIntervalTime()
  },
  activated() {
    this.initIntervalTime()
  },
  deactivated() {
    this.destroyInterval()
  },
  destroyed() {
    this.destroyInterval()
  },
  methods: {
    ...mapActions({
      getAttendancesList: 'virtualReception/getAttendancesList',
    }),
    setPage(page) {
      this.filters.page = page
    },
    destroyInterval() {
      clearInterval(this.interval)
      this.interval = null
    },
    initIntervalTime() {
      const INTERVAL_TIME = 300000
      if (not(isNil(this.interval))) return
      this.interval = setInterval(() => {
        if (not(equals(this.$route.path, '/agenda'))) {
          this.destroyInterval()
          return
        }
        if (not(this.showVirtualReceptionDialog)) {
          this.filters.isClosed = false
        }
        this.listAttendancesWithFilters()
      }, INTERVAL_TIME)
    },
    listAttendancesWithFilters() {
      this.getAttendancesList(this.filters)
    },
    updateParams(filters) {
      this.filters = filters
    },
  },
}
</script>
