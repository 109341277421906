var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('input',{ref:"fileInput",staticClass:"d-none",attrs:{"type":"file","multiple":_vm.acceptMultiple},on:{"change":function($event){return _vm.addFiles($event.target.files)}}}),_c('div',{class:{
      'dropzone--dashed': _vm.dashed,
      'dropzone__container': true,
      'tw-flex': true,
      'tw-items-center': true,
    },style:({
      'border-color': _vm.color,
      'border-radius': _vm.borderRadius,
    }),on:{"dragover":function($event){$event.preventDefault();},"drop":function($event){$event.preventDefault();return _vm.addFiles($event.dataTransfer.files)},"click":function($event){return _vm.selectFiles()}}},[(!_vm.files.length)?_c('div',{staticClass:"tw-w-full"},[(_vm.icon)?_c('v-icon',{attrs:{"size":"50px","color":_vm.color}},[_vm._v(" "+_vm._s(_vm.icon)+" ")]):_vm._e(),_c('p',{staticClass:"tw-text-center tw-mb-0",style:({ 'color': _vm.color })},[_vm._v(" "+_vm._s(_vm.defaultMessage)+" ")])],1):_c('div',{staticClass:"tw-grid tw-grid-cols-3 tw-gap-4 tw-m-4"},_vm._l((_vm.files),function(file,index){return _c('div',{key:index,staticClass:"tw-pa-4",attrs:{"cols":_vm.fileColSize},on:{"click":function($event){$event.stopPropagation();}}},[_c('v-card',{staticClass:"tw-p-4 tw-cursor-default",attrs:{"color":"#E9F0FE"}},[_c('div',{staticClass:"tw-flex tw-justify-end tw--mt-2 tw--mr-2"},[_c('v-btn',{attrs:{"icon":""},on:{"click":[function($event){$event.stopPropagation();},function($event){return _vm.removeFile(file)}]}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1),_c('div',{staticClass:"tw-mb-2"},[(file.src)?_c('v-img',{attrs:{"height":"80","contain":"","src":file.src}}):_c('v-icon',{attrs:{"color":"#1E77CC","size":"80"}},[_vm._v(" mdi-file-document ")])],1),_c('div',{staticClass:"tw-text-base tw-text-gray"},[_c('p',{staticClass:"tw-mb-0 tw-truncate tw-text-center"},[_vm._v(" "+_vm._s(file.name)+" ")]),(_vm.selectInputData)?_c('dropzone-select-input',{ref:"dropzoneSelectInput",refInFor:true,staticClass:"tw-pt-4",attrs:{"file-id":file.id,"data":_vm.selectInputData},on:{"update:inputValue":_vm.updateFile}}):_vm._e()],1)])],1)}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }