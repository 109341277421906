<template>
  <v-dialog
    :value="true"
    fullscreen
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar
        dark
        color="primary"
      >
        <v-toolbar-title>
          {{ hasItemToEdit ? 'Editar' : 'Novo' }} Item
        </v-toolbar-title>
        <v-spacer />
        <v-btn
          icon
          dark
          @click="emitCloseDialog"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="tw-grid tw-grid-cols-12 tw-gap-6 tw-px-7 tw-py-6">
        <v-text-field
          v-model="item.name"
          class="tw-col-span-full"
          label="Nome do item"
          clearable
          filled
          hide-details
          :error="$v.item.name.$error"
          @blur="$v.item.name.$touch()"
        />
        <v-text-field
          v-model="item.tussCode"
          class="tw-col-span-4"
          label="TUSS"
          clearable
          filled
          hide-details
          :error="$v.item.tussCode.$error"
          @blur="$v.item.tussCode.$touch()"
        />
        <v-text-field
          :value="itemToEdit.amparoCode"
          class="tw-col-span-4"
          label="Código Amparo"
          clearable
          filled
          hide-details
          disabled
        />
        <v-autocomplete
          v-model="item.type"
          class="tw-col-span-4"
          label="Tipo"
          :items="itemTypeValues"
          item-text="label"
          item-value="value"
          clearable
          filled
          hide-details
          :error="$v.item.type.$error"
          @blur="$v.item.type.$touch()"
        />
        <v-autocomplete
          v-if="isExamItem"
          v-model="item.exams"
          class="tw-col-span-6"
          label="Exame"
          :items="examsList"
          :search-input.sync="searchExam"
          :loading="isLoadingExams"
          item-text="name"
          return-object
          :no-data-text="noDataExamListMessage"
          clearable
          no-filter
          multiple
          chips
          deletable-chips
          filled
          hide-details
        >
          <template #item="{ item }">
            <div>
              {{ item.name }}
              <span
                v-if="item.code"
              >
                - {{ item.code }}
              </span>
            </div>
          </template>
          <template #append>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-icon
                  class="tw-mt-1"
                  v-on="on"
                >
                  mdi-help-circle-outline
                </v-icon>
              </template>
              <span>
                Os exames relacionados ao item impactarão na ordem de serviço na sessão de
                "Prescrições" do paciente. Os mnemônicos que aparecerão para vincular a
                prescrição dependerão da devida configuração deste campo.
              </span>
            </v-tooltip>
          </template>
        </v-autocomplete>
        <v-autocomplete
          v-model="item.specialityIds"
          class="tw-col-span-6"
          :items="sortedSpecialities"
          label="Especialidades"
          item-text="name"
          item-value="id"
          multiple
          chips
          deletable-chips
          filled
          hide-details
        >
          <template #selection="{ index }">
            <div
              v-if="index === 0"
            >
              <span
                v-if="shouldShowAllSelected(
                  item.specialityIds, specialities
                )"
              >
                Todas
              </span>
              <span v-else>
                {{ item.specialityIds.length }} especialidade(s) selecionada(s)
              </span>
            </div>
          </template>
          <template #prepend-item>
            <div
              class="tw-flex tw-flex-row tw-gap-4 tw-ml-4 tw-mb-1"
            >
              <v-btn
                depressed
                small
                color="primary"
                @click="setItemSpecialityIds(specialities)"
              >
                Todas
              </v-btn>
              <v-btn
                depressed
                small
                @click="setItemSpecialityIds([])"
              >
                Nenhuma
              </v-btn>
            </div>
          </template>
          <template #append>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-icon
                  class="mt-1"
                  v-on="on"
                >
                  mdi-help-circle-outline
                </v-icon>
              </template>
              <span>
                Novas agendas das especialidades selecionadas virão com o item adicionado
                por padrão na tabela de cobertura.
              </span>
            </v-tooltip>
          </template>
        </v-autocomplete>
        <div
          v-if="isExamItem"
          class="tw-col-span-full tw-grid tw-grid-cols-subgrid tw-gap-6 tw-mb-2"
        >
          <div class="tw-col-span-12 tw-text-base tw-text-black tw-font-bold">
            Mnemonicos
          </div>
          <v-text-field
            v-model="itemMnemonic.mnemonic"
            class="tw-col-span-4"
            label="Mnemonico"
            clearable
            filled
            hide-details
          />
          <v-text-field
            v-model="itemMnemonic.material"
            class="tw-col-span-4"
            label="Material"
            clearable
            filled
            hide-details
          />
          <v-text-field
            v-model="itemMnemonic.location"
            class="tw-col-span-4"
            label="Localização"
            clearable
            filled
            hide-details
          />
          <v-text-field
            v-model="itemMnemonic.observation"
            class="tw-col-span-10"
            label="Observação"
            clearable
            filled
            hide-details
          />
          <v-btn
            class="tw-col-span-2 tw-h-full tw-font-semibold tw-text-white"
            color="dark-green"
            block
            large
            :disabled="!isItemMnemonicFullyFilled"
            @click="addNewItemMnemonic"
          >
            Adicionar
          </v-btn>
          <v-data-table
            v-if="itemMnemonics?.length"
            class="tw-col-span-full tw-shadow-[0_3px_1px_0px_#E0E0E0E5]"
            :items="itemMnemonics"
            :headers="mnemonicsTableHeaders"
            hide-default-footer
          >
            <template v-slot:item="{ item }">
              <tr>
                <td class="tw-text-sm tw-text-gray-dark">
                  {{ item.mnemonic }}
                </td>
                <td class="tw-text-sm tw-text-gray-dark">
                  {{ item.material }}
                </td>
                <td class="tw-text-sm tw-text-gray-dark ">
                  {{ item.location }}
                </td>
                <td class="tw-text-sm tw-text-gray-dark">
                  {{ item.observation }}
                </td>
                <td class="tw-text-sm tw-text-gray-dark">
                  <v-btn
                    icon
                    text
                    small
                    color="error"
                    @click="removeItemMnemonic(item)"
                  >
                    <v-icon>
                      mdi-delete
                    </v-icon>
                  </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>
        </div>
        <div class="tw-col-span-full">
          <v-tooltip right>
            <template #activator="{ on }">
              <span
                v-on="on"
              >
                <span class="tw-text-base tw-text-black tw-font-bold">
                  Gerenciamento de Cobertura de Agendas
                </span>
              </span>
            </template>
            <span>
              Está sessão permite que agendas sejam editadas em massa, possibilitando
              adicionar ou remover o item da tabela de cobertura das agendas inclusas nos
              filtros selecionados.
            </span>
          </v-tooltip>
        </div>
        <create-agenda-item-bulk-update
          class="tw-col-span-full"
          :item-id="itemToEdit?.id"
        />
        <v-btn
          class="btn btn-primary tw-col-span-2 tw-col-start-11 tw-self-end"
          large
          :loading="isLoading"
          @click="handleSaveItem"
        >
          Salvar Item
        </v-btn>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  required,
  minLength,
} from 'vuelidate/lib/validators'
import {
  assoc,
  equals,
  findIndex,
  isEmpty,
  isNil,
  not,
  pick,
  pluck,
  remove,
  values,
  __,
  includes,
  all,
  length,
  any,
  propEq,
  partition,
  defaultTo,
  uniq,
  map,
} from 'ramda'
import { ITEM } from 'amparo-enums'
import { mapActions, mapGetters, mapState } from 'vuex'
import debounce from 'lodash/debounce'

export default {
  name: 'CreateItem',
  components: {
    CreateAgendaItemBulkUpdate: () => import('./CreateAgendaItemBulkUpdate'),
  },
  props: {
    itemToEdit: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  data() {
    return {
      item: {
        name: '',
        tussCode: '',
        type: '',
        specialityIds: [],
        exams: null,
      },
      itemMnemonic: {
        mnemonic: null,
        material: null,
        location: null,
        observation: null,
      },
      itemMnemonics: [],
      itemMnemonicsToCreate: [],
      itemMnemonicsToDelete: [],
      reminderCommunications: [],
      showDialog: true,
      itemTypeValues: values(ITEM.types),
      searchExam: null,
      noDataExamListMessage: 'Digite para buscar',
      examsList: [],
      mnemonicsTableHeaders: [
        {
          text: 'Mnemonico',
          width: '24%',
          sortable: false,
          class: 'tw-text-base tw-font-medium tw-text-gray',
        },
        {
          text: 'Material',
          width: '24%',
          sortable: false,
          class: 'tw-text-base tw-font-medium tw-text-gray',
        },
        {
          text: 'Localização',
          width: '24%',
          sortable: false,
          class: 'tw-text-base tw-font-medium tw-text-gray',
        },
        {
          text: 'Observação',
          width: '24%',
          sortable: false,
          class: 'tw-text-base tw-font-medium tw-text-gray',
        },
        {
          text: '',
          width: '4%',
          sortable: false,
          class: 'tw-text-base tw-font-medium tw-text-gray',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      specialities: 'speciality/specialities',
      isLoading: 'billing/isLoadingItem',
      isLoadingExams: 'exam/loading',
    }),
    ...mapState({
      user: ({ authentication }) => authentication.user,
    }),
    isItemMnemonicFullyFilled() {
      return this.itemMnemonic.mnemonic
        && this.itemMnemonic.material
        && this.itemMnemonic.location
    },
    isExamItem() {
      return equals(ITEM.types.labExam.value, this.item.type)
    },
    hasItemToEdit() {
      return not(isEmpty(this.itemToEdit))
    },
    sortedSpecialities() {
      const [selectedSpecialities, notSelectedSpecialities] = partition(
        ({ id }) => includes(id, defaultTo([], this.item.specialityIds)),
        this.specialities,
      )

      return [
        ...selectedSpecialities,
        ...notSelectedSpecialities,
      ]
    },
  },
  watch: {
    searchExam: debounce(async function search(searchExamValue) {
      const defaultText = 'Digite para buscar'
      this.noDataExamListMessage = defaultText

      if (
        !this.searchExam
        || !this.searchExam.length >= 1
      ) {
        this.noDataExamListMessage = defaultText
        return
      }

      await this.setExamsList(searchExamValue)

      if (this.examsList.length === 0) {
        this.noDataExamListMessage = 'Nenhum exame encontrado'
      }
    }, 1000),
    'item.type': function handleUpdateItemType(newType) {
      if (!equals(newType, ITEM.types.labExam.value)) {
        this.item.exams = []
      }
    },
  },
  validations() {
    const itemValidations = {
      item: {
        name: {
          required,
          minLength: minLength(2),
        },
        tussCode: {
          required,
        },
        type: {
          required,
        },
      },
    }

    return { ...itemValidations }
  },
  mounted() {
    if (this.hasItemToEdit) {
      this.fillFieldsToEdit()
    }
    this.listSpeciality({ returnAll: true })
  },
  methods: {
    ...mapActions({
      createItem: 'billing/createItem',
      createItemMnemonic: 'billing/createItemMnemonic',
      updateItem: 'billing/updateItem',
      deleteItemMnemonic: 'billing/removeItemMnemonic',
      listSpeciality: 'speciality/listSpeciality',
      listExam: 'exam/listExam',
      setSnackbar: 'snackbar/setSnackbar',
    }),
    async setExamsList(search) {
      const examsList = await this.listExam({
        search,
      })

      this.examsList = uniq([
        ...examsList,
        ...this.examsList,
      ])
    },
    shouldShowAllSelected(selectedItems, selectedItemsList) {
      return equals(length(selectedItems), length(selectedItemsList))
    },
    emitCloseDialog() {
      this.$emit('closeDialog')
    },
    async setItemExams() {
      if (!isEmpty(this.itemToEdit.itemExams)) {
        const examsData = map(
          itemExam => itemExam.exam || itemExam.examGroup,
          this.itemToEdit.itemExams,
        )
        this.examsList = examsData
        this.item.exams = examsData
      }
    },
    async fillFieldsToEdit() {
      this.item = pick(
        [
          'name',
          'tussCode',
          'type',
        ],
        this.itemToEdit,
      )
      await this.setItemExams()
      this.itemMnemonics = this.itemToEdit.itemMnemonics
      this.isLoadingItemSpecialities = true
      this.item.specialityIds = any(propEq(null, 'specialityId'), this.itemToEdit.specialityItems)
        ? pluck('id', this.specialities)
        : pluck('specialityId', this.itemToEdit.specialityItems)
      this.isLoadingItemSpecialities = false
    },
    addNewItemMnemonic() {
      this.itemMnemonicsToCreate.push(this.itemMnemonic)
      this.itemMnemonics.push(this.itemMnemonic)
      this.itemMnemonic = {
        mnemonic: null,
        material: null,
        location: null,
        observation: null,
      }
    },
    removeItemMnemonic(item) {
      const indexOnItemMnemonics = findIndex(equals(item), this.itemMnemonics)
      this.itemMnemonics = remove(indexOnItemMnemonics, 1, this.itemMnemonics)

      if (item.id) {
        this.itemMnemonicsToDelete.push(item.id)
      } else {
        const indexOnItemMnemonicsToCreate = findIndex(
          equals(item),
          this.itemMnemonicsToCreate,
        )

        this.itemMnemonicsToCreate = remove(
          indexOnItemMnemonicsToCreate,
          1,
          this.itemMnemonicsToCreate,
        )
      }
    },
    async handleItemMnemonics(itemId) {
      if (this.itemMnemonicsToCreate.length) {
        await this.createItemMnemonic({
          itemId,
          itemMnemonics: this.itemMnemonicsToCreate,
        })
      }

      if (this.itemMnemonicsToDelete.length) {
        await this.deleteItemMnemonic({
          ids: this.itemMnemonicsToDelete,
        })
      }
    },
    arraysHaveTheSameValues(array1, array2) {
      return all(includes(__, array2), array1)
        && all(includes(__, array1), array2)
    },
    getSpecialityFieldsFormatted() {
      const specialityIdsHasChanged = !isEmpty(this.itemToEdit)
        ? !this.arraysHaveTheSameValues(
          this.item.specialityIds,
          pluck('specialityId', this.itemToEdit.specialityItems),
        )
        : true
      const selectedAllSpecialities = this.shouldShowAllSelected(
        this.item.specialityIds,
        this.specialities,
      )

      return {
        specialityIds: specialityIdsHasChanged && !selectedAllSpecialities
          ? this.item.specialityIds
          : null,
        allSpecialities: selectedAllSpecialities,
      }
    },
    async setItemSpecialityIds(selectedValues) {
      this.item = assoc('specialityIds', pluck('id', selectedValues), this.item)
    },
    formatItem() {
      const item = {
        ...pick(
          [
            'name',
            'tussCode',
            'type',
          ],
          this.item,
        ),
        ...this.getSpecialityFieldsFormatted(),
      }

      const itemExamIds = this.item.exams

      const examIds = isNil(itemExamIds) || isEmpty(itemExamIds)
        ? []
        : pluck('id', itemExamIds)

      return {
        ...item,
        examIds,
      }
    },
    async handleSaveItem() {
      const feedback = {}
      this.$v.$touch()
      if (this.$v.$error) {
        feedback.status = 'error'
        feedback.message = 'Verifique os campos em vermelho'
        this.setSnackbar(feedback)
        return
      }
      try {
        if (this.hasItemToEdit) {
          await this.updateItem({
            id: this.itemToEdit.id,
            attributes: this.formatItem(),
          })
          if (this.isExamItem) {
            await this.handleItemMnemonics(this.itemToEdit.id)
          }
        } else {
          const item = await this.createItem(this.formatItem())
          if (this.isExamItem) {
            await this.handleItemMnemonics(item.id)
          }
        }
        this.emitCloseDialog()
        feedback.status = 'success'
        feedback.message = 'Item salvo com sucesso!'
      } catch (error) {
        feedback.status = 'error'
        feedback.message = 'Erro ao salvar item'
      }
      this.setSnackbar(feedback)
    },
  },
}
</script>

<style lang="stylus" scoped>
@import '../../style/_core/colors.styl'

.v-btn.v-btn--text
  border solid 1px thin-gray
</style>
