<template>
  <div class="tw-mx-8">
    <div class="tw-flex tw-justify-end">
      <emr-encounter-list-controls
        v-if="encounters.length !== 0"
        :pagination="pagination"
      />
    </div>

    <v-list>
      <v-list-item
        v-for="emr in encounters"
        :key="emr.id"
        class="tw-px-0 tw-py-2"
        small
      >
        <v-list-item-content class="tw-overflow-visible">
          <emr-encounter-card
            :emr="emr"
            :should-show-section-name="shouldShowSectionName(emr)"
          />
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        v-if="encounters.length === 0"
        class="tw-p-0"
      >
        <span class="components__default-message">
          {{ encountersEmptyMessage }}
        </span>
      </v-list-item>

      <emr-history-pagination
        v-else
        :page="pagination.page"
        class="mb-6"
        @changePage="setPage"
      />
    </v-list>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  any,
  equals,
} from 'ramda'
import EmrEncounterListControls from './EmrEncounterListControls'
import EmrEncounterCard from './EmrEncounterCard'
import EmrHistoryPagination from './EmrHistoryPagination'


export default {
  name: 'EmrEncounterList',
  components: {
    EmrEncounterListControls,
    EmrEncounterCard,
    EmrHistoryPagination,
  },
  props: {
    encounters: {
      type: Array,
      default: () => [],
    },
    pagination: {
      type: Object,
      default: () => {},
    },
    encountersEmptyMessage: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      reminderEncounterId: 'emr/reminderEncounterId',
    }),
  },
  methods: {
    setPage(page) {
      this.$emit('changePage', page)
    },
    shouldShowSectionName(emr) {
      const showableSectionFormNames = ['SOAP']

      return any(equals(emr?.encounterFormName), showableSectionFormNames)
    },
  },
}
</script>
