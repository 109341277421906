<template>
  <div>
    <component-title class="tw-bg-green-deep">
      <template #actions>
        <div class="tw-flex">
          <emr-print-button
            v-if="emrData"
            class="tw-mr-2"
            :emr-data="emrData"
            :button-color="'white'"
          />
          <emr-cancel-encounter class="tw-mr-2" />
          <emr-end-encounter />
        </div>
      </template>
    </component-title>

    <div class="tw-grid tw-grid-cols-12">
      <div class="tw-col-span-9">
        <emr-form-builder
          :should-show-all-warnings="shouldShowAllWarnings"
        />
      </div>
      <div class="tw-col-span-3">
        <emr-resume :is-inside-encounter="true" />
      </div>
    </div>
  </div>
</template>

<script>
import { isEmpty } from 'ramda'
import { mapActions, mapGetters } from 'vuex'
import ComponentTitle from '@/components/ComponentTitle'
import EmrResume from '@/components/Common/EmrResume'
import EmrCancelEncounter from '@/pages/Emr/EmrCancelEncounter'
import EmrEndEncounter from '@/pages/Emr/EmrEndEncounter'
import EmrPrintButton from '@/pages/Emr/EmrPrintButton'
import EmrFormBuilder from './EmrFormBuilder'

export default {
  components: {
    ComponentTitle,
    EmrResume,
    EmrFormBuilder,
    EmrCancelEncounter,
    EmrEndEncounter,
    EmrPrintButton,
  },
  data() {
    return {
      shouldShowAllWarnings: false,
    }
  },
  computed: {
    ...mapGetters({
      encounter: 'emr/encounter',
    }),
    emrData() {
      return isEmpty(this.encounter) ? null : this.encounter
    },
  },
  provide() {
    return {
      providerTurnOnAllQuestionsWarnings: this.showAllWarnings,
    }
  },
  async activated() {
    await this.setOpenedEncountersBroadcastChannel(() => {
      const { id: patientId } = this.$route.params
      this.$router.push(`/emr/${patientId}`)
    })
  },
  methods: {
    ...mapActions({
      setOpenedEncountersBroadcastChannel: 'emr/setOpenedEncountersBroadcastChannel',
    }),
    showAllWarnings() {
      this.shouldShowAllWarnings = !this.shouldShowAllWarnings
    },
  },
}
</script>
