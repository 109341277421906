<template>
  <div
    v-if="attendanceList.length > 0"
    class="tw-grid tw-grid-cols-1 tw-mx-12 tw-my-6 tw-gap-8"
  >
    <virtual-reception-attendance
      v-for="attendance of attendanceList"
      :key="attendance.id"
      :attendance="attendance"
      :is-closed="isClosed"
      @updateList="updateList"
    />
  </div>
  <div
    v-else
    class="components__default-message tw-ml-12"
  >
    Não há atendimentos.
  </div>
</template>

<script>
import VirtualReceptionAttendance from './VirtualReceptionAttendance'

export default {
  components: {
    VirtualReceptionAttendance,
  },
  props: {
    attendanceList: {
      type: Array,
      required: false,
      default: () => [],
    },
    isClosed: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    updateList() {
      this.$emit('updateList')
    },
  },
}
</script>
