<template>
  <div
    v-if="!isLoadingOrderList"
    class="tw-p-4"
  >
    <div class="tw-flex tw-justify-between tw-pb-3">
      <v-btn
        color="primary"
        :disabled="isSelectedOrdersEmpty"
        @click="toggleMultipleOrdersDialog"
      >
        Editar Ordens
        <span v-if="!isSelectedOrdersEmpty">
          ({{ selectedOrders.length }})
        </span>
      </v-btn>
      <v-btn
        text
        color="primary"
        class="tw-capitalize"
        :loading="isDownloadingXslsx"
        @click="downloadXlsx"
      >
        <v-icon
          right
          plain
          class="tw-mr-1"
          size="20"
        >
          mdi-download
        </v-icon>
        <span class="tw-text-base tw-font-semibold">
          Download Planilha
        </span>
      </v-btn>
    </div>
    <v-data-table
      v-model="selectedOrders"
      class="v-table--header"
      :headers="headers"
      :items="formattedOrdersList"
      :options.sync="pagination"
      :server-items-length="ordersList.count"
      :footer-props="{
        'items-per-page-options': [30],
        'items-per-page-text': '',
      }"
      item-key="id"
      show-select
      no-data-text="Sem itens para exibir, clique no botão acima para começar a criar"
      @update:page="changePage"
    >
      <template #item="order">
        <tr>
          <td>
            <v-checkbox
              class="tw-pt-2"
              :input-value="order.isSelected"
              @change="order.select($event)"
            />
          </td>
          <td>
            <v-btn
              icon
              :disabled="isLoadingOrderEdit && isLoadingOrderEditId !== order.item.id"
              :loading="isLoadingOrderEdit && isLoadingOrderEditId === order.item.id"
              @click="openDetailsDialog(order.item.id)"
            >
              <v-btn
                icon
                color="black"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </v-btn>
          </td>
          <td class="tw-text-xs tw-font-medium tw-tracking-wide">
            {{ order.item.number }}
          </td>
          <td class="tw-text-xs tw-font-medium tw-tracking-wide">
            {{ order.item.orderStatusLabel }}
          </td>
          <td class="tw-text-xs tw-font-medium tw-tracking-wide">
            {{ order.item.bill }}
          </td>
          <td class="tw-text-xs tw-font-medium tw-tracking-wide">
            {{ order.item.hmoName }}
          </td>
          <td class="tw-text-xs tw-font-medium tw-tracking-wide">
            {{ order.item.healthProductName }}
          </td>
          <td class="tw-text-xs tw-font-medium tw-tracking-wide">
            {{ order.item.clinic }}
          </td>
          <td>
            <div class="tw-text-xs tw-font-medium tw-tracking-wide">
              <a
                class="tw-font-medium tw-tracking-wide"
                @click="openPatientProfile(order.item.patientId)"
              >
                {{ order.item.patientName }}
              </a>
            </div>
            <div class="tw-text-xs tw-font-medium tw-tracking-wide">
              {{ order.item.patientCPF }}
            </div>
          </td>
          <td class="tw-text-xs tw-font-medium tw-tracking-wide">
            {{ order.item.date }}
          </td>
          <td class="tw-text-xs tw-font-medium tw-tracking-wide">
            {{ order.item.attendanceStatusLabel }}
          </td>
          <td class="tw-text-xs tw-font-medium tw-tracking-wide">
            {{ order.item.totalPrice | formatMoney }}
          </td>
          <td class="tw-text-xs tw-font-medium tw-tracking-wide">
            {{ order.item.hmoGuideNumber }}
          </td>
          <td class="tw-text-xs tw-font-medium tw-tracking-wide">
            {{ order.item.authorizationCode }}
          </td>
          <td class="tw-text-xs tw-font-medium tw-tracking-wide">
            {{ order.item.professional }}
          </td>
          <td class="tw-text-xs tw-font-medium tw-tracking-wide">
            {{ order.item.speciality }}
          </td>
          <td class="tw-text-xs tw-font-medium tw-tracking-wide">
            {{ order.item.parentReturnalOrder }}
          </td>
          <td class="tw-text-xs tw-font-medium tw-tracking-wide">
            {{ order.item.paymentMethod }}
          </td>
          <td class="tw-text-xs tw-font-medium tw-tracking-wide">
            {{ order.item.isInvoiceEmitted }}
          </td>
          <td class="tw-text-xs tw-font-medium tw-tracking-wide">
            <div v-if="hasAccessToContractEdit">
              <div
                v-for="cid in order.item.encounterCids"
                :key="cid"
              >
                {{ cid }}
                <br>
              </div>
            </div>
          </td>
          <v-tooltip bottom>
            <template #activator="{ on }">
              <td
                class="tw-text-xs tw-font-medium tw-tracking-wide"
                v-on="on"
              >
                {{ order.item.eligibilityStatus }}
              </td>
            </template>
            <span class="tw-whitespace-pre-line">
              {{ getEligibilityMessage(order.item.eligibility) }}
            </span>
          </v-tooltip>
          <td class="tw-text-xs tw-font-medium tw-tracking-wide">
            {{ order.item.updatedAt }}
          </td>
        </tr>
      </template>
    </v-data-table>

    <create-appointment
      v-if="createAppointmentDialog"
      origin="agenda"
      title="Editar Agendamento"
      :patient="order?.patient"
      :clinic-id="order?.clinic?.id"
      :items="order?.orderItems"
      :edit-appointment-data="orderAppointment"
      :selected-slot="selectedSlot"
      :slots="slots"
      :contract="actualContract"
      :show-dialog="createAppointmentDialog"
      @close="createAppointmentDialog = false"
      @setAppointmentData="setAppointmentData"
      @reloadData="reloadOrderListData"
    />
    <edit-immediate-care-order-dialog
      v-if="editImmediateCareOrderDialog"
      :order="order"
      :patient="order?.patient"
      :items="order?.orderItems"
      :contract="actualContract"
      @close="closeImmediateCareDialog"
    />
    <v-dialog
      v-model="editMultipleOrders.dialog"
      max-width="800"
    >
      <v-card class="tw-p-3">
        <v-card-title class="tw-text-xl">
          Editar OS selecionadas
        </v-card-title>
        <v-card-text>
          <div class="tw-grid tw-grid-cols-1 tw-gap-y-6">
            <v-alert
              class="tw-mt-4 tw-mb-0"
              type="warning"
              outlined
            >
              Atenção: O processo de atualizar as OS's pode demorar alguns segundos.
            </v-alert>
            <v-autocomplete
              v-model="editMultipleOrders.orderStatus"
              :items="ORDER_STATUS"
              :item-disabled="getShouldDisableOrderStatus"
              :disabled="shouldDisableOrderStatus"
              no-data-text="Nenhum status disponível entre as OSs selecionadas"
              label="Status"
              item-text="name"
              item-value="value"
              filled
              clearable
              hide-details
              :menu-props="{ bottom: true, offsetY: true }"
            />

            <v-autocomplete
              v-model="editMultipleOrders.billId"
              :item-text="formatBill"
              item-value="id"
              label="Fatura"
              :items="filterBillsToBill"
              :disabled="shouldDisableBillId"
              clearable
              filled
              hide-select
              hide-details
            >
              <template v-slot:item="{ item, on }">
                <v-list-item v-on="on">
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ item.hmo.name }} - {{ item.number }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ item.closeDate | formatDate }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>
                      {{ formatBillStatus(item.status) }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-autocomplete>

            <div class="tw-flex tw-gap-10 tw-my-0 tw-py-0">
              <v-checkbox
                v-model="editMultipleOrders.isInvoiceEmitted"
                label="Nota Fiscal Emitida"
                filled
                color="primary"
                hide-details
              />
            </div>
          </div>
        </v-card-text>
        <div class="tw-flex tw-gap-x-3 tw-p-2 tw-justify-end">
          <v-btn
            class="btn-dialog tw-ml-auto"
            large
            @click="toggleMultipleOrdersDialog"
          >
            Cancelar
          </v-btn>
          <v-btn
            class="btn-dialog"
            color="primary"
            large
            :disabled="!editMultipleOrders.orderStatus"
            :loading="isLoadingBulkUpdate"
            @click="bulkUpdate()"
          >
            Salvar informações
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  APPOINTMENT,
  BILL,
  IMMEDIATE_CARE,
  HMO,
  ORDER,
  ELIGIBILITY,
} from 'amparo-enums'
import { mapGetters, mapActions, mapState } from 'vuex'
import PAYMENT_METHODS from '@/enums/orderPaymentMethods'
import getOrderStatusList from '@/utils/getOrderStatusList'
import moment from 'moment'
import createWebSheet from '@/utils/xlsx/downloader'
import { writeFile } from 'xlsx'

import {
  isNil,
  map,
  prop,
  values,
  not,
  isEmpty,
  filter,
  includes,
  join,
  length,
  omit,
  reduce,
  reject,
  intersection,
  pluck,
  uniq,
  any,
  isNotEmpty,
} from 'ramda'

export default {
  name: 'OrderList',
  components: {
    CreateAppointment: () => import('@/components/CreateAppointment/CreateAppointment'),
    EditImmediateCareOrderDialog: () => import('./EditImmediateCareOrderDialog'),
  },
  data: () => ({
    isInvoiceEmitted: {
      true: 'Sim',
      false: 'Não',
    },
    isDownloadingXslsx: false,
    editMultipleOrders: {
      dialog: false,
      isInvoiceEmitted: false,
      orderStatus: null,
      billId: null,
    },
    order: null,
    actualContract: null,
    selectedSlot: null,
    slots: [],
    pagination: {},
    orderStatusValues: values(ORDER.status),
    createAppointmentDialog: false,
    editImmediateCareOrderDialog: false,
    eligibilityErrorMessages: {
      [ELIGIBILITY.status.hmoConfigDataNotFound]: 'Dados de integração não configurados',
      [ELIGIBILITY.status.cmIntegrationUnavailable]: 'Integração com serviço da CM indisponível',
      [ELIGIBILITY.status.getCredentialsUnavailable]: 'Serviço de credenciais de integração não disponível',
      [ELIGIBILITY.status.cmHmoConfigError]: 'ID de integração inválido',
      [ELIGIBILITY.status.cmResponseError]: 'Carteirinha não válida',
      [ELIGIBILITY.status.notEligible]: 'Carteirinha não válida',
      [ELIGIBILITY.status.cmPortalAuthenticationError]: 'Credenciais de integração inválidas',
      [ELIGIBILITY.status.serviceUnavailable]: 'Serviço indisponível ',
    },
    excelHeaders: [
      'Ordem de Serviço',
      'Status da Ordem de Serviço',
      'Fatura',
      'Operadora',
      'Plano',
      'Unidade',
      'Paciente',
      'CPF',
      'Data/Hora',
      'Status do Atendimento',
      'Valor',
      'Número da Guia',
      'Profissional',
      'Autorização',
      'Especialidade',
      'OS Origem',
      'Forma de Pagamento',
      'Nota Fiscal Emitida',
      'CIDS/CIAPS',
      'Elegibilidade',
      'Última Atualização',
    ],
    headers: [
      {
        text: '',
        value: 'null',
        sortable: false,
      },
      {
        text: 'OS',
        value: 'number',
        sortable: false,
        class: 'tw-text-xs tw-tracking-wide tw-font-semibold tw-text-green-dark py-5',
      },
      {
        text: 'Status da OS',
        value: 'orderStatusLabel',
        sortable: false,
        class: 'tw-text-xs tw-tracking-wide tw-font-semibold tw-text-green-dark',
      },
      {
        text: 'Fatura',
        value: 'bill',
        sortable: false,
        class: 'tw-text-xs tw-tracking-wide tw-font-semibold tw-text-green-dark',
      },
      {
        text: 'Operadora',
        value: 'hmo',
        sortable: false,
        class: 'tw-text-xs tw-tracking-wide tw-font-semibold tw-text-green-dark',
      },
      {
        text: 'Plano',
        value: 'healthProduct',
        sortable: false,
        class: 'tw-text-xs tw-tracking-wide tw-font-semibold tw-text-green-dark',
      },
      {
        text: 'Unidade',
        value: 'clinic',
        sortable: false,
        class: 'tw-text-xs tw-tracking-wide tw-font-semibold tw-text-green-dark',
      },
      {
        text: 'Paciente',
        value: 'patient',
        sortable: false,
        class: 'tw-text-xs tw-tracking-wide tw-font-semibold tw-text-green-dark',
      },
      {
        text: 'Data',
        value: 'date',
        sortable: false,
        class: 'tw-text-xs tw-tracking-wide tw-font-semibold tw-text-green-dark',
      },
      {
        text: 'Atendimento',
        value: 'appointment',
        sortable: false,
        class: 'tw-text-xs tw-tracking-wide tw-font-semibold tw-text-green-dark',
      },
      {
        text: 'Valor',
        value: 'totalPrice',
        sortable: false,
        class: 'tw-text-xs tw-tracking-wide tw-font-semibold tw-text-green-dark',
      },
      {
        text: 'Número da Guia',
        value: 'hmoGuideNumber',
        sortable: false,
        class: 'tw-text-xs tw-tracking-wide tw-font-semibold tw-text-green-dark',
      },
      {
        text: 'Autorização',
        value: 'authorizationCode',
        sortable: false,
        class: 'tw-text-xs tw-tracking-wide tw-font-semibold tw-text-green-dark',
      },
      {
        text: 'Profissional',
        value: 'professional',
        sortable: false,
        class: 'tw-text-xs tw-tracking-wide tw-font-semibold tw-text-green-dark',
      },
      {
        text: 'Especialidade',
        value: 'speciality',
        sortable: false,
        class: 'tw-text-xs tw-tracking-wide tw-font-semibold tw-text-green-dark',
      },
      {
        text: 'OS Origem',
        value: 'relatedOs',
        sortable: false,
        class: 'tw-text-xs tw-tracking-wide tw-font-semibold tw-text-green-dark',
      },
      {
        text: 'Forma de Pagamento',
        value: 'paymentMethods',
        sortable: false,
        class: 'tw-text-xs tw-tracking-wide tw-font-semibold tw-text-green-dark',
      },
      {
        text: 'NF emitida',
        value: 'isInvoiceEmitted',
        sortable: false,
        class: 'tw-text-xs tw-tracking-wide tw-font-semibold tw-text-green-dark',
      },
      {
        text: 'CIDS/CIAPS',
        value: 'encounterCids',
        sortable: false,
        class: 'tw-text-xs tw-tracking-wide tw-font-semibold tw-text-green-dark',
      },
      {
        text: 'Elegibilidade',
        value: 'eligibilityStatus',
        sortable: false,
        class: 'tw-text-xs tw-tracking-wide tw-font-semibold tw-text-green-dark',
      },
      {
        text: 'Última Atualização',
        value: 'updatedAt',
        sortable: false,
        class: 'tw-text-xs tw-tracking-wide tw-font-semibold tw-text-green-dark',
      },
    ],
    selectedOrders: [],
    ORDER_STATUS: values(ORDER.status),
    isLoadingBulkUpdate: false,
    isLoadingOrderEdit: false,
    isLoadingOrderEditId: null,
  }),
  computed: {
    ...mapGetters({
      clinics: 'clinic/clinics',
      ordersList: 'billing/orders',
      allOrders: 'billing/allOrders',
      isLoadingOrderList: 'billing/isLoadingOrderList',
      userHasAccessToFunctionality: 'authentication/userHasAccessToFunctionality',
    }),
    ...mapState({
      bills: ({ billing }) => billing.bills,
    }),
    selectedOrdersId() {
      return map(prop('id'), this.selectedOrders)
    },
    isSelectedOrdersEmpty() {
      return isEmpty(this.selectedOrders)
    },
    formattedOrdersList() {
      return isEmpty(this.ordersList)
        ? []
        : this.formatOrdersList(this.ordersList.rows)
    },
    hasAccessToContractEdit() {
      return this.userHasAccessToFunctionality.contractEdit
    },
    hasMoreThanOneHmo() {
      const hmoIds = pluck('hmoId', this.selectedOrders)

      return length(uniq(hmoIds)) > 1
    },
    filteredStatusToEditMultipleOrders() {
      const orderStatusList = map(item => getOrderStatusList({
        orderToEditStatus: item.orderStatus,
        attendanceStatus: item.attendanceStatus,
        newBillId: this.editMultipleOrders.billId,
        hasAccessToOrderEditService: this.userHasAccessToFunctionality.orderEditService,
        hasAccessToOrderEditBill: this.userHasAccessToFunctionality.orderEditBill,
        eligibilityStatus: item.eligibility?.status,
        hmoId: item.hmoId,
      }), this.selectedOrders)

      const reducedStatusList = reduce(
        (acc, item) => intersection(acc, item),
        values(ORDER.status),
        orderStatusList,
      )

      if (this.editMultipleOrders.billId) {
        return [ORDER.status.billed, reducedStatusList]
      }
      return reducedStatusList
    },
    filterBillsToBill() {
      if (this.hasMoreThanOneHmo) return []

      return filter(
        bill => bill.status === BILL.status.toBill.value,
        this.bills,
      )
    },
    shouldDisableOrderStatus() {
      return Boolean(this.editMultipleOrders.billId)
    },
    shouldDisableBillId() {
      const statusAllowedToBill = [
        ORDER.status.receivedByBilling.value,
        ORDER.status.billed.value,
      ]

      return any(
        order => !includes(order.orderStatus, statusAllowedToBill),
        this.selectedOrders,
      )
    },
    orderAppointment() {
      if (!this.order?.appointment) return null

      const slotsWithoutDeletedAt = reject(
        prop('deletedAt'),
        this.order.appointment.slots,
      )

      if (isEmpty(slotsWithoutDeletedAt)) {
        return this.order?.appointment
      }

      return {
        ...this.order?.appointment,
        slots: pluck('slotId', slotsWithoutDeletedAt),
      }
    },
  },
  watch: {
    'editMultipleOrders.billId': function billIdChanged(billId) {
      if (!billId) {
        this.editMultipleOrders.orderStatus = null
      }
      if (billId) {
        this.editMultipleOrders.orderStatus = ORDER.status.billed.value
      }
    },
  },
  deactivated() {
    this.editImmediateCareOrderDialog = false
    this.createAppointmentDialog = false
    this.resetSelectedOrders()
  },
  methods: {
    ...mapActions({
      setOrderPagination: 'billing/setOrderPagination',
      findOrder: 'billing/findOrder',
      getActualContract: 'billing/getActualContract',
      listOrder: 'billing/listOrder',
      listSlot: 'agenda/listSlot',
      listAllOrders: 'billing/listAllOrders',
      setSnackbar: 'snackbar/setSnackbar',
      bulkUpdateOrders: 'billing/bulkUpdateOrders',
    }),
    async changePage() {
      this.resetSelectedOrders()
      await this.setOrderPagination(this.pagination)
      await this.listOrder()
    },
    getEligibilityMessage(eligibility) {
      if (!eligibility) return 'Não foi verificada'
      if (eligibility.status === ELIGIBILITY.status.eligible) {
        return `Elegível ${eligibility.manually && '(manualmente)'}
          ${moment(eligibility.time).format('DD/MM/YY HH:mm')}
        `
      }
      if (eligibility.status === ELIGIBILITY.status.notEligible) {
        return `Não elegível ${eligibility.manually && '(manualmente)'}
          ${moment(eligibility.time).format('DD/MM/YY HH:mm')}
        `
      }

      return `Erro ao validar: ${this.eligibilityErrorMessages[eligibility.status]}`
    },
    getShouldDisableOrderStatus(status) {
      return !includes(status, this.filteredStatusToEditMultipleOrders)
    },
    formatParentReturnalOrder(orderItems) {
      const orderItemsList = filter(
        prop('parentReturnalOrderId'),
        orderItems,
      )

      return join(
        ' ',
        map(item => `#${item.parentReturnalOrder.number}`, orderItemsList),
      )
    },
    formatDate(date) {
      return moment(date).utc().format('DD/MM/YY')
    },
    async downloadXlsx() {
      this.isDownloadingXslsx = true
      await this.listAllOrders()
      const orders = this.allOrders.rows

      const rows = map(
        omit(['id']),
        this.formatOrdersList(orders, true),
      )

      try {
        const websheet = createWebSheet(
          {
            rows,
            headers: this.excelHeaders,
            sheetName: 'Ordens de Serviço',
          },
        )

        writeFile(websheet, `RelatórioOS ${moment().format('DD-MM-YY')}.xlsx`)
      } catch (err) {
        this.setSnackbar({
          status: 'error',
          message: 'Erro ao baixar o Relatório.',
        })
      }
      this.isDownloadingXslsx = false
    },
    formatBillStatus(status) {
      return BILL.status[status].label
    },
    formatBill(bill) {
      if (!bill) {
        return 'N/A'
      }

      return `#${bill.number}
        ${moment(bill.closeDate).format('DD/MM/YYYY')}
        ${this.formatBillStatus(bill.status)}
      `
    },
    getEligibilityLabel(status) {
      if (!status) return 'Não verificada'
      if (status === ELIGIBILITY.status.eligible) {
        return 'Elegível'
      }
      if (status === ELIGIBILITY.status.notEligible) {
        return 'Não elegível'
      }
      return 'Não verificada (Erro)'
    },
    formatOrdersList(orders, ignoreExtraAttributes = false) {
      return orders.map(order => ({
        number: order.number,
        orderStatusLabel: ORDER.status[order.status].name,
        bill: this.formatBill(order.bill),
        hmoName: order.healthMaintenanceOrganization?.name || 'N/A',
        healthProductName: order.healthProduct?.name || 'N/A',
        clinic: order.clinic ? order.clinic.name : 'N/A',
        patientName: order.patient.name,
        patientCPF: this.$filters.getIdentifierDocument(order.patient),
        date: this.formatDate(order.date),
        attendanceStatusLabel: this.getAttendanceStatusLabel(order),
        totalPrice: order.totalPrice,
        hmoGuideNumber: order.hmoGuideNumber || 'N/A',
        professional: order.professional ? order.professional.name : 'N/A',
        authorizationCode: order.authorizationCode || 'N/A',
        speciality: order.speciality.name,
        parentReturnalOrder: this.formatParentReturnalOrder(order.orderItems),
        paymentMethod: this.paymentMethodsValue(order),
        isInvoiceEmitted: this.isInvoiceEmitted[order.isInvoiceEmitted],
        encounterCids: order.encounterCids || [],
        eligibilityStatus: this.getEligibilityLabel(order.eligibility?.status),
        updatedAt: this.formatDate(order.updatedAt),
        ...(ignoreExtraAttributes ? {} : {
          orderStatus: order.status,
          id: order.id,
          hmoId: order.healthMaintenanceOrganization?.id || 'N/A',
          patientId: order.patient.id,
          attendanceStatus: order.appointment?.status || order.immediateCare?.status,
          eligibility: order.eligibility,
        }),
      }))
    },
    toggleMultipleOrdersDialog() {
      if (this.editMultipleOrders.dialog) {
        this.editMultipleOrders = {
          dialog: false,
          orderStatus: null,
          isInvoiceEmitted: null,
          billId: null,
        }
        return
      }
      this.editMultipleOrders.dialog = !this.editMultipleOrders.dialog
    },
    buildBillId(billId) {
      if (billId) {
        return { billId }
      }
      return {}
    },
    async bulkUpdate() {
      try {
        this.isLoadingBulkUpdate = true
        const {
          orderStatus,
          isInvoiceEmitted,
          billId,
        } = this.editMultipleOrders

        const idsErrors = await this.bulkUpdateOrders({
          id: pluck('id', this.selectedOrders),
          status: orderStatus,
          isInvoiceEmitted: Boolean(isInvoiceEmitted),
          ...this.buildBillId(billId),
        })

        if (not(isEmpty(idsErrors))) {
          const findOrdersObject = filter(({ id }) => includes(id, idsErrors), this.ordersList.rows)

          const ordersNumbers = map(prop('number'), findOrdersObject)

          const message = length(idsErrors) > 1
            ? `As ordens ${join(', ', ordersNumbers)} não puderam ser alteradas`
            : `A ordem ${join(', ', ordersNumbers)} não pode ser alterada`

          this.setSnackbar({
            status: 'error',
            message,
          })
          return
        }

        this.setSnackbar({
          status: 'success',
          message: 'As ordens foram alteradas com sucesso',
        })
      } catch (err) {
        this.setSnackbar({
          status: 'error',
          message: 'Erro ao alterar ordens',
        })
      } finally {
        this.isLoadingBulkUpdate = false
        this.selectedOrders = []
        this.listOrder()
        this.toggleMultipleOrdersDialog()
      }
    },
    getAttendanceStatusLabel({ appointment, immediateCare }) {
      if (immediateCare) {
        const immediateCareEnum = Object.values(IMMEDIATE_CARE.status)
          .find(enumObject => enumObject.value === immediateCare.status)

        return immediateCareEnum?.label || 'N/A'
      }
      if (appointment) return APPOINTMENT.status[appointment.status].label
      return 'N/A'
    },
    async openDetailsDialog(id) {
      try {
        this.isLoadingOrderEdit = true
        this.isLoadingOrderEditId = id
        this.order = await this.findOrder({ id })
        this.actualContract = await this.getActualContract(this.order.healthProductId)

        if (this.order.appointment) {
          this.slots = await this.listSlot({
            date: moment(this.order.appointment.date).format('YYYY-MM-DD'),
            clinic: { id: this.order.appointment.clinicId },
            professional: { id: this.order.appointment.professionalId },
            speciality: { id: this.order.appointment.specialityId },
          })

          for (const slot of this.slots) {
            if (any(
              ({ slotId }) => slotId === slot.id,
              this.order.appointment.slots,
            )) {
              this.selectedSlot = slot
              break
            }
          }
          this.createAppointmentDialog = true
        }

        if (this.order.immediateCare) {
          this.editImmediateCareOrderDialog = true
        }
      } catch (err) {
        this.setSnackbar({
          status: 'error',
          message: 'Erro ao abrir ordem',
        })
      } finally {
        this.isLoadingOrderEdit = false
        this.isLoadingOrderEditId = null
      }
    },
    reloadOrderListData() {
      this.createAppointmentDialog = false
      this.listOrder()
    },
    isPrivateHmo(hmoId) {
      return includes(
        hmoId,
        [HMO.private],
      )
    },
    paymentMethodsValue(order) {
      const { paymentMethods, healthMaintenanceOrganization } = order
      if (isNil(paymentMethods) && this.isPrivateHmo(healthMaintenanceOrganization.id)) {
        return 'Não Informado'
      }
      if (isNil(paymentMethods)) {
        return 'Convênio'
      }
      return paymentMethods.reduce((acc, { paymentMethod }, index) => {
        if (index === 0) {
          return PAYMENT_METHODS[paymentMethod].name
        }
        return `${acc}, ${PAYMENT_METHODS[paymentMethod].name}`
      }, '')
    },
    setAppointmentData(data) {
      if (isNotEmpty(data)) {
        this.createAppointmentDialog = false
        this.listOrder()
      }
    },
    closeImmediateCareDialog() {
      this.editImmediateCareOrderDialog = false
      this.listOrder()
    },
    resetSelectedOrders() {
      this.selectedOrders = []
    },
    openPatientProfile(patientId) {
      const route = this.$router.resolve({ name: 'patientProfile', params: { id: patientId } })
      window.open(route.href, '_blank')
    },
  },
}
</script>

<style lang="stylus" scoped>
.v-table--header >>> .v-data-table-header
  background-color #f6f6f6 !important
  box-shadow 0px 3px 1px rgba(224, 224, 224, 0.9)
  padding 1rem 0 !important
</style>
