<template>
  <div>
    <div class="tw-w-full tw-bg-green-frostee tw-px-12 tw-py-6 tw-grid tw-grid-cols-12 tw-gap-4">
      <v-text-field
        v-model="filters.name"
        class="tw-col-span-8"
        filled
        label="Buscar por nome"
        clearable
        hide-details
        no-data-text="Digite para buscar"
        @change="listHmo({ name: filters.name })"
        @click:clear="listHmo"
      />
      <v-btn
        color="dark-green"
        class="tw-col-span-4 tw-font-semibold tw-h-full"
        dark
        block
        large
      >
        <v-icon left>
          mdi-magnify
        </v-icon>
        Buscar Plano de Saúde
      </v-btn>
    </div>

    <div class="tw-my-8 tw-px-12">
      <div
        v-if="isHmosEmpty"
        class="tw-text-[#00000061] tw-text-6xl tw-tracking-tighter tw-font-extralight"
      >
        Nenhum resultado encontrado.
      </div>
      <template v-else>
        <div
          v-for="(hmo, index) in hmos"
          :key="index"
          class="
            tw-grid
            tw-grid-cols-12
            tw-border
            tw-border-solid
            tw-border-gray-thin
            tw-rounded-lg
            tw-p-5
            tw-mb-3
          "
        >
          <div class="tw-col-span-11">
            <div class="tw-text-[#000000de] tw-text-2xl">
              {{ hmo.name }}
            </div>

            <div
              class="tw-text-[#00000099] tw-tracking-wider tw-leading-loose"
            >
              Apelido: {{ hmo.nickName }}
            </div>

            <div
              class="tw-text-[#00000099] tw-tracking-wider tw-leading-loose"
            >
              Tipo: {{ hmo.type }}
            </div>
          </div>
          <div class="tw-col-span-1 tw-flex tw-flex-row tw-gap-2 tw-justify-end">
            <v-tooltip
              v-if="isWorkSpaceAmparo"
              top
            >
              <template #activator="{ on }">
                <v-btn
                  class="tw-font-semibold"
                  text
                  icon
                  @click="editIntegrationsDialog(hmo)"
                  v-on="on"
                >
                  <v-icon>mdi-clipboard-text-multiple</v-icon>
                </v-btn>
              </template>
              <span>Editar Integrações</span>
            </v-tooltip>
            <v-tooltip top>
              <template #activator="{ on }">
                <v-btn
                  class="tw-font-semibold"
                  text
                  icon
                  @click="openProductListDialog(hmo)"
                  v-on="on"
                >
                  <v-icon>mdi-package-variant-closed</v-icon>
                </v-btn>
              </template>
              <span>Plano</span>
            </v-tooltip>
            <v-tooltip top>
              <template #activator="{ on }">
                <v-btn
                  class="tw-font-semibold"
                  text
                  icon
                  :data-testid="`hmo__edit-${hmo.name}--button`"
                  @click="editDialog(hmo)"
                  v-on="on"
                >
                  <v-icon>mdi-account-edit</v-icon>
                </v-btn>
              </template>
              <span>Editar</span>
            </v-tooltip>
          </div>
        </div>
      </template>
    </div>

    <create-hmo
      v-if="showEditHmoDialog"
      edit-hmo
      :hmo-data="editedHmo"
      :hmo-list-filter="filters"
      @closeCreateHmoDialog="closeDialog"
    />

    <edit-hmo-integrations
      v-if="showEditHmoIntegrationsDialog"
      edit-hmo
      :hmo-data="editedHmo"
      :hmo-list-filter="filters"
      @closeDialog="closeDialog"
    />

    <health-product-list
      v-if="showProductListDialog"
      :hmo-id="editedHmo.id"
      @closeDialog="closeDialog"
    />
  </div>
</template>

<script>
import {
  isEmpty,
} from 'ramda'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'HmoList',
  components: {
    CreateHmo: () => import('./CreateHmo'),
    EditHmoIntegrations: () => import('./EditHmoIntegrations'),
    HealthProductList: () => import('./HealthProductList'),
  },
  data() {
    return {
      showProductListDialog: false,
      showEditHmoDialog: false,
      showEditHmoIntegrationsDialog: false,
      editedHmo: {},
      filters: {},
      count: '',
      numberOfPages: null,
      page: 1,
    }
  },
  computed: {
    ...mapGetters({
      hmos: 'healthMaintenanceOrganization/hmos',
      loading: 'healthMaintenanceOrganization/loading',
      isWorkSpaceAmparo: 'authentication/isWorkSpaceAmparo',
    }),
    isHmosEmpty() {
      return isEmpty(this.hmos)
    },
  },
  mounted() {
    this.listHmo()
  },
  methods: {
    ...mapActions({
      listHmo: 'healthMaintenanceOrganization/listHmo',
    }),
    openProductListDialog(hmoData) {
      this.editedHmo = hmoData
      this.showProductListDialog = true
    },
    editDialog(hmoData) {
      this.editedHmo = hmoData
      this.showEditHmoDialog = true
    },
    editIntegrationsDialog(hmoData) {
      this.editedHmo = hmoData
      this.showEditHmoIntegrationsDialog = true
    },
    closeDialog() {
      this.editedHmo = {}
      this.showEditHmoDialog = false
      this.showProductListDialog = false
      this.showEditHmoIntegrationsDialog = false
    },
  },
}
</script>

<style lang='stylus' scoped>
@import '../../style/_core/colors.styl'
.v-btn.v-btn--text
  border solid 1px thin-gray
</style>
