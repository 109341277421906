<template>
  <div>
    <field-alert
      v-if="question.alertOptions"
      :alert-options="question.alertOptions"
      :answer-data="answerData"
    />
    <component
      :is="component"
      v-if="component"
      class="tw-w-full"
      :question="question"
      :answer="answer"
      :data="encounterData"
      :should-clean-field="shouldCleanField"
      :should-show-all-warnings="shouldShowAllWarnings"
      :professional-preferences="professionalPreferences"
      @update:mevo-data="handleUpdateMevoData"
    />
  </div>
</template>

<script>
import moment from 'moment'
import { mapActions, mapGetters } from 'vuex'
import {
  path,
  propEq,
  find,
  isNil,
  clone,
} from 'ramda'
import { EMR_QUESTIONS } from 'amparo-enums'

const { questions } = EMR_QUESTIONS

export default {
  name: 'FieldBuilder',
  components: {
    FieldAlert: () => import('./FieldAlert'),
  },
  props: [
    'question',
    'encounterData',
    'shouldCleanField',
    'professionalPreferences',
    'shouldShowAllWarnings',
  ],
  data() {
    return {
      component: null,
      answerData: null,
    }
  },
  computed: {
    ...mapGetters({
      encounter: 'emr/encounter',
    }),
    loader() {
      if (!this.question.type) {
        return null
      }
      // eslint-disable-next-line prefer-template
      return () => import('./' + this.question.type)
    },
  },
  mounted() {
    this.loader()
      .then(() => {
        this.component = () => this.loader()
      })
      .catch(() => {
        // this.component = () => import('templates/default')
      })
  },
  deactivated() {
    this.component = null
  },
  methods: {
    ...mapActions({
      setEncounterResponse: 'emr/setEncounterResponse',
      createPrescriptionReport: 'emr/createPrescriptionReport',
      updatePrescriptionReport: 'emr/updatePrescriptionReport',
      createPrescription: 'emr/createPrescription',
      initPrescriptionProgress: 'emr/initPrescriptionProgress',
      updatePrescriptionManagementMedicalReports: 'emr/updatePrescriptionManagementMedicalReports',
      updateEncounter: 'emr/updateEncounter',
    }),
    answer(questionId, result) {
      const res = clone(result)
      this.answerData = { questionId, res }
      this.setEncounterResponse({ questionId, res })
    },
    getInProgressMevoPrescription() {
      const prescriptions = path(['encounter', 'data', 'prescriptionsManagement'], this)

      if (isNil(prescriptions)) return null

      const prescriptionInProgress = find(
        propEq('in progress', 'state'),
      )(prescriptions)

      return prescriptionInProgress
    },
    async getMevoPrescription() {
      const inProgressPrescription = this.getInProgressMevoPrescription()
      if (!isNil(inProgressPrescription)) return inProgressPrescription

      const newPrescription = await this.createPrescription({ encounterId: this.encounter.id })
      this.initPrescriptionProgress(newPrescription)

      return {
        id: newPrescription.idPrescricao,
        token: newPrescription.CodigoValidacao,
        bearerToken: newPrescription.Token,
        state: 'in progress',
      }
    },
    async getInProgressMedicalReportId() {
      const prescription = await this.getMevoPrescription()
      const medicalReports = path(['medicalReports'], prescription)

      if (isNil(medicalReports)) return null

      const medicalReportsInProgress = find(
        propEq('in progress', 'state'),
      )(medicalReports)

      return path(['id'], medicalReportsInProgress)
    },
    async handleMevoMedicalReport(prescription, question, answer) {
      const prescriptionId = prescription.id
      const prescriptionToken = prescription.bearerToken
      const medicalReportId = await this.getInProgressMedicalReportId()
      const reportData = {
        type: question.mevoOptions.type,
        content: answer,
        date: moment(),
      }
      if (!isNil(medicalReportId)) {
        await this.updatePrescriptionReport({
          prescriptionId,
          reportId: medicalReportId,
          reportData,
          bearerToken: prescriptionToken,
        })
        return
      }
      const newMedicalReport = await this.createPrescriptionReport({
        prescriptionId,
        reportData,
        bearerToken: prescriptionToken,
      })
      if (newMedicalReport) {
        this.updatePrescriptionManagementMedicalReports({
          prescriptionId,
          medicalReportId: newMedicalReport.idPrescricaoLaudoMedico,
          medicalReportState: 'in progress',
          medicalReportType: reportData.type,
        })
        await this.updateEncounter()
      }
    },
    async handleUpdateMevoData(data) {
      const { question, res } = data
      const prescription = await this.getMevoPrescription()
      switch (question.id) {
        case questions.medicalReport:
          await this.handleMevoMedicalReport(prescription, question, res)
          break
        default:
          break
      }
    },
  },
}
</script>
