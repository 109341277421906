<template>
  <div>
    <div>
      <div
        v-if="attachmentList.length === 0"
        :class="emptyListClasses"
      >
        Não há anexos.
      </div>

      <div
        class="
          tw-text-xs
          tw-uppercase
          tw-text-green-japanese-laurel
          tw--mx-9
        "
      >
        <div
          v-for="attachment of attachmentList"
          :key="attachment.fileName"
          class="
            tw-flex
            tw-items-center
            tw-px-8
            tw-py-1
            tw-transition-all
            tw-duration-300
            hover:tw-bg-green-japanese-laurel
            hover:tw-bg-opacity-10
          "
        >
          <div
            class="
              tw-flex
              tw-flex-nowrap
              tw-font-bold
              align-center
              tw-max-w-[70%]
              tw-cursor-pointer
              tw-break-keep
            "
            @click="showAttachment(attachment)"
          >
            <v-tooltip
              bottom
              max-width="450"
            >
              <template
                #activator="{ on }"
              >
                <span
                  class="tw-inline-block tw-truncate"
                  v-on="shouldShowTextTooltip(attachment) && on"
                >
                  {{ attachment.originalName }}
                </span>
              </template>
              <span>
                {{ buildAttachmentName(attachment) }}
              </span>
            </v-tooltip>

            <span class="tw-inline-block tw-text-nowrap">
              &nbsp;- {{ buildAttachmentDate(attachment) }}
            </span>
          </div>

          <div class="tw-flex tw-w-full tw-justify-end tw-items-center">
            <v-btn
              class="tw-mr-2"
              small
              icon
            >
              <v-icon
                color="japaneseLaurel"
                size="18"
                @click="showAttachment(attachment)"
              >
                mdi-eye
              </v-icon>
            </v-btn>

            <v-btn
              class="tw-mr-2"
              small
              icon
            >
              <v-icon
                size="18"
                color="japaneseLaurel"
                @click="downloadAttachment(attachment)"
              >
                mdi-download
              </v-icon>
            </v-btn>

            <v-btn
              v-if="!hideDeleteButton"
              small
              icon
            >
              <v-icon
                color="japaneseLaurel"
                size="18"
                @click="openConfirmDeletion(attachment)"
              >
                mdi-close-circle
              </v-icon>
            </v-btn>
          </div>
        </div>

        <div
          v-if="seeMoreMessage"
          class="
            tw-text-center
            tw-py-2
            tw-font-bold
            tw-underline
            tw-cursor-pointer
            tw-transition-all
            tw-duration-300
            hover:tw-bg-green-japanese-laurel
            hover:tw-bg-opacity-10
          "
          @click="toggleShowListAttachmentDialog()"
        >
          Ver mais...
        </div>
      </div>
    </div>


    <list-attachment-dialog
      :show-dialog="showListAttachmentDialog"
      :patient="patient"
      :attachments="attachments"
      :order-id="orderId"
      :limited-list="false"
      :route="route"
      :update-data="updateData"
      type="general"
      @update:showDialog="toggleShowListAttachmentDialog"
    />

    <v-dialog
      v-model="confirmDeletion"
      max-width="420"
    >
      <v-card
        class="confirm-deletion__confirm-alert"
      >
        <v-card-title
          v-if="attachmentToDelete"
          class="tw-text-base tw-break-normal tw-p-0 tw-mb-4"
        >
          Deseja realmente apagar o arquivo {{ attachmentToDelete.originalName }}?
        </v-card-title>

        <v-card-actions class="tw-p-0">
          <div class="tw-grid tw-grid-cols-2 tw-gap-x-4 tw-w-full">
            <v-btn
              large
              text
              block
              class="btn btn-secondary"
              @click="confirmDeletion = false"
            >
              Não
            </v-btn>

            <v-btn
              large
              block
              class="btn btn-danger"
              @click="deleteAttachment(attachmentToDelete)"
            >
              Sim
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  always,
  cond,
  equals,
  filter,
  gt,
  includes,
  length,
  not,
  omit,
  path,
  prop,
  slice,
  T,
} from 'ramda'
import { mapActions } from 'vuex'

export default {
  name: 'ListAttachments',
  components: {
    ListAttachmentDialog: () => import('./ListAttachmentDialog'),
  },
  props: [
    'patient',
    'attachments',
    'type',
    'limitedList',
    'route',
    'orderId',
    'updateData',
    'contractId',
    'emptyListClasses',
    'hideDeleteButton',
  ],
  data() {
    return {
      uploadProgress: null,
      shouldShowProgressBar: false,
      confirmDeletion: false,
      attachmentToDelete: null,
      seeMoreMessage: false,
      showListAttachmentDialog: false,
    }
  },
  computed: {
    attachmentList() {
      if (this.limitedList && gt(length(this.attachments), 4)) {
        this.showSeeMoreMessage(true)
        return slice(0, 3, this.attachments)
      }
      this.showSeeMoreMessage(false)
      return this.attachments
    },
  },
  mounted() {
    this.$emit('attachmentLength', this.attachmentList.length)
  },
  methods: {
    ...mapActions({
      listAttachments: 'emr/listAttachments',
      setSnackbar: 'snackbar/setSnackbar',
    }),
    toggleShowListAttachmentDialog() {
      this.showListAttachmentDialog = !this.showListAttachmentDialog
    },
    buildAttachmentName({ originalName, date }) {
      return `${originalName} ${date ? ' - ' : ''} ${this.$options.filters.formatDate(date)}`
    },
    buildAttachmentDate({ date }) {
      return this.$options.filters.formatDate(date)
    },
    showSeeMoreMessage(status) {
      this.seeMoreMessage = status
    },
    removeTypeExam(attachmentList) {
      if (equals(this.type, 'exam')) return attachmentList
      return filter(
        ({ fileName }) => not(includes('_exa_', fileName)),
        attachmentList,
      )
    },
    openConfirmDeletion(attachment) {
      this.confirmDeletion = true
      this.attachmentToDelete = attachment
    },
    generateCurrentIdObject({
      patient,
      orderId,
      contractId,
      s3Key,
    }) {
      return cond([
        [path(['s3Key']), always({ s3Key })],
        [path(['patient', 'resume']), always({ patientMedicalResumeId: patient?.resume?.id })],
        [path(['orderId']), always({ orderId })],
        [path(['contractId']), always({ contractId })],
        [T, always({ patientId: patient?.id })],
      ])({
        patient,
        orderId,
        contractId,
        s3Key,
      })
    },
    getAttachmentsRoute(currentId, action, id) {
      if (!prop('patientId', currentId)) return this.route
      if (action === 'delete') return `${this.route}/${id}`
      return `${this.route}/url`
    },
    getAttachmentParams({
      fileName,
      originalName,
      extension,
      s3Key,
      action,
      id,
    }) {
      const currentId = this.generateCurrentIdObject({
        patient: this.patient,
        orderId: this.orderId,
        contractId: this.contractId,
        s3Key,
      })

      const route = this.getAttachmentsRoute(currentId, action, id)

      const params = {
        ...currentId,
        fileName,
        originalName,
        extension,
      }

      return { route, params, currentId }
    },
    deleteAttachment({
      id,
      fileName,
      originalName,
      s3Key,
    }) {
      const { route: deleteRoute, params, currentId } = this.getAttachmentParams({
        id,
        fileName,
        originalName,
        s3Key,
        action: 'delete',
      })

      this.$http.delete(deleteRoute, { params: omit(['originalName'], params) })
        .then(async () => {
          this.showSnackbar('success', `${originalName} Apagado com sucesso!`)
          this.confirmDeletion = false
          this.attachmentToDelete = false
          if (prop('patientMedicalResumeId', currentId)) {
            await this.listAttachments({ patientId: this.patient.id })
          } else {
            await this.updateData()
          }
        })
        .catch((error) => {
          if (error?.response?.data?.errorCode === 'instance_invalid') {
            this.showSnackbar('error', 'Arquivo não encontrado.')
            return
          }
          this.showSnackbar('error', 'Não foi possível deletar o arquivo.')
        })
    },
    showAttachment({
      fileName,
      originalName,
      extension,
      s3Key,
    }) {
      const { route: showRoute, params } = this.getAttachmentParams({
        fileName,
        originalName,
        extension,
        s3Key,
        action: 'show',
      })

      this.$http.get(showRoute, { params })
        .then((res) => {
          window.open(res.data.signedUrlToDownload)
        })
        .catch(() => {
          this.showSnackbar('error', 'Erro ao fazer download.')
        })
    },
    async downloadAttachment({
      fileName,
      originalName,
      extension,
      s3Key,
    }) {
      const { route: downloadRoute, params } = this.getAttachmentParams({
        fileName,
        originalName,
        extension,
        s3Key,
        action: 'download',
      })

      try {
        const response = await this.$http.get(downloadRoute, { params })
        const awsUrl = response.data.signedUrlToDownload

        const awsResponse = await fetch(awsUrl)
        const blob = await awsResponse.blob()

        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = originalName
        link.click()

        window.URL.revokeObjectURL(link.href)
      } catch (error) {
        this.showSnackbar('error', 'Erro ao fazer download.')
      }
    },
    showSnackbar(status, message) {
      this.setSnackbar({ status, message })
    },
    shouldShowTextTooltip(attachment) {
      const text = this.buildAttachmentName(attachment)
      if (text.length >= 26) return true
      return false
    },
  },
}
</script>

<style lang="stylus" scoped>
@import '../../style/_core/colors.styl'

.confirm-deletion__confirm-alert
  padding 15px

.card-dialog__title
  font-size 19.9px !important
  letter-spacing 0.3px

</style>
