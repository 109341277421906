<template>
  <div class="tw-grid tw-grid-cols-3 tw-items-center">
    <v-pagination
      class="tw-col-start-2"
      :value="page"
      :length="pages"
      :total-visible="VISIBLE_PAGES"
      @input="changePage($event)"
    />
    <div class="tw-justify-self-end">
      Total: {{ count }}
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ItemListContainerPagination',
  data() {
    return {
      VISIBLE_PAGES: 5,
    }
  },
  computed: {
    ...mapGetters({
      page: 'billing/itemPage',
      pages: 'billing/itemNumberOfPages',
      count: 'billing/itemCount',
    }),
  },
  methods: {
    ...mapActions({
      setItemPage: 'billing/setItemPage',
    }),
    changePage(page) {
      this.setItemPage(page)
      this.$emit('changePage', page)
    },
  },
}
</script>
