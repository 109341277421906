import axios from '@/lib/axios'
import removeNilOrEmpty from '@/utils/removeNilOrEmpty'

export default {
  state: {
    loadingContractItem: false,
    contractItems: [],
    allContractItems: [],
    contractItemCount: 0,
    contractItemNumberOfPages: 0,
    contractItemPage: 1,
  },
  mutations: {
    SET_LOADING_CONTRACT_ITEM(state, payload) {
      state.loadingContractItem = payload
    },
    SET_CONTRACT_ITEMS(state, payload) {
      state.contractItems = payload
    },
    SET_ALL_CONTRACT_ITEMS(state, payload) {
      state.allContractItems = payload
    },
    SET_CONTRACT_ITEM_COUNT(state, payload) {
      state.contractItemCount = payload
    },
    SET_CONTRACT_ITEM_NUMBER_OF_PAGES(state, payload) {
      state.contractItemNumberOfPages = payload
    },
    SET_CONTRACT_ITEM_PAGE(state, payload) {
      state.contractItemPage = payload
    },
  },
  actions: {
    listContractItem({ commit, state }, params) {
      return new Promise((resolve, reject) => {
        commit('SET_LOADING_CONTRACT_ITEM', true)
        axios.get('/billing/contract_item', {
          params: {
            ...removeNilOrEmpty(params),
            page: state.contractItemPage,
          },
        })
          .then(({ data }) => {
            commit('SET_CONTRACT_ITEMS', data.contractItems)
            commit('SET_CONTRACT_ITEM_COUNT', data.count)
            commit('SET_CONTRACT_ITEM_NUMBER_OF_PAGES', data.numberOfPages)
            resolve(data)
          })
          .catch(reject)
          .finally(() => commit('SET_LOADING_CONTRACT_ITEM', false))
      })
    },
    listAllContractItems({ commit }, params) {
      return new Promise((resolve, reject) => {
        commit('SET_LOADING_CONTRACT_ITEM', true)
        axios.get('/billing/contract_item', {
          params: {
            ...removeNilOrEmpty(params),
            listAll: true,
          },
        })
          .then(({ data }) => {
            commit('SET_ALL_CONTRACT_ITEMS', data.contractItems)
            resolve(data)
          })
          .catch(reject)
          .finally(() => commit('SET_LOADING_CONTRACT_ITEM', false))
      })
    },
    searchContractItem(_, params) {
      return new Promise((resolve, reject) => {
        axios.get('/billing/contract_item/search', {
          params,
        })
          .then(({ data }) => {
            resolve(data)
          })
          .catch(reject)
      })
    },
    downloadContract(_, contractId) {
      return new Promise((resolve, reject) => {
        axios.get('/billing/contract_item/download', {
          params: {
            contractId,
          },
        })
          .then(({ data }) => {
            resolve(data)
          })
          .catch(reject)
      })
    },
    downloadCatalogContract() {
      return new Promise((resolve, reject) => {
        axios.get('/billing/contract_item/download/catalog')
          .then(({ data }) => {
            resolve(data)
          })
          .catch(reject)
      })
    },
    uploadContract(_, { params }) {
      return new Promise((resolve, reject) => {
        axios.post('/billing/contract_item/', params)
          .then(({ data }) => {
            resolve(data)
          })
          .catch(reject)
      })
    },
    setContractItemPage({ commit }, page = 1) {
      commit('SET_CONTRACT_ITEM_PAGE', page)
    },
  },
  getters: {
    isLoadingContractItem: state => state.loadingContractItem,
    contractItems: state => state.contractItems,
    allContractItems: state => state.allContractItems,
    contractItemCount: state => state.contractItemCount,
    contractItemNumberOfPages: state => state.contractItemNumberOfPages,
    contractItemPage: state => state.contractItemPage,
  },
}
