<template>
  <div class="tw-px-10 tw-pb-6">
    <div
      class="tw-cursor-pointer tw-my-4"
      @click="redirectToListContract"
    >
      <icon-previous icon-color="green" />
    </div>
    <div class="tw-grid tw-grid-cols-12 tw-gap-x-6 tw-gap-y-2">
      <div class="tw-col-span-8 xl:tw-col-span-9 tw-grid tw-grid-cols-12 tw-gap-6">
        <div class="tw-col-span-full tw-text-xl tw-font-bold">
          <div>
            {{ contract.name || 'N/A' }} - {{ contractHmoName }} - {{ contractHealthProductName }}
          </div>
          <div class="tw-font-medium">
            {{ contractStatusText }}
          </div>
        </div>
        <div class="tw-col-span-full">
          <div class="tw-font-bold">
            Nome
          </div>
          <div class="tw-text-base tw-font-normal">
            {{ contract.name || '-' }}
          </div>
        </div>
        <div class="tw-col-span-full">
          <div class="tw-font-bold">
            Data de inicio
          </div>
          <div class="tw-text-base tw-font-normal">
            {{ contract.startDate || '-' | formatDate }}
          </div>
        </div>
        <div class="tw-col-span-full">
          <div class="tw-font-bold">
            Descrição
          </div>
          <div class="tw-text-base tw-font-normal tw-whitespace-pre-line">
            {{ contract.description || '-' }}
          </div>
        </div>
        <div class="tw-col-span-full">
          <div class="tw-font-bold">
            Jornada de ativação
          </div>
          <div
            v-if="journeys && journeys.length === 0"
            class="tw-text-base tw-font-normal"
          >
            Sem Jornada de ativação configurada
          </div>
          <div
            v-else
            class="tw-grid tw-grid-cols-2 xl:tw-grid-cols-3 tw-gap-6 tw-mt-3"
          >
            <v-card
              v-for="(journey, index) of journeys"
              :key="index"
              class="tw-px-2 tw-pt-2 tw-py-4 tw-min-w-72"
              elevation="3"
              height="auto"
            >
              <v-card-title class="tw-pl-0">
                {{ journey.clinic ? 'Unidade ' + journey.clinic.name : 'Padrão' }}
              </v-card-title>
              <div class="tw-font-medium">
                Slots por especialidade:
              </div>
              <div class="tw-font-normal">
                Médico de família:
                <span class="tw-font-bold">
                  {{ journey.doctorSlots }}
                </span>
              </div>
            </v-card>
          </div>
        </div>
        <div class="tw-col-span-full">
          <div class="tw-font-bold">
            Obrigatoriedade de RQE
          </div>
          <div class="tw-text-base tw-font-normal">
            {{ contractRequiredRqeText }}
          </div>
        </div>
        <div class="tw-col-span-2">
          <div class="tw-font-bold">
            Usuario criador
          </div>
          <div class="tw-text-base tw-font-normal">
            {{ userCreatedBy || '-' }}
          </div>
        </div>
        <div class="tw-col-span-2">
          <div class="tw-font-bold">
            Data de Criação
          </div>
          <div class="tw-text-base tw-font-normal">
            {{ contract.createdAt | formatDate }}
          </div>
        </div>
        <div class="tw-col-span-full">
          <div class="tw-font-bold">
            Contrato Original
          </div>
          <list-attachments
            class="tw-block"
            :contract-id="contract.id"
            :attachments="contract.contractAttachments"
            :limited-list="true"
            :route="'/billing/contract_attachment'"
            :update-data="getContractData"
            type="general"
          />
        </div>
      </div>
      <div
        v-if="hasContractLog"
        class="tw-col-span-3 xl:tw-col-span-2 tw-justify-self-end"
      >
        <v-card
          width="200"
        >
          <v-list>
            <v-list-group
              :value="false"
            >
              <template v-slot:activator>
                <v-list-item-title>
                  Histórico
                </v-list-item-title>
              </template>
              <v-list-item
                v-for="log in contractLog"
                :key="log.id"
                three-line
              >
                <v-list-item-content>
                  <v-list-item-title>
                    {{ log.userName }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ log.date | dateWithBrTime }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle>
                    {{ getContractStatusValue(log.oldStatus) }} -
                    {{ getContractStatusValue(log.newStatus) }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </v-list>
        </v-card>
      </div>
      <v-tooltip
        v-if="!contractIsArchived"
        class="tw-col-span-1"
        top
      >
        <template #activator="{ on }">
          <v-btn
            class="tw-mt-4"
            text
            icon
            @click="redirectToEditContract"
            v-on="on"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </template>
        <span>Editar</span>
      </v-tooltip>
      <div class="tw-col-span-full tw-text-xl tw-font-bold tw-mt-4">
        Tabela de Cobertura
      </div>
      <v-btn
        class="tw-col-span-full tw-w-min tw-text-base tw-font-semibold tw-capitalize tw-p-0"
        color="primary"
        text
        @click="showDownloadXlsxDialog = true"
      >
        Download Planilha
        <v-icon right>
          mdi-download
        </v-icon>
      </v-btn>
      <v-text-field
        v-model="searchName"
        class="tw-col-span-full tw-my-4"
        label="Buscar item"
        clearable
        hide-details
        filled
      />
      <contract-items-table
        class="tw-col-span-full"
        :contract-items="contractItems"
        :page="page"
        @setPageAndListItems="setPageAndListItems"
      />
    </div>
    <download-contract-xlsx-dialog
      :show-download-xlsx-dialog="showDownloadXlsxDialog"
      :contract="contract"
      @closeDialog="showDownloadXlsxDialog = false"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import debounce from 'lodash/debounce'
import {
  defaultTo,
  equals,
  find,
  isEmpty,
  isNil,
  path,
  prop,
  propEq,
  reject,
  values,
} from 'ramda'
import { CONTRACT } from 'amparo-enums'

export default {
  name: 'ContractItemContainer',
  components: {
    ContractItemsTable: () => import('@/components/Common/ContractItemsTable'),
    DownloadContractXlsxDialog: () => import('@/components/Common/DownloadContractXlsxDialog'),
    IconPrevious: () => import('@/components/UI/IconPrevious'),
    ListAttachments: () => import('@/components/Common/ListAttachments'),
  },
  data() {
    return {
      notFoundMessage: 'Não encontrado',
      page: 1,
      showDownloadXlsxDialog: false,
      isDownloadingXslsx: false,
      searchName: '',
      isRqeTextRequired: {
        true: {
          label: 'Sim',
          value: true,
        },
        false: {
          label: 'Não',
          value: false,
        },
      },
    }
  },
  computed: {
    ...mapGetters({
      contract: 'billing/contract',
      contractItems: 'billing/contractItems',
    }),
    journeys() {
      return this.contract.journeys
    },
    contractStatusText() {
      return this.getLabelBy(this.contract.status, CONTRACT.status)
    },
    contractRequiredRqeText() {
      return this.getLabelBy(this.contract.requiredRqe, this.isRqeTextRequired)
    },
    userCreatedBy() {
      return path(['createdBy', 'name'], this.contract)
    },
    contractIsArchived() {
      return equals(this.contract.status, CONTRACT.status.archived.value)
    },
    contractHmoName() {
      return path(['hmo', 'name'], this.contract)
    },
    contractHealthProductName() {
      return path(['healthProduct', 'name'], this.contract)
    },
    hasContractLog() {
      return !(
        isNil(this.contract.log)
        || isEmpty(this.contractLog)
      )
    },
    contractLog() {
      return reject(
        ({ oldStatus, newStatus }) => equals(oldStatus, newStatus),
        defaultTo([], this.contract.log),
      )
    },
  },
  watch: {
    searchName: debounce(async function search() {
      await this.setPageAndListItems(1)
    }, 500),
  },
  methods: {
    ...mapActions({
      getContract: 'billing/getContract',
      setContractItemPage: 'billing/setContractItemPage',
      listContractItem: 'billing/listContractItem',
    }),
    async getContractData() {
      await this.getContract(this.contract.id)
    },
    getContractStatusValue(contractStatus) {
      if (isNil(contractStatus)) return ''
      return this.getLabelBy(contractStatus, CONTRACT.status)
    },
    getLabelBy(value, options) {
      if (isNil(value)) return ''
      return prop(
        'label',
        find(propEq(value, 'value'), values(options)),
      )
    },
    redirectToEditContract() {
      this.$router.push(`/billing/edit-contract/${this.contract.id}`)
    },
    async setPageAndListItems(contractItemPage) {
      this.setContractItemPage(contractItemPage)
      this.page = contractItemPage
      await this.listContractItem({ contractId: this.contract.id, name: this.searchName })
    },
    redirectToListContract() {
      this.$router.push('/billing/list-contract')
    },
    contractItemClinicName(item) {
      const clinicLabel = isNil(item.clinicId)
        ? 'Padrão'
        : path(['clinic', 'name'], item)

      return clinicLabel
    },
  },
}
</script>
