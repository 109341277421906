<template>
  <div>
    <div
      v-if="shouldShowFormsPatientAlert"
    >
      <v-divider />

      <v-alert
        class="tw-my-6"
        color="red lighten-5"
        :value="true"
      >
        <div class="tw-flex tw-justify-between tw-items-center">
          <div>
            <v-icon color="danger">
              mdi-alert
            </v-icon>
            <span class="tw-ml-2 tw-text-black">
              O Paciente possui
              <span class="tw-text-danger">
                {{ uncompletedFormsLength }}
                formulário<span v-if="shouldShowFormsPatientPluralLabel">s</span>
              </span>
              não concluído<span v-if="shouldShowFormsPatientPluralLabel">s</span>
            </span>
          </div>

          <div>
            <v-btn
              text
              color="red darken-4"
              @click="openPatientForms"
            >
              Preencher
            </v-btn>
          </div>
        </div>
      </v-alert>
    </div>

    <v-dialog
      v-model="isOpenPatientForms"
      transition="dialog-bottom-transition"
      persistent
      max-width="1400"
    >
      <v-card>
        <v-card-title
          class="tw-flex tw-justify-between tw-text-xl tw-font-medium"
        >
          Formulários

          <v-btn
            icon
            @click="closePatientForms"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <patient-forms-list modal-view />
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import {
  gt,
  not,
  equals,
} from 'ramda'
import { mapGetters } from 'vuex'

export default {
  name: 'EmrFormWarning',
  components: {
    PatientFormsList: () => import('@/pages/Emr/PatientFormsList'),
  },
  data() {
    return {
      isOpenPatientForms: false,
    }
  },
  computed: {
    ...mapGetters({
      uncompletedFormsLength: 'patientForm/uncompletedFormsLength',
    }),
    shouldShowFormsPatientAlert() {
      return gt(this.uncompletedFormsLength, 0)
    },
    shouldShowFormsPatientPluralLabel() {
      return not(equals(this.uncompletedFormsLength, 1))
    },
  },
  methods: {
    openPatientForms() {
      this.isOpenPatientForms = true
    },
    closePatientForms() {
      this.isOpenPatientForms = false
    },
  },
}
</script>

<style lang="stylus" scoped>
@import '../../../style/_core/colors.styl'

.forms-patient__message-icon
  align-items center

.forms-patient__message
  color black

.forms-patient__message-number
  color red

</style>
