<template>
  <v-expansion-panel class="tw-bg-transparent">
    <v-expansion-panel-header
      hide-actions
      class="tw-p-0 tw-px-4"
    >
      <template v-slot:default="{ open }">
        <div class="tw-flex tw-justify-between tw-items-center">
          <div class="tw-flex tw-items-center">
            <v-icon left>
              {{ open ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
            </v-icon>
            <span class="tw-tracking-wide tw-text-xl tw-text-gray tw-font-medium">
              {{ title }}
            </span>
          </div>

          <slot name="actions" />
        </div>
      </template>
    </v-expansion-panel-header>

    <v-expansion-panel-content class="tw-text-gray">
      <slot />

      <div
        v-if="shouldNotShowList"
        class="tw-font-medium"
      >
        {{ noShowMessage }}
      </div>

      <ul v-else>
        <li
          v-for="item in list"
          :key="item.id"
        >
          <div class="tw-flex tw-items-center tw-flex-nowrap">
            <div class="tw-font-medium tw-max-w-[80%]">
              <v-tooltip
                bottom
                max-width="450"
              >
                <template #activator="{ on }">
                  <span
                    class="tw-block tw-truncate"
                    v-on="shouldShowTextTooltip(formatLabel(item)) && on"
                  >
                    {{ formatLabel(item) }}
                  </span>
                </template>

                {{ formatLabel(item) }}
              </v-tooltip>
            </div>

            <v-chip
              v-if="item.denied"
              class="tw-inline-flex tw-ml-2"
              color="red"
              text-color="white"
              label
              x-small
              disabled
            >
              negado
            </v-chip>
          </div>
        </li>
      </ul>
    </v-expansion-panel-content>

    <v-divider />
  </v-expansion-panel>
</template>

<script>
import { isEmpty } from 'ramda'

export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    list: {
      type: Array,
      default: () => [],
    },
    formatLabel: {
      type: Function,
      default: ({ name }) => name,
    },
    noShowMessage: {
      type: String,
      default: '',
    },
  },
  computed: {
    shouldNotShowList() {
      return isEmpty(this.list)
    },
  },
  methods: {
    shouldShowTextTooltip(text, maxLength = 16) {
      if (text.length >= maxLength) return true
      return false
    },
  },
}
</script>

<style lang="stylus" scoped>
.v-chip--disabled
  opacity 1
</style>
