<template>
  <div>
    <div
      :class="{
        'sidebar__container-fixed': windowTop >= sidebarStickyOffset,
        'sidebar__container': true,
        'tw-pt-6 tw-pb-12 tw-px-3': true,
        'tw-overflow-y-auto tw-overflow-x-hidden tw-overscroll-contain': true,
      }"
    >
      <loading-emr-resume v-if="isLoading" />
      <div
        v-else
        :class="{
          'tw-pr-2': windowTop < sidebarStickyOffset,
        }"
      >
        <div class="tw-px-4 tw-grid tw-grid-cols-1 tw-gap-y-4">
          <emr-resume-legacy :resume="patient.resume.data.legacy" />

          <v-btn
            v-if="isInsideEncounter"
            color="amparo-medium-green"
            class="tw-font-medium"
            outlined
            large
            block
            @click="showEmrHistory = true"
          >
            <v-icon
              left
              size="25px"
            >
              mdi-clipboard-text
            </v-icon>
            Histórico de consultas
          </v-btn>

          <v-btn
            v-if="isWorkSpaceAmparo && !isPatientHmoPackageTypeNone"
            color="amparo-medium-green"
            outlined
            large
            block
            @click="openCarePlan"
          >
            <v-icon
              class="tw-font-medium"
              left
              size="25px"
            >
              mdi-heart
            </v-icon>
            Plano de cuidado
          </v-btn>
        </div>

        <v-divider class="tw-mt-6" />

        <emr-care-line-patient
          v-if="isWorkSpaceAmparo && !isPatientHmoPackageTypeNone"
        />

        <v-expansion-panels
          accordion
          multiple
          flat
        >
          <emr-resume-item-panel
            title="Lista de problemas"
          >
            <div class="tw-grid tw-grid-cols-1 tw-gap-y-4">
              <div>
                <div class="tw-mb-2 tw-font-bold">
                  Problemas Ativos
                </div>

                <div
                  v-if="shouldNotShowResumeList(activeProblems)"
                  class="tw-font-medium"
                >
                  Paciente não possui problemas monitorados em ciclos de cuidado.
                </div>

                <ul>
                  <li
                    v-for="activeProblem in activeProblems"
                    :key="activeProblem.id"
                    class="tw-pb-1"
                  >
                    <div class="tw-flex tw-flex-nowrap">
                      <div class="tw-max-w-[90%]">
                        <v-tooltip
                          bottom
                          max-width="450"
                        >
                          <template #activator="{ on }">
                            <span
                              class="tw-block tw-truncate"
                              v-on="shouldShowTextTooltip(formatActiveProblemLabel(activeProblem))
                                && on"
                            >
                              {{ formatActiveProblemLabel(activeProblem) }}
                            </span>

                            <div class="tw-font-medium tw-text-sm">
                              {{ activeProblem.status }} -
                              Inicio: {{ activeProblem.startDate | formatDate }}
                            </div>
                          </template>

                          {{ formatActiveProblemLabel(activeProblem) }}
                        </v-tooltip>
                      </div>

                      <div class="tw-ml-2">
                        <highlight-pin-button
                          v-if="isInsideEncounter"
                          icon-size="16"
                          :condition="activeProblem"
                          :pinned="isHighlightedBackground(
                            activeProblem,
                            'activeProblemHighlightData'
                          )"
                          @togglePin="applyPinAction"
                        />
                      </div>
                    </div>
                  </li>
                </ul>
              </div>

              <div>
                <div class="tw-mb-2 tw-font-bold">
                  Antecedentes Pessoais
                </div>

                <div
                  v-if="shouldNotShowResumeList(personalBackground)"
                  class="tw-font-medium"
                >
                  Paciente não possui histórico anterior.
                </div>

                <ul>
                  <li
                    v-for="background in personalBackground"
                    :key="background.id"
                    class="tw-pb-1"
                  >
                    <div class="tw-flex tw-flex-nowrap tw-items-center">
                      <div class="tw-font-medium tw-max-w-[70%]">
                        <v-tooltip
                          bottom
                          max-width="450"
                        >
                          <template #activator="{ on }">
                            <span
                              class="tw-block tw-truncate"
                              v-on="shouldShowTextTooltip(background.name) && on"
                            >
                              {{ background.name }}
                            </span>
                          </template>
                          {{ background.name }}
                        </v-tooltip>
                      </div>

                      <div class="tw-ml-2">
                        <v-chip
                          v-if="background.denied"
                          class="tw-mr-2"
                          color="red"
                          text-color="white"
                          label
                          x-small
                          disabled
                        >
                          negado
                        </v-chip>

                        <highlight-pin-button
                          v-if="isInsideEncounter"
                          icon-size="16"
                          :pinned="isHighlightedBackground(background, 'personalHighlightData')"
                          :condition="background"
                          @togglePin="applyPinAction"
                        />
                      </div>
                    </div>
                  </li>
                </ul>
              </div>

              <div>
                <div class="tw-mb-2 tw-font-bold">
                  Antecedentes Familiares
                </div>

                <div
                  v-if="shouldNotShowResumeList(familyBackground)"
                  class="tw-font-medium"
                >
                  Paciente não possui histórico familiar anterior.
                </div>

                <ul>
                  <li
                    v-for="familyHistory in familyBackground"
                    :key="familyHistory.id"
                    class="tw-pb-1"
                  >
                    <div class="tw-flex tw-flex-nowrap tw-items-center">
                      <div class="tw-font-medium tw-max-w-[70%]">
                        <v-tooltip
                          bottom
                          max-width="450"
                        >
                          <template #activator="{ on }">
                            <span
                              class="tw-block tw-truncate"
                              v-on="shouldShowTextTooltip(familyHistory.name) && on"
                            >
                              {{ familyHistory.name }}
                            </span>
                          </template>
                          {{ familyHistory.name }}
                        </v-tooltip>
                      </div>

                      <div class="tw-ml-2">
                        <v-chip
                          v-if="familyHistory.denied"
                          class="tw-mr-2"
                          color="red"
                          text-color="white"
                          label
                          x-small
                          disabled
                        >
                          negado
                        </v-chip>

                        <highlight-pin-button
                          v-if="isInsideEncounter"
                          icon-size="16"
                          :pinned="isHighlightedBackground(familyHistory, 'familyHighlightData')"
                          :condition="familyHistory"
                          @togglePin="applyPinAction"
                        />
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </emr-resume-item-panel>

          <emr-resume-item-panel
            title="Tratamentos em andamento"
            no-show-message="Paciente não possui tratamentos em andamento."
            :list="rejectSuspendedMeds(patient.resume.data.treatments)"
          />

          <emr-resume-item-panel
            v-if="!shouldNotShowResumeList(
              patient.resume.data.prescriptions
            )"
            title="Histórico de prescrições"
            no-show-message="Paciente não possui histórico de prescrições."
          >
            <ul>
              <li
                v-for="prescription in patient.resume.data.prescriptions"
                :key="prescription.id"
              >
                <div
                  v-if="tooltips.copy[prescription.id]"
                  class="tw-flex tw-flex-nowrap tw-items-center"
                >
                  <div class="tw-font-medium tw-max-w-[70%]">
                    <v-tooltip
                      bottom
                      max-width="450"
                    >
                      <template #activator="{ on }">
                        <span
                          class="tw-block tw-truncate"
                          v-on="shouldShowTextTooltip(formatLabelPrescription(prescription))
                            && on"
                        >
                          {{ formatLabelPrescription(prescription) }}
                        </span>
                      </template>
                      {{ formatLabelPrescription(prescription) }}
                    </v-tooltip>
                  </div>

                  <div class="tw-ml-2 tw-flex">
                    <div
                      v-if="prescription.prescriptions[0].origin === 'mevo'"
                      class="tw-flex"
                    >
                      <v-tooltip bottom>
                        <template #activator="{ on }">
                          <v-btn
                            icon
                            v-on="on"
                            @click="copyPrescriptionLink(prescription)"
                          >
                            <v-icon size="20">
                              mdi-content-copy
                            </v-icon>
                          </v-btn>
                        </template>
                        {{ tooltips.copy[prescription.id].label }}
                      </v-tooltip>

                      <v-tooltip bottom>
                        <template #activator="{ on }">
                          <v-btn
                            icon
                            v-on="on"
                            @click="sendPrescriptionSms(prescription)"
                          >
                            <v-icon size="20">
                              mdi-message-processing
                            </v-icon>
                          </v-btn>
                        </template>
                        {{ tooltips.sendSms[prescription.id].label }}
                      </v-tooltip>
                    </div>

                    <v-tooltip bottom>
                      <template #activator="{ on }">
                        <v-btn
                          icon
                          v-on="on"
                          @click="openPrescriptionDialog(prescription)"
                        >
                          <v-icon size="20">
                            mdi-eye
                          </v-icon>
                        </v-btn>
                      </template>
                      Visualizar
                    </v-tooltip>
                  </div>
                </div>
              </li>
            </ul>
          </emr-resume-item-panel>

          <emr-resume-item-panel
            title="Alergias"
            no-show-message="Paciente não possui alergias."
            :list="removeResolved(patient.resume.data.allergies)"
          />

          <emr-resume-item-panel
            v-if="!shouldNotShowResumeList(patient.resume.data.habits)"
            title="Hábitos"
            no-show-message="Paciente não possui hábitos."
            :list="removeResolved(patient.resume.data.habits)"
          />

          <emr-resume-item-panel
            title="Vacinas"
            no-show-message="Paciente não possui vacinas."
            :list="removeResolved(patient.resume.data.vaccines)"
            :format-label="formatVaccineLabel"
          />

          <emr-resume-item-panel
            title="Anexos"
          >
            <template v-slot:actions>
              <add-attachment
                :patient="patient"
                type="general"
              />
            </template>

            <list-attachments
              :key="reload"
              empty-list-classes="tw-font-medium"
              type="general"
              :patient="patient"
              :attachments="attachments"
              :limited-list="true"
              :route="'/emr/attachment'"
            />
          </emr-resume-item-panel>
        </v-expansion-panels>

        <section class="tw-mx-4">
          <div
            class="
              tw-flex
              tw-items-center
              tw-text-xl
              tw-font-medium
              tw-mt-6
              tw-mb-2
            "
          >
            <v-icon
              color="primary"
              left
            >
              mdi-star
            </v-icon>
            Lembretes
          </div>

          <div
            v-if="!shouldShowReminder(patient.resume.data.reminders)"
            class="tw-font-medium"
          >
            Sem lembretes.
          </div>

          <ul>
            <li
              v-for="reminder in getRemindersFromProfessional(patient.resume.data.reminders)"
              :key="reminder.id"
              class="tw-font-medium"
            >
              <v-tooltip
                bottom
                max-width="450"
              >
                <template #activator="{ on }">
                  <span
                    class="tw-block tw-truncate"
                    v-on="shouldShowTextTooltip(reminder.text, 45) && on"
                  >
                    {{ reminder.text }}
                  </span>
                </template>
                {{ reminder.text }}
              </v-tooltip>
            </li>
          </ul>
        </section>

        <div v-if="isInsideEncounter">
          <v-divider class="tw-mt-6 tw-mb-8" />

          <emr-end-encounter
            class="tw-mx-4"
            block
          />
        </div>
      </div>
    </div>

    <care-plan
      v-if="isOpenCarePlan"
      :patient-id="patient.id"
      @closeDialog="closeCarePlan"
    />

    <v-dialog
      v-model="showEmrHistory"
      max-width="1400"
    >
      <v-card
        v-if="showEmrHistory"
        min-height="600px"
      >
        <v-card-title class="tw-mx-8 tw-flex tw-justify-between">
          <h3 class="tw-text-2xl tw-font-semibold tw-text-gray-soft mt-4">
            Histórico de consultas
          </h3>

          <v-btn
            icon
            @click="showEmrHistory = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <emr-history
            :is-inside-encounter="true"
            :hide-timeline-dots="true"
          />
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-if="isOpenPrescriptionDialog"
      v-model="isOpenPrescriptionDialog"
      max-width="600"
    >
      <v-card>
        <v-card-title class="tw-flex tw-justify-between">
          Prescrição - {{ prescriptionDialog[0].prescribedAt | formatDate }}

          <v-btn
            icon
            @click="isOpenPrescriptionDialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <ul>
            <li
              v-for="prescription in prescriptionDialog"
              :key="prescription.id"
            >
              {{ prescription.name }}
            </li>
          </ul>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import AddAttachment from '@/components/Common/AddAttachment'
import EmrResumeItemPanel from '@/components/Common/EmrResumeItemPanel'
import EmrEndEncounter from '@/pages/Emr/EmrEndEncounter'
import ListAttachments from '@/components/Common/ListAttachments'
import EmrCareLinePatient from '@/pages/Emr/EmrCareLinePatient'
import EmrResumeLegacy from '@/pages/Emr/EmrResumeLegacy'
import EmrHistory from '@/pages/Emr/EmrHistory'
import HighlightPinButton from '@/components/emrFields/HighlightPinButton'
import { isDateAfterDays } from '@/utils/dateValidators'
import CarePlan from '@/components/Common/CarePlan'
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
import {
  __,
  always,
  and,
  ascend,
  concat,
  descend,
  defaultTo,
  equals,
  filter,
  find,
  gt,
  head,
  isEmpty,
  isNil,
  indexBy,
  length,
  map,
  not,
  or,
  hasPath,
  path,
  pipe,
  prop,
  propEq,
  reduce,
  reject,
  replace,
  sort,
  sortWith,
  split,
  without,
} from 'ramda'

export default {
  components: {
    AddAttachment,
    ListAttachments,
    EmrCareLinePatient,
    EmrResumeLegacy,
    EmrHistory,
    EmrEndEncounter,
    EmrResumeItemPanel,
    CarePlan,
    HighlightPinButton,
    LoadingEmrResume: () => import('@/components/loaders/LoadingEmrResume'),
  },
  props: {
    isInsideEncounter: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoading: true,
      emptyTreatments: false,
      showEmrHistory: false,
      isOpenPrescriptionDialog: false,
      isOpenCarePlan: false,
      prescriptionDialog: {},
      tooltips: {
        copy: {},
        sendEmail: {},
        sendSms: {},
      },
      windowTop: 0,
    }
  },
  computed: {
    ...mapGetters({
      encounter: 'emr/encounter',
      isPatientHmoPackageTypeNone: 'emr/isPatientHmoPackageTypeNone',
      attachments: 'emr/attachments',
      patient: 'emr/patient',
      reload: 'emr/reload',
      questions: 'emr/questions',
      user: 'authentication/user',
      isWorkSpaceAmparo: 'authentication/isWorkSpaceAmparo',
    }),
    sidebarStickyOffset() {
      return this.isInsideEncounter ? 125 : 183
    },
    encounterData() {
      return this.encounter.data
    },
    historyConditionsId() {
      return this.questions.historyConditions
    },
    familyBackgroundId() {
      return this.questions.familyHistory
    },
    clinicalBackgroundId() {
      return this.questions.clinicalBackground
    },
    surgeriesId() {
      return this.questions.surgeries
    },
    careLinesPatient() {
      const careLines = defaultTo([], this.patient.careLinesPatient)
      return sort(descend(path(['careLine', 'isPrimary'])), careLines)
    },
    activeProblems() {
      const activeProblems = filter(
        path(['activeProblemHighlightData', 'isHighlighted']),
        this.removeResolved(path(['patient', 'resume', 'data', 'conditions'], this)),
      )
      const expiredProblemsList = filter(
        problem => and(
          path(['activeProblemHighlightData', 'highlightExpirationTimeByDays'], problem),
          isDateAfterDays(
            prop('startDate', problem),
            path(['activeProblemHighlightData', 'highlightExpirationTimeByDays'], problem),
          ),
        ),
        activeProblems,
      )
      this.setExpiredActiveProblems(expiredProblemsList)
      return sortWith([
        ascend(path(['activeProblemHighlightData', 'displayOrder'])),
        ascend(prop('name')),
      ])(without(expiredProblemsList, activeProblems))
    },
    personalBackground() {
      const { data: { clinicalBackground, surgeries } } = this.patient.resume

      return filter(
        path(['personalHighlightData', 'isHighlighted']),
        this.removeResolved(concat(clinicalBackground, surgeries)),
      )
    },
    familyBackground() {
      const { data: { familyHistory } } = this.patient.resume
      return filter(
        path(['familyHighlightData', 'isHighlighted']),
        this.removeResolved(familyHistory),
      )
    },
  },
  mounted() {
    window.addEventListener('scroll', this.onScroll)
  },
  async created() {
    this.$bus.$on('selectMultipleCardHighlightPin', () => this.handleGetPatientResume())
    await this.handleGetPatientResume()
    this.setTooltipsPrescriptions('copy', 'Copiar')
    this.setTooltipsPrescriptions('sendEmail', 'Enviar por e-mail')
    this.setTooltipsPrescriptions('sendSms', 'Enviar por SMS')
    await this.getPatientExamResult(this.$route.params.id)
  },
  async activated() {
    await this.handleGetPatientResume()
    this.setTooltipsPrescriptions('copy', 'Copiar')
    this.setTooltipsPrescriptions('sendEmail', 'Enviar por e-mail')
    this.setTooltipsPrescriptions('sendSms', 'Enviar por SMS')
  },
  deactivated() {
    this.isLoading = true
  },
  beforeDestroy() {
    this.$bus.$off('update:emrResumeHighlightPin', this.handleEvent)
    window.removeEventListener('scroll', this.onScroll)
  },
  methods: {
    ...mapActions({
      getPatientExamResult: 'emr/getPatientExamResult',
      getPatientResume: 'emr/getPatientResume',
      listAttachments: 'emr/listAttachments',
      setEmrPatient: 'emr/setEmrPatient',
      setExpiredActiveProblems: 'emr/setExpiredActiveProblems',
      updateEncounter: 'emr/updateEncounter',
      setPinAction: 'emr/setPinAction',
      sendSms: 'agenda/sendPrescriptionSms',
      setSnackbar: 'snackbar/setSnackbar',
    }),
    onScroll() {
      this.windowTop = window.top.scrollY
    },
    setTooltipsPrescriptions(list, label) {
      this.tooltips[list] = pipe(
        defaultTo([]),
        indexBy(prop('id')),
        map(always({ label })),
      )(this.patient.resume.data.prescriptions)
    },
    setTooltip(list, id, defaultLabel, label) {
      this.tooltips[list][id].label = label
      setTimeout(() => {
        this.tooltips[list][id].label = defaultLabel
      }, 1000)
    },
    async sendPrescriptionSms({ id, prescriptionId }) {
      const params = {
        patient: {
          id: this.patient.id,
          telephone: this.patient.telphone,
        },
        prescriptionId,
      }
      try {
        await this.sendSms(params)
        this.setTooltip('sendSms', id, 'Enviar por SMS', 'Enviado!')
      } catch (error) {
        const errorMessage = path(['response', 'data', 'errorMessage'], error)
        if (errorMessage === 'invalid_phone_number') {
          this.setSnackbar({
            status: 'error',
            message: 'Telefone inválido, atualize e tente novamente.',
          })
        }
        this.setTooltip('sendSms', id, 'Enviar por SMS', 'Erro!')
      }
    },
    copyPrescriptionLink(prescription) {
      const copyInput = document.createElement('input')
      copyInput.style = 'position: absolute; left: -10000px; top: -10000px;'
      copyInput.value = `Acesse o link ${prescription.prescriptions[0].prescriptionUrl} para acessar sua prescrição digital`
      document.body.appendChild(copyInput)
      copyInput.select()
      document.execCommand('copy')
      document.body.removeChild(copyInput)
      this.setTooltip('copy', prescription.id, 'Copiar', 'Copiado!')
    },
    formatLabelPrescription({ prescriptions }) {
      const prescriptionNames = pipe(
        map(
          pipe(
            prop('name'),
            split(' '),
            head,
            replace(',', ''),
            concat(__, ', '),
          ),
        ),
        reduce(concat, ''),
        replace(/, $/g, ''),
      )(prescriptions)

      const prescribedAtFormatted = moment(prescriptions[0].prescribedAt, 'YYYY-MM-DD')
        .format('DD/MM/YYYY')

      return `${prescriptionNames} - ${prescribedAtFormatted}`
    },
    formatActiveProblemLabel({ version, code, name }) {
      return `${version} - ${code} - ${name}`
    },
    formatVaccineLabel({ name, status }) {
      return `${name.trim()} - ${status}`
    },
    openPrescriptionDialog({ prescriptions }) {
      this.isOpenPrescriptionDialog = true
      this.prescriptionDialog = prescriptions
    },
    removeResolved(list) {
      return filter(propEq(null, 'endEncounterId'), list)
    },
    isHighlightedBackground(highlightData, highlightType) {
      if (hasPath(['data'], this.encounter)) {
        const subjectiveQuestionId = this.retrieveQuestionIdFromSubjective(highlightData)
        const questionId = subjectiveQuestionId || this.historyConditionsId
        const questionData = this.getQuestionIfExists(this.encounterData, questionId)
        if (isNil(questionData)) return false

        const question = find(propEq(highlightData.id, 'id'))(questionData)
        const isHighlighted = path([highlightType, 'isHighlighted'], question)
        if (isNil(isHighlighted)) return false
        return isHighlighted
      }
      return false
    },
    applyPinAction({ conditionHighlightType, condition, action }) {
      if (this.isHighlightFromEvaluation(conditionHighlightType, condition)) {
        this.applyPinActionToEvaluationBackground(action, condition)
      } else if (this.isHighlightFromSubjective(conditionHighlightType)) {
        this.applyPinActionToSubjectiveBackground(action, condition)
      }
      this.$bus.$emit('update:emrResumeHighlightPin')
    },
    applyPinActionToEvaluationBackground(action, condition) {
      this.setPinAction({ action, condition, questionId: this.historyConditionsId })
      this.updateEncounter()
    },
    applyPinActionToSubjectiveBackground(action, condition) {
      const questionId = this.retrieveQuestionIdFromSubjective(condition)
      this.setPinAction({ action, condition, questionId })
      this.updateEncounter()
    },
    isHighlightFromEvaluation(conditionHighlightType, condition) {
      return or(
        equals(conditionHighlightType, 'activeProblem'),
        and(
          equals(conditionHighlightType, 'personal'),
          not(isNil(condition.status)),
        ),
      )
    },
    isHighlightFromSubjective(conditionHighlightType) {
      return or(
        equals(conditionHighlightType, 'family'),
        equals(conditionHighlightType, 'personal'),
      )
    },
    retrieveQuestionIdFromSubjective(condition) {
      if (isNil(this.encounterData)) return null
      const questionIdList = [
        this.retrieveQuestionIdFromFamilybackground(condition),
        this.retrieveQuestionIdFromClinicalbackground(condition),
        this.retrieveQuestionIdFromSurgerybackground(condition),
      ]
      return find(questionId => not(isEmpty(questionId)), questionIdList)
    },
    getQuestionIfExists(encounterData, id) {
      const questionData = path([id], encounterData)
      if (isNil(questionData)) return null

      return hasPath(['value'], questionData)
        ? questionData.value
        : questionData
    },
    retrieveQuestionIdFromFamilybackground({ id }) {
      const questionData = this.getQuestionIfExists(
        this.encounterData,
        this.familyBackgroundId,
      )
      if (isNil(questionData)) return ''

      const familyBackground = find(propEq(id, 'id'))(questionData)
      return prop('id', familyBackground) ? this.familyBackgroundId : ''
    },
    retrieveQuestionIdFromClinicalbackground({ id }) {
      const questionData = this.getQuestionIfExists(
        this.encounterData,
        this.clinicalBackgroundId,
      )
      if (isNil(questionData)) return ''

      const clinicalBackground = find(propEq(id, 'id'))(questionData)
      return prop('id', clinicalBackground) ? this.clinicalBackgroundId : ''
    },
    retrieveQuestionIdFromSurgerybackground({ id }) {
      const questionData = this.getQuestionIfExists(
        this.encounterData,
        this.surgeriesId,
      )
      if (isNil(questionData)) return ''

      const surgeryBackground = find(propEq(id, 'id'))(questionData)
      return prop('id', surgeryBackground) ? this.surgeriesId : ''
    },
    rejectSuspendedMeds(meds) {
      return reject(propEq('Suspenso', 'status'), meds)
    },
    async handleGetPatientResume() {
      this.isLoading = true
      if (isEmpty(this.patient)) {
        await this.setEmrPatient(this.$route.params.id)
      }
      await this.getPatientResume(this.patient.id)
      await this.listAttachments({
        patientId: this.patient.id,
      })
      this.isLoading = false
    },
    shouldShowReminder(reminders) {
      return gt(length(this.getRemindersFromProfessional(reminders)), 0)
    },
    getRemindersFromProfessional(reminders) {
      return reject(
        ({ professionalId }) => not(equals(professionalId, this.user.professionalId)),
        reminders,
      )
    },
    openCarePlan() {
      this.isOpenCarePlan = true
    },
    closeCarePlan() {
      this.isOpenCarePlan = false
    },
    shouldShowTextTooltip(text, maxLength = 16) {
      if (text.length >= maxLength) return true
      return false
    },
    shouldNotShowResumeList(list) {
      return isEmpty(list) || isNil(list)
    },
    getSectionColsSize(item) {
      if (path(['name', 'length'], item) < 30) return { cols: 'auto' }
      if (path(['denied'], item) || path(['prescriptions'], item)) {
        return {
          cols: 6,
          md: 7,
          lg: 8,
          xl: 9,
        }
      }
      return { cols: 11 }
    },
  },
}
</script>

<style lang="stylus" scoped>
.v-chip--disabled
  opacity 1

.sidebar__container
  background-color #eee
  min-height calc(100vh - 250px)
  max-width 100%

.sidebar__container-fixed
  height calc(100vh - 70px)
  max-width 25%
  position: fixed
  top: 70px

</style>
