<template>
  <v-dialog
    id="carePlanId"
    :value="true"
    max-width="1000"
    persistent
  >
    <v-card class="tw-px-8 tw-py-4">
      <v-card-title class="tw-flex tw-justify-between tw-px-0 tw-pb-10">
        <img
          class="printable-area__logo"
          src="@/assets/logo.png"
          alt="logo"
        >
        <v-btn
          class="tw-self-start"
          icon
          @click="closeOpenCarePlan()"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="tw-px-0">
        <div class="tw-flex tw-items-center tw-justify-between">
          <h2>
            Plano de Cuidado
          </h2>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                v-on="on"
                @click="sendCarePlanToPatient"
              >
                <v-icon>mdi-message-processing</v-icon>
              </v-btn>
            </template>
            <span>Enviar por SMS</span>
          </v-tooltip>
        </div>

        <p class="tw-text-sm">
          Esse plano de cuidado é anual.
        </p>

        <h3 class="tw-text-sm">
          Interações:
        </h3>

        <p class="tw-text-sm tw-mb-0">
          - <span class="tw-text-blue-500">Tarefas Internas</span>:
          É a programação de cuidados que sua equipe de saúde está preparando para você.
          <br>
          - <span class="tw-text-blue-500">Formulários</span>:
          Se refere ao preenchimento de um formulário que você ou
          a sua equipe especializada irá responder.
          <br>
          - <span class="tw-text-blue-500">Comunicados</span>:
          É o contato que iremos realizar por email com orientações e dicas de saúde.
        </p>

        <care-plan-card
          v-if="carePlan.schedules"
          class="tw-mt-4"
          :care-items="carePlan.schedules"
          care-item="schedule"
        />

        <care-plan-card
          v-if="carePlan.forms"
          class="tw-mt-4"
          :care-items="carePlan.forms"
          care-item="form"
        />

        <care-plan-card
          v-if="carePlan.communications"
          class="tw-mt-4"
          :care-items="carePlan.communications"
          care-item="communication"
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>

import { mapActions } from 'vuex'

export default {
  components: {
    CarePlanCard: () => import('./CarePlanCard'),
  },
  props: {
    patientId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      carePlan: {},
    }
  },
  async mounted() {
    await this.getCarePlanData()
  },
  methods: {
    ...mapActions({
      getCarePlan: 'emr/getCarePlan',
      sendCarePlanLink: 'emr/sendCarePlanLink',
      setSnackbar: 'snackbar/setSnackbar',
    }),
    async getCarePlanData() {
      this.carePlan = await this.getCarePlan(this.patientId)
    },
    async sendCarePlanToPatient() {
      try {
        await this.sendCarePlanLink(this.patientId)
        this.setSnackbar({ status: 'success', message: 'Plano de Cuidado enviado com sucesso!' })
      } catch (error) {
        this.setSnackbar({ status: 'error', message: 'Erro ao enviar Plano de Cuidado!' })
      }
    },
    closeOpenCarePlan() {
      this.$emit('closeDialog')
    },
  },
}
</script>
