<template>
  <v-expansion-panels
    :value="0"
    accordion
    flat
  >
    <emr-resume-item-panel
      title="Linhas de Cuidado"
    >
      <template v-slot:actions>
        <v-btn
          text
          small
          @click.stop="isOpenCareLinePatientList = true"
        >
          <v-icon
            small
            left
          >
            mdi-pencil
          </v-icon>
          Editar
        </v-btn>
      </template>

      <div v-if="careLinesPatient.length === 0">
        <p class="tw-font-medium tw-mb-4">
          Paciente não possui Linhas de Cuidado
        </p>
      </div>

      <div
        v-for="careLineType in careLineTypes"
        v-else
        :key="careLineType.title"
      >
        <v-tooltip
          v-for="careLine in getCareLineTypeData(careLineType.dataFieldName)"
          :key="careLine.id"
          left
        >
          <template v-slot:activator="{ on, attrs }">
            <div
              class="tw-font-medium tw-cursor-default tw-mb-2 tw-flex tw-items-center"
              v-bind="attrs"
              v-on="on"
            >
              <v-chip
                color="primary"
                text-color="white"
                small
                class="
                  tw-rounded-full
                  tw-mr-2
                  tw-p-0
                  tw-w-6
                  tw-h-6
                  tw-inline-flex
                  tw-items-center
                  tw-justify-center
                "
              >
                <span class="tw-cursor-default">
                  {{ careLineType.label }}
                </span>
              </v-chip>

              {{ careLine.careLine.name | toUpperCase }}
            </div>
          </template>

          <div>
            <span>{{ careLineType.description }}</span>

            <ul>
              <li v-if="!hasDiseasesLog(careLine)">
                Não há CIDs atrelados
              </li>
              <li
                v-for="careLineDisease in careLine.diseases.logs"
                v-else
                :key="careLineDisease.diseaseId"
              >
                {{ careLineDisease.version }} -
                {{ careLineDisease.code }} -
                {{ careLineDisease.name }}
              </li>
            </ul>
          </div>
        </v-tooltip>
      </div>

      <v-btn
        text
        block
        large
        color="dark-green"
        class="tw-font-medium tw-mt-4 tw-pl-2 tw-text tw-flex tw-justify-start"
        @click="openNewCareLinePatient"
      >
        <v-icon
          left
          size="25"
        >
          mdi-plus-circle
        </v-icon>
        Adicionar linha de cuidado
      </v-btn>

      <emr-care-line-patient-list-dialog
        v-if="isOpenCareLinePatientList"
        :is-open-care-line-patient-list="isOpenCareLinePatientList"
        @toggleCreateCareLinePatient="openNewCareLinePatient()"
        @closeCareLinePatientListDialog="isOpenCareLinePatientList = false"
      />

      <emr-new-care-line-patient-dialog
        v-if="isOpenNewCareLinePatient"
        @closeDialog="closeNewCareLinePatient"
      />
    </emr-resume-item-panel>
  </v-expansion-panels>
</template>

<script>

import { mapGetters } from 'vuex'
import {
  defaultTo,
  equals,
  filter,
  path,
} from 'ramda'
import { CARE_LINE } from 'amparo-enums'

export default {
  name: 'EmrCareLinePatient',
  components: {
    EmrResumeItemPanel: () => import('@/components/Common/EmrResumeItemPanel'),
    EmrNewCareLinePatientDialog: () => import('@/pages/Emr/EmrNewCareLinePatientDialog'),
    EmrCareLinePatientListDialog: () => import('@/pages/Emr/EmrCareLinePatientListDialog'),
  },
  data() {
    return {
      isOpenNewCareLinePatient: false,
      isOpenCareLinePatientList: false,
      careLineTypes: [
        {
          title: 'Linha Primária',
          dataFieldName: 'primaryLines',
          label: '1º',
          description: 'Linha de cuidado primária',
        },
        {
          title: 'Linha Secundária',
          dataFieldName: 'secondLines',
          label: '2º',
          description: 'Linha de cuidado secundária',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      careLinesPatient: 'careLine/careLinesPatient',
      patient: 'emr/patient',
    }),
    careLinesPatient() {
      return defaultTo([], this.patient.careLinesPatient)
    },
    primaryLines() {
      return filter(
        careLine => careLine.careLine.isPrimary
        && !(equals(careLine.careLineId, CARE_LINE.activationJourney)),
        this.careLinesPatient,
      )
    },
    secondLines() {
      return filter(
        careLine => !(careLine.careLine.isPrimary)
        && !(equals(careLine.careLineId, CARE_LINE.activationJourney)),
        this.careLinesPatient,
      )
    },
  },
  methods: {
    openNewCareLinePatient() {
      this.isOpenNewCareLinePatient = true
    },
    closeNewCareLinePatient() {
      this.isOpenNewCareLinePatient = false
    },
    getCareLineTypeData(dataFieldName) {
      return this[dataFieldName]
    },
    hasDiseasesLog(careLinePatient) {
      return path(['diseases', 'logs'], careLinePatient)
    },
  },
}
</script>
