<template>
  <div class="filters-container tw-py-7">
    <div class="tw-grid tw-grid-flow-col tw-auto-cols-fr tw-px-11 tw-gap-6">
      <v-text-field
        v-model="filters.name"
        label="Nome"
        hide-details
        filled
        clearable
        @click:clear="emitFilters"
        @change="emitFilters"
      />
      <v-text-field
        v-model="filters.cpf"
        label="CPF"
        hide-details
        filled
        clearable
        @click:clear="emitFilters"
        @change="emitFilters"
      />
      <v-select
        v-model="filters.urgency"
        :items="urgencyItems"
        hide-details
        item-text="title"
        item-value="value"
        label="Urgência"
        filled
        clearable
        @change="emitFilters"
      />
      <v-select
        v-model="attendanceStatus"
        :items="statusItems"
        hide-details
        label="Estado de atendimento"
        item-text="title"
        item-value="value"
        filled
        @change="handleAttendanceFilter"
      />
      <v-menu
        v-model="menuDate"
        :close-on-content-click="true"
        transition="scale-transition"
        offset-y
        :nudge-right="40"
        class="tw-w-full"
        max-width="290px"
      >
        <template v-slot:activator="{ on }">
          <v-text-field
            :value="formatFilterDateToDDMMYYYY"
            readonly
            label="Data de atendimento"
            append-icon="mdi-calendar"
            clearable
            hide-details
            filled
            v-on="on"
            @click:clear="
              filters.date = null;
              emitFilters()
            "
          />
        </template>
        <v-date-picker
          v-model="filters.date"
          locale="pt-br"
          color="primary"
          no-title
          @change="emitFilters"
        />
      </v-menu>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import {
  converge,
  or,
  isNil,
  isEmpty,
  reject,
  includes,
  omit,
} from 'ramda'
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      menuDate: false,
      attendanceStatus: false,
      filters: {
        page: 1,
        isClosed: false,
      },
      statusItems: [
        {
          title: 'Atendidos',
          value: true,
        },
        {
          title: 'Não Atendidos',
          value: false,
        },
        {
          title: 'Cancelados',
          value: 'cancelAttendance',
        },
        {
          title: 'Em atendimento',
          value: 'inAttendance',
        },
      ],
      urgencyItems: [
        {
          title: 'Verde',
          value: 1,
        },
        {
          title: 'Amarelo',
          value: 2,
        },
        {
          title: 'Vermelho',
          value: 3,
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      showVirtualReceptionDialog: 'virtualReception/showVirtualReceptionDialog',
    }),
    formatFilterDateToDDMMYYYY() {
      return this.filters.date ? moment(this.filters.date).format('DD/MM/YYYY') : ''
    },
  },
  watch: {
    showVirtualReceptionDialog() {
      this.filters.isClosed = false
    },
  },
  created() {
    this.emitFilters()
  },
  activated() {
    this.emitFilters()
  },
  methods: {
    emitFilters() {
      this.$nextTick(() => {
        this.$emit('filterUpdate', reject(converge(or, [isNil, isEmpty]), this.filters))
      })
    },
    handleAttendanceFilter() {
      this.filters = omit(['attendanceStatus', 'isClosed'], this.filters)
      const noBooleanStatus = ['inAttendance', 'cancelAttendance']
      if (includes(this.attendanceStatus, noBooleanStatus)) {
        this.filters.attendanceStatus = this.attendanceStatus
      } else {
        this.filters.isClosed = this.attendanceStatus
      }
      this.emitFilters()
    },
  },
}
</script>
