<template>
  <div class="tw-p-12">
    <div v-if="hasPatient">
      <v-card
        v-for="patient in patientRegistrations.patientRegistrations"
        :key="patient.id"
        class="tw-mb-4"
      >
        <v-card-title class="tw-text-2xl">
          {{ patient | formatDisplayName }}
        </v-card-title>
        <v-card-text class="tw-flex">
          <div class="tw-grid tw-grid-flow-col tw-auto-cols-max tw-gap-6">
            <div
              v-for="(column, indexColumn) in cardStructure"
              :key="indexColumn"
            >
              <div
                v-for="(item, indexRow) of column"
                :key="indexRow"
                class="tw-mb-3"
              >
                <strong class="info__title">{{ item.label }}:</strong>

                {{ formatedPatientData(patient[item.key], item.key) }}
              </div>
            </div>

            <div>
              <span class="tw-mb-12">
                <strong class="info__title">STATUS: </strong>{{ translateStatus[patient.status] }}
              </span>

              <div
                v-if="statusIsTodo(patient.status)"
                class="tw-grid tw-grid-cols-2 tw-gap-2"
              >
                <v-btn
                  class="tw-text-white"
                  color="danger"
                  small
                  @click="auditPatient(patient, auditStatus.refused.value)"
                >
                  RECUSAR
                </v-btn>
                <v-btn
                  color="primary"
                  small
                  @click="auditPatient(patient, auditStatus.approved.value)"
                >
                  APROVAR
                </v-btn>
              </div>
            </div>
          </div>
        </v-card-text>
      </v-card>
      <div class="tw-text-center">
        <v-pagination
          :length="totalPages"
          :total-visible="itemsPerPage"
          @input="changePage($event)"
        />
      </div>
    </div>
    <div v-else>
      <v-card>
        <v-card-text class="tw-text-center">
          Não há registros de paciente
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>
<script>
import {
  always,
  cond,
  defaultTo,
  equals,
  T,
} from 'ramda'
import { AUDIT } from 'amparo-enums'
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      itemsPerPage: 30,
      cardStructure: {
        firstColumn: [
          {
            key: 'name',
            label: 'NOME',
          },
          {
            key: 'cpf',
            label: 'CPF',
          },
          {
            key: 'healthInsurancePlanNumber',
            label: 'CARTEIRINHA',
          },
          {
            label: 'CARTEIRA DO RESPONSÁVEL',
            key: 'responsibleHealthInsurancePlanNumber',
          },
        ],
        secondColumn: [
          {
            key: 'email',
            label: 'E-MAIL',
          },
          {
            key: 'telphone',
            label: 'TELEFONE',
          },
          {
            key: 'birthDate',
            label: 'NASCIMENTO',
          },
          {
            key: 'nationality',
            label: 'NACIONALIDADE',
          },
          {
            key: 'biologicalSex',
            label: 'SEXO',
          },
        ],
      },
      translateStatus: {
        [AUDIT.status.approved.value]: AUDIT.status.approved.label,
        [AUDIT.status.refused.value]: AUDIT.status.refused.label,
        [AUDIT.status.todo.value]: AUDIT.status.todo.label,
      },
    }
  },
  computed: {
    ...mapGetters({
      patientRegistrations: 'patientRegistration/patientRegistrations',
    }),
    auditStatus() {
      return AUDIT.status
    },
    hasPatient() {
      return this.patientRegistrations.count
    },
    totalPages() {
      return Math.ceil(this.patientRegistrations.count / this.itemsPerPage)
    },
  },
  methods: {
    formatedPatientData(value, key) {
      return cond([
        [equals('biologicalSex'), () => this.$filters.biologicalSex(value)],
        [equals('telphone'), () => this.$filters.formatPhone(value)],
        [equals('birthDate'), () => this.$filters.formatDate(value)],
        [equals('cpf'), () => this.$filters.formatCpf(value)],
        [equals('responsibleHealthInsurancePlanNumber'), always(defaultTo('Não possui', value))],
        [T, always(value)],
      ])(key)
    },
    changePage(page) {
      this.$emit('update:page', { page })
    },
    upList() {
      document.getElementById('audit__field-name').focus()
    },
    auditPatient(patient, status) {
      this.$emit('audit', { patient, status })
    },
    statusIsTodo(status) {
      return equals(status, this.auditStatus.todo.value)
    },
  },
}
</script>
<style scoped lang="stylus">
.info__title
  text-transform uppercase
  letter-spacing .1rem
  font-size .8rem
  color secondary-color

</style>
