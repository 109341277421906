<template>
  <div>
    <loading-emr-history
      v-if="isLoading"
      justify-center
    />

    <div
      v-else
      class="tw-flex tw-mx-8 tw-mt-10"
    >
      <v-timeline
        class="tw-w-full tw-pt-0"
        align-top
        dense
      >
        <emr-timeline-header>
          <emr-new-encounter
            v-if="isUserDoctorOrNurse"
            :encounter-appointment-options="encounterAppointmentOptions"
          />
        </emr-timeline-header>

        <v-timeline-item hide-dot>
          <div class="tw-flex tw-justify-between tw-ml-8 tw-mb-6">
            <h2 class="tw-text-green-deep">
              Atendimentos
            </h2>

            <emr-encounter-list-controls
              v-if="encounters.length !== 0"
              :patient="patient"
              :pagination="pagination"
            />
          </div>
        </v-timeline-item>

        <v-timeline-item
          v-for="emr in encounters"
          :key="emr.id"
          small
        >
          <template v-slot:icon>
            <time-line-item-chip :label="emr.date | convertUtcDateToBrDate | formatDate" />
          </template>

          <emr-encounter-card
            class="tw-ml-8 tw--mt-3"
            show-card-arrow
            :emr="emr"
            :should-show-section-name="shouldShowSectionName(emr)"
          />
        </v-timeline-item>

        <v-timeline-item
          v-if="encounters.length === 0"
          class="tw-pb-0"
          hide-dot
        >
          <span class="components__default-message tw-ml-8">
            {{ encountersEmptyMessage }}
          </span>
        </v-timeline-item>

        <emr-history-pagination
          v-else
          :page="pagination.page"
          class="mb-6"
          @changePage="setPage"
        />
      </v-timeline>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  any,
  equals,
  includes,
  isEmpty,
  not,
} from 'ramda'
import TimeLineItemChip from '@/pages/Emr/TimeLineItemChip'
import LoadingEmrHistory from '@/components/loaders/LoadingEmrHistory'
import EmrTimelineHeader from '@/pages/Emr/EmrTimelineHeader'
import EmrHistoryPagination from './EmrHistoryPagination'
import EmrNewEncounter from './EmrNewEncounter'
import EmrEncounterListControls from './EmrEncounterListControls'
import EmrEncounterCard from './EmrEncounterCard'

export default {
  name: 'EmrTimelineList',
  components: {
    LoadingEmrHistory,
    EmrTimelineHeader,
    EmrHistoryPagination,
    EmrNewEncounter,
    EmrEncounterListControls,
    EmrEncounterCard,
    TimeLineItemChip,
  },
  props: {
    isLoading: {
      type: Boolean,
      required: true,
    },
    encounters: {
      type: Array,
      required: true,
    },
    encountersEmptyMessage: {
      type: String,
      required: true,
    },
    pagination: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      user: 'authentication/user',
      patient: 'emr/patient',
      emrAllowedProfessions: 'emr/emrAllowedProfessions',
      emrEncounterAppointmentOptions: 'emr/encounterAppointmentOptions',
      reminderEncounterId: 'emr/reminderEncounterId',
    }),
    encounterAppointmentOptions() {
      if (not(isEmpty(this.emrEncounterAppointmentOptions))) {
        return this.emrEncounterAppointmentOptions
      }
      const options = JSON.parse(localStorage.getItem('_encounterOptions'))

      return options
    },
  },
  methods: {
    setPage(page) {
      this.$emit('changePage', page)
    },
    shouldShowSectionName(emr) {
      const sectionFormNames = ['SOAP']
      const encounterFormName = emr?.encounterFormName
      return any(equals(encounterFormName), sectionFormNames)
    },
    shouldShowReminder(professionalId) {
      return equals(professionalId, this.user.professionalId)
    },
    isUserDoctorOrNurse() {
      return includes(this.user.professionId, this.emrAllowedProfessions)
    },
  },
}
</script>
