<template>
  <div class="tw-flex tw-flex-nowrap tw-w-full tw-justify-between">
    <div
      class="
        tw-flex
        tw-flex-col
        tw-justify-center
        tw-items-start
        tw-text-sm
        tw-leading-5
        tw-font-bold
      "
    >
      <div class="tw-text-xl tw-font-medium">
        {{ titleText }}
      </div>
      <div
        class="card__infos-sub-title tw-my-2 tw-flex tw-flex-wrap tw-gap-x-3"
      >
        <div
          v-if="encounterTitle"
          class="tw-pr-4 tw-pb-1"
          cols="auto"
          align-center
        >
          <v-icon
            class="tw-mr-1"
            size="20"
          >
            mdi-doctor
          </v-icon>
          <span>
            {{ professionalText }}
          </span>
        </div>
        <div>
          <v-icon
            class="tw-mr-1"
            size="20"
          >
            mdi-calendar
          </v-icon>
          {{ emr.date | convertUtcDateToBrDate | formatDate }}
        </div>
        <div>
          <v-icon
            class="tw-mr-1"
            size="20"
          >
            mdi-clock-outline
          </v-icon>
          {{ emr.startTime | convertUtcToBrTime | removeSecondsFromTime }} -
          {{ emr.endTime | convertUtcToBrTime | removeSecondsFromTime }}
        </div>
        <div>
          <v-icon
            class="tw-mr-1"
            size="20"
          >
            mdi-map-marker
          </v-icon>
          Unidade {{ emr.clinic.name }}
        </div>
      </div>
    </div>
    <div
      v-if="!emr.data.legacy"
      class="tw-flex tw-items-center"
    >
      <v-chip
        v-if="shouldShowSpecialityName"
        color="japaneseLaurel"
        outlined
        disabled
      >
        <strong>{{ emr.speciality.name }}</strong>
      </v-chip>
      <v-chip
        v-if="hasEncounterName(emr.encounterFormName, 'ANNOTATION')"
        disabled
        :class="getChipAnnotationReasonClass(emr.annotationReason)"
      >
        {{ formatAnnotationReason(emr.annotationReason) }}
      </v-chip>
      <v-chip
        v-if="immediateCareHasAbsentPatient(emr)"
        color="#f3c5a2"
        disabled
      >
        Paciente Faltou
      </v-chip>
      <emr-print-button
        v-if="shouldShowPrintButton"
        :emr-data="emr"
        :button-color="'dark-green'"
        :classes="'justify-end'"
      />
      <v-btn
        v-if="shouldShowVideoRoomChatButton && emr.videoRoomChat?.chatLog?.messages?.length"
        fab
        dark
        text
        justify-end
        small
        color="dark-green"
        @click="emitToggleVideoRoomChat"
      >
        <v-icon>
          mdi-message-reply-text
        </v-icon>
      </v-btn>
    </div>
    <div
      v-else
      class="legacy"
    >
      Atendimento Importado
    </div>
  </div>
</template>

<script>
import {
  cond,
  equals,
  always,
  T,
  includes,
  toUpper,
  and,
  or,
  pathEq,
  path,
  split,
  head,
  last,
} from 'ramda'
import moment from 'moment'
import { EMR_QUESTIONS } from 'amparo-enums'
import encounterAnnotationReasonEnums from '@/enums/encounterAnnotationReason'

const { questions } = EMR_QUESTIONS
const { ENCOUNTER_ANNOTATION_REASON } = encounterAnnotationReasonEnums

export default {
  components: {
    EmrPrintButton: () => import('@/pages/Emr/EmrPrintButton'),
  },
  props: {
    emr: {
      type: Object,
      required: true,
    },
    shouldShowSpecialityName: {
      type: Boolean,
      required: false,
      default: true,
    },
    shouldShowPrintButton: {
      type: Boolean,
      required: false,
      default: true,
    },
    shouldShowVideoRoomChatButton: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  computed: {
    encounterTitle() {
      return path(['data', questions.encounterTitle, 'value'], this.emr)
    },
    professionalText() {
      const fullProfessionalName = this.emr.professional.name
      const professionalNames = split(' ', fullProfessionalName)
      const firstAndLastName = professionalNames.length > 1
        ? `${head(professionalNames)} ${last(professionalNames)}`
        : fullProfessionalName

      return `
        ${firstAndLastName}
        ${this.emr.professional.documentType}-${this.emr.professional.documentState}
        ${this.emr.professional.documentNumber}`
    },
    titleText() {
      const defaultText = `
        ${this.formatEncounterFormName(this.emr.encounterFormName)} com:
        ${this.showAppropriatePronoun(this.emr)}
        ${this.professionalText}`

      return this.encounterTitle ? this.encounterTitle : defaultText
    },
  },
  methods: {
    hasEncounterName(name, encounterFormName) {
      return equals(name, encounterFormName)
    },
    formatEncounterFormName(encounterFormName) {
      return cond([
        [equals('ANNOTATION'), always('Anotação')],
        [T, always('Consulta')],
      ])(encounterFormName)
    },
    calculateEmrEndDate(startDate, durationEmr) {
      return moment(startDate, 'YYYY-MM-DDTHH:mm:ss.SSSZ')
        .add(durationEmr, 'milliseconds')
        .format('YYYY-MM-DDTHH:mm:ss.SSSZ')
    },
    showAppropriatePronoun(emr) {
      if (includes('Técnico', emr.professional.profession.name)) return ''
      return emr.professional.biologicalSex === 'F' ? 'Dra.' : 'Dr.'
    },
    getChipAnnotationReasonClass(annotationReason) {
      return [
        'ma-2',
        `chip__annotation-reason--${annotationReason}`,
      ]
    },
    formatAnnotationReason(annotationReason) {
      return ENCOUNTER_ANNOTATION_REASON[toUpper(annotationReason)].label
    },
    immediateCareHasAbsentPatient(emr) {
      return and(
        includes(emr.encounterFormName, ['IMMEDIATE_CARE', 'SOAP']),
        or(
          pathEq('missed', ['appointment', 'status'], emr),
          pathEq('missed', ['immediateCare', 'status'], emr),
        ),
      )
    },
    emitToggleVideoRoomChat() {
      this.$emit('toggleShouldShowVideoRoomChat')
    },
  },
}
</script>

<style lang="stylus" scoped>
.v-chip--disabled
  opacity 1
.card__header
  word-break: break-word
  background-color #eeeeee

.card__header___arrow
  &:after
    content ''
    position absolute
    left 0
    top 25px
    width 0
    height 0
    border 15px solid transparent
    border-right-color #eee
    border-left 0
    margin-top -15px
    margin-left -15px

.card__infos-sub-title
  color rgba(#000, 0.6)

.chip__annotation-reason--administrative_demand
  background-color #C0DFD3 !important

.chip__annotation-reason--erratic
  background-color #F29F9E !important

.chip__annotation-reason--telemonitoring
  background-color #fccc7e !important

.chip__annotation-reason--prescription
  background-color #C3E0E8 !important

.chip__annotation-reason--missing_patient
  background-color #F3C5A2 !important

.chip__annotation-reason--presential
  background-color #c8a2c8 !important

.legacy
  color $japanese-laurel !important
  text-transform uppercase
  margin-right 10px

</style>
