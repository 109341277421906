import {
  APPOINTMENT,
  IMMEDIATE_CARE,
  ORDER,
  ELIGIBILITY,
  HMO,
} from 'amparo-enums'
import {
  equals,
  includes,
  uniq,
} from 'ramda'

const getOrderStatusList = ({
  orderToEditStatus,
  newBillId,
  hasAccessToOrderEditService,
  hasAccessToOrderEditBill,
  attendanceStatus,
  eligibilityStatus,
  hmoId,
}) => {
  if (equals(orderToEditStatus, ORDER.status.canceled.value)) {
    return [ORDER.status.canceled]
  }

  if (!includes(attendanceStatus, [
    APPOINTMENT.status.attended.value,
    IMMEDIATE_CARE.status.attended.value,
  ])) {
    return uniq([
      ORDER.status.opened,
      ORDER.status[orderToEditStatus],
    ])
  }

  if (hasAccessToOrderEditService) {
    if (eligibilityStatus !== ELIGIBILITY.status.eligible && !includes(hmoId, [
      HMO.private,
      HMO.sabinPrivate,
      HMO.assefazPrivate,
    ])) {
      return [
        ORDER.status[orderToEditStatus],
      ]
    }

    if (equals(orderToEditStatus, ORDER.status.opened.value)) {
      return [
        ORDER.status.opened,
        ORDER.status.sentToBilling,
      ]
    }

    if (equals(orderToEditStatus, ORDER.status.returnedToReview.value)) {
      return [
        ORDER.status.returnedToReview,
        ORDER.status.sentToBilling,
      ]
    }
  }

  if (hasAccessToOrderEditBill) {
    if (newBillId) {
      return [
        ORDER.status.billed,
      ]
    }

    if (equals(orderToEditStatus, ORDER.status.sentToBilling.value)) {
      return [
        ORDER.status.sentToBilling,
        ORDER.status.receivedByBilling,
      ]
    }

    if (equals(orderToEditStatus, ORDER.status.receivedByBilling.value)) {
      return [
        ORDER.status.receivedByBilling,
        ORDER.status.returnedToReview,
      ]
    }

    if (equals(orderToEditStatus, ORDER.status.billed.value)) {
      if (!newBillId) {
        return [
          ORDER.status.receivedByBilling,
        ]
      }

      return [
        ORDER.status.billed,
      ]
    }
  }

  return [
    ORDER.status[orderToEditStatus],
  ]
}

export default getOrderStatusList
