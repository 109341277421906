<template>
  <div v-if="shouldShowDialogButton">
    <v-btn
      outlined
      large
      block
      class="tw-font-medium"
      color="amparo-medium-green"
      @click="showDialog = true"
    >
      <v-icon
        class="tw-font-medium"
        left
        size="25"
      >
        mdi-folder-download
      </v-icon>
      Resumo importado
    </v-btn>

    <v-dialog
      v-model="showDialog"
      max-width="1000"
    >
      <v-card>
        <v-card-title class="tw-text-xl tw-font-medium tw-flex tw-justify-between">
          Resumo importado

          <v-btn
            icon
            @click="showDialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <div
            v-for="(property, index) of filteredLegacy"
            :key="index"
            column
            class="tw-flex tw-mb-1"
          >
            <div
              v-if="property.length > 0"
              class="tw-text-2xl tw-font-medium tw-mb-1"
            >
              {{ translatedProperties[index] }}
            </div>

            <div
              v-for="item in property"
              :key="item.id"
              class="tw-text-black tw-opacity-70 tw-ml-1"
            >
              <span v-if="item.name">
                <span class="tw-mr-2">•</span>
                {{ item.name }} {{ item.status ? `- ${item.status}` : '' }}
              </span>
              <div
                v-if="item.specialityName"
                column
              >
                <span>
                  <span class="tw-mr-2">•</span>
                  {{ item.specialityName }}
                </span>
                <div column>
                  <span class="tw-my-1">Observações:</span>
                  <span>{{ item.observations }}</span>
                  <v-divider class="tw-my-2" />
                </div>
              </div>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  not,
  isEmpty,
  values,
  flatten,
  isNil,
  pickAll,
  keys,
} from 'ramda'

export default {
  props: ['resume'],
  data() {
    return {
      showDialog: false,
      translatedProperties: {
        habits: 'Hábitos',
        vaccines: 'Vacinas',
        allergies: 'Alergias',
        careLines: 'Linhas de cuidado',
        reminders: 'Lembretes',
        surgeries: 'Cirurgias',
        conditions: 'Condições',
        examRequests: 'Exames solicitados',
        familyHistory: 'Histórico familiar',
        treatments: 'Tratamentos',
        medicalReferrals: 'Encaminhamentos',
        clinicalBackground: 'Antecedentes clínicos',
      },
    }
  },
  computed: {
    shouldShowDialogButton() {
      return not(isEmpty(flatten(values(this.filteredLegacy))))
    },
    filteredLegacy() {
      if (not(isNil(this.resume))) return pickAll(keys(this.translatedProperties), this.resume)
      return []
    },
  },
}
</script>
