<template>
  <div>
    <div v-if="hasResumeStruct(emr)">
      <emr-encounter-resume
        :emr="emr"
        :items-per-row="itemsPerRow"
        :should-show-section-name="shouldShowSectionName"
        :should-show-video-room-chat="shouldShowVideoRoomChat"
        :can-open-resume-modal="canOpenResumeModal"
      />
    </div>
    <div v-else>
      <component
        :is="getComponentByEncounterFormName(emr)"
        :encounter-data="emr.data"
        :row="!isLegacy"
        :column="isLegacy"
      />
    </div>
  </div>
</template>

<script>
import EmrEncounterResume from './EmrEncounterResume'
import EmrOldEncounterResume from './EmrOldEncounterResume'
import EmrAnnotationResume from './EmrAnnotationResume'
import EmrImmediateCareResume from './EmrImmediateCareResume'
import EmrEncounterResumeLegacy from './EmrEncounterResumeLegacy'

export default {
  name: 'EmrEncounterSummary',
  components: {
    EmrEncounterResume,
    EmrOldEncounterResume,
    EmrAnnotationResume,
    EmrEncounterResumeLegacy,
    EmrImmediateCareResume,
  },
  props: {
    emr: {
      type: Object,
      required: true,
    },
    canOpenResumeModal: {
      type: Boolean,
      default: false,
    },
    shouldShowSectionName: {
      type: Boolean,
      default: false,
    },
    shouldShowVideoRoomChat: {
      type: Boolean,
      default: false,
    },
    itemsPerRow: {
      type: Number,
      default: 2,
    },
  },
  computed: {
    isLegacy() {
      return Boolean(this.emr.data?.legacy)
    },
  },
  methods: {
    hasResumeStruct(emr) {
      return emr?.encounterResumeStruct
        || emr?.encounterForm?.encounterResumeStruct
    },
    getComponentByEncounterFormName(emr) {
      if (this.isLegacy) return 'emr-encounter-resume-legacy'
      if (this.hasEncounterName(emr.encounterFormName, 'ANNOTATION')) return 'emr-annotation-resume'
      if (this.hasEncounterName(emr.encounterFormName, 'IMMEDIATE_CARE')) return 'emr-immediate-care-resume'
      return 'emr-old-encounter-resume'
    },
    hasEncounterName(encounterFormName, name) {
      return encounterFormName === name
    },
  },
}
</script>
